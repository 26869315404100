<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.74956 15.1499C3.17737 15.1499 3.50265 14.86 3.50265 14.4145C3.50265 13.969 3.17737 13.6649 2.74956 13.6649C2.32528 13.6649 2 13.969 2 14.4145C2 14.86 2.32528 15.1499 2.74956 15.1499Z"
      fill="currentColor"
    />
    <path
      d="M7.51937 15.1499C8.64017 15.1499 9.31548 14.5594 9.54176 13.3114L8.53057 13.0957C8.40328 13.9195 8.08154 14.2978 7.51937 14.2978C6.73446 14.2978 6.342 13.527 6.342 12.1481C6.342 10.7657 6.73446 10.002 7.51937 10.002C8.06386 10.002 8.38207 10.3591 8.5235 11.1794L9.55944 10.9425C9.31902 9.71561 8.64724 9.1499 7.51937 9.1499C5.90004 9.1499 5.23888 10.4192 5.23888 12.1481C5.23888 13.8735 5.90004 15.1499 7.51937 15.1499Z"
      fill="currentColor"
    />
    <path
      d="M12.1336 15.1499C13.3994 15.1499 14.1277 14.4781 14.1277 13.4457C14.1277 12.5406 13.7034 12.1092 12.5897 11.7557L12.1336 11.6107C11.5219 11.4092 11.3204 11.1652 11.3204 10.7374C11.3204 10.2601 11.5891 9.98785 12.2114 9.98785C12.77 9.98785 13.1837 10.253 13.3888 10.8718L14.156 10.4051C13.8272 9.50347 13.2085 9.1499 12.2503 9.1499C10.9739 9.1499 10.2703 9.82875 10.2703 10.8011C10.2703 11.7486 10.8042 12.2295 11.7836 12.5441L12.2503 12.6962C12.869 12.8906 13.0989 13.0709 13.0989 13.5447C13.0989 13.9973 12.7912 14.3155 12.1619 14.3155C11.5149 14.3155 11.0694 13.9973 10.7971 13.3609L10.0511 13.8594C10.4365 14.7681 11.133 15.1499 12.1336 15.1499Z"
      fill="currentColor"
    />
    <path
      d="M16.016 15.0368H17.4727L19.0566 9.26304H17.9995L17.2499 12.1234C17.0908 12.7209 16.9388 13.3573 16.7549 14.1458H16.7337C16.5499 13.3573 16.3978 12.7209 16.2387 12.1234L15.4892 9.26304H14.432L16.016 15.0368Z"
      fill="currentColor"
    />
  </svg>
</template>
