import axios from "axios";
import { getAxiosInstance } from "@lib/utils/axiosInstance";

export default defineNuxtPlugin(async (app) => {
  const { $auth, $logError, $notifyError } = app;
  const { baseUrl: njordaApiBaseUrl, isDevelopment } = useRuntimeConfig().public;

  const logout = function () {
    $auth.logout(true);
    return;
  };

  const $njordaB2BApi = getAxiosInstance();
  $njordaB2BApi.interceptors.request.use(
    function (config) {
      config.baseURL = `${njordaApiBaseUrl}/b2b/v1/`;
      config.withCredentials = true;
      return config;
    },
    function (e) {
      return Promise.reject(e);
    },
  );

  $njordaB2BApi.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response) {
        const code = parseInt(error.status ?? error.response.status);

        if (code === 401) {
          return logout();
        }
        let errorMsg;
        if (error?.response?.data?.messages) {
          const messages = error.response.data.messages;
          errorMsg = getErrorMessage(messages);
          if (isDevelopment) {
            $logError("API Error: \n", JSON.stringify(messages));
          }
        }
        throw {
          message: errorMsg,
          error: error,
          request: error.request,
          response: error.response,
        };
      } else if (error && axios.isCancel(error)) {
        return;
      } else {
        $notifyError({
          text: "Network error. Please try again later.",
        });
      }
      return Promise.reject(error);
    },
  );

  if ($auth.loggedIn) {
    await $auth.init().catch(() => {
      logout();
    });
  }

  return {
    provide: {
      njordaB2BApi: $njordaB2BApi,
    },
  };
});
// TODO: Implement new error handling mechanism
