import mitt from "mitt";

export default defineNuxtPlugin(() => {
  const emitter = mitt();

  return {
    provide: {
      trigger: emitter.emit,
      subscribe: emitter.on,
      unsubscribe: emitter.off,
    },
  };
});
