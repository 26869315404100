import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import type {
  CancelablePromise,
  marshmallow_schema_ClientResultSchemaItems,
  marshmallow_schema_ColumnChoicesSchema,
  marshmallow_schema_EnumSchemaItems,
  marshmallow_schema_HoldingsReportSchemaItems,
  marshmallow_schema_OrganizationSchemaItems,
  marshmallow_schema_ListReportSchemaItems,
  njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema,
  njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema,
  njorda_libs_schemas_positions_report_PositionsSchema,
  njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema,
  njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema,
  marshmallow_schema_AccountDetailsSchemaItems,
  HoldingsReport,
  njorda_libs_schemas_summary_report_ClientSummarySchema,
  PortfolioBenchmark,
  PortfolioBenchmarkResult,
} from "~/api/njorda";
import type { TOrganization } from "~/interfaces/app";
import type {
  TAnalyticsReportRow,
  TAnalyticsReportsColumnOption,
  TAnalyticsReportsColumnOptions,
  TAnalyticsReportsExportFormat,
  TAnalyticsReportsFilter,
  TAnalyticsReportsGrouping,
  TAnalyticsReportsGroupingOption,
  TAnalyticsReportsSourceOptions,
  TAnalyticsReportsUnsavedReport,
} from "~/interfaces/analytics-reports";
import type {
  TAdvisorClient,
  TAdvisorClientAdvisor,
  TAdvisorClientExtended,
  TAdvisorClientHoldingReport,
  TAdvisorClientHoldingReportDetails,
  TAdvisorClientsMap,
  TAdvisorHoldingReportClient,
} from "~/interfaces/advisor";
import { HOLDING_REPORT_STATUS_OPTIONS } from "@/constants/advisor";
import { useUserStore } from "./user";
import { useCalcsStore } from "./calcs";
import { usePortfolioBenchmarksStore } from "./portflioBenchmarks";
import { usePortfolioTemplatesStore } from "./portfolioTemplates";
import { usePortfolioAssetsStore } from "./portfolioAssets";
import { useAssetClassesStore } from "./asset-classes";
import { useAssetListRulesStore } from "./asset-list-rules";
import { getKeyByStrings } from "@lib/utils/formatting";
import useClientName from "~/composables/advisor/client-name";

export type TClientSummaryMapKeyParams = {
  clientId: string;
  startDate: string;
  endDate: string;
  accountIds?: string;
};

type TAdvisorState = {
  _organization: TOrganization | null;
  _organizations: TOrganization[];
  _client: TAdvisorHoldingReportClient | null;
  _organizationsLoading: boolean;
  _analyticsReports: TAnalyticsReportRow[];
  _analyticsReportsLoading: boolean;
  _groupingOptions: TAnalyticsReportsGroupingOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _groupingOptionsLoading: any;
  _sourceOptions: TAnalyticsReportsSourceOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _sourceOptionsLoading: any;
  _columnOptions: TAnalyticsReportsColumnOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _columnOptionsLoading: any;
  _tempReportFilters: TAnalyticsReportsFilter[];
  _tempReportColumns: TAnalyticsReportsColumnOption[];
  _saveReportInProgress: boolean;
  _deleteTempReportInProgress: boolean;
  _clients: TAdvisorClient[];
  _clientsMap: TAdvisorClientsMap;
  _clientsLoading: boolean;
  _reports: TAdvisorClientHoldingReport[];
  _clientReport: Record<string, unknown> | null;
  _clientSummaryMap: Record<string, njorda_libs_schemas_summary_report_ClientSummarySchema | null>;
  _clientSummaryMapLoading: Record<string, boolean>;
  _allBenchmarksCalculations: PortfolioBenchmarkResult[] | null;
};

export const useAdvisorStore = defineStore("advisorStore", {
  state: () =>
    ({
      _organization: null,
      _organizations: [],
      _client: null,
      _organizationsLoading: false,
      _analyticsReports: [],
      _analyticsReportsLoading: false,
      _groupingOptions: [],
      _groupingOptionsLoading: null,
      _sourceOptions: {} as TAnalyticsReportsSourceOptions,
      _sourceOptionsLoading: null,
      _columnOptions: {} as TAnalyticsReportsColumnOptions,
      _columnOptionsLoading: null,
      _tempReportFilters: [],
      _tempReportColumns: [],
      _saveReportInProgress: false,
      _deleteTempReportInProgress: false,
      _clients: [],
      _clientsMap: {} as TAdvisorClientsMap,
      _clientsLoading: false,
      _reports: [],
      _clientReport: null,
      _clientSummaryMap: {} as Record<string, njorda_libs_schemas_summary_report_ClientSummarySchema>,
      _clientSummaryMapLoading: {} as Record<string, boolean>,
      _allBenchmarksCalculations: null,
    }) as TAdvisorState,
  getters: {
    organization(state: TAdvisorState): TOrganization | null {
      return state._organization;
    },
    organizations(state: TAdvisorState): TOrganization[] {
      return state._organizations;
    },
    organizationsLoading(state: TAdvisorState): boolean {
      return state._organizationsLoading;
    },
    organizationName(state: TAdvisorState): string {
      // getOrganizationName
      return state._organization?.name ?? "";
    },
    client(state: TAdvisorState): TAdvisorHoldingReportClient | null {
      return state._client;
    },
    clientName(state: TAdvisorState): string {
      // getUserName
      if (state._client) {
        const { first_name, last_name } = state._client;
        return `${first_name ?? ""} ${last_name ?? ""}`.trim();
      }
      return "";
    },
    personalNumber(state: TAdvisorState): string {
      // getPersonalNumber
      return state._client?.personal_number ?? "";
    },
    reportLoaded(state: TAdvisorState): boolean {
      return !!state._clientReport && Object.keys(state._clientReport).length > 0;
    },
    clientReport(state: TAdvisorState): Record<string, unknown> | null {
      if (this.reportLoaded) {
        return state._clientReport;
      } else {
        return null;
      }
    },
    analyticsReports: (state: TAdvisorState) => state._analyticsReports,
    analyticsReportsLoading: (state: TAdvisorState) => state._analyticsReportsLoading,
    groupingOptionsLoading: (state: TAdvisorState) => state._groupingOptionsLoading,
    groupingOptions: (state: TAdvisorState) => state._groupingOptions,
    sourceOptionsLoading: (state: TAdvisorState) => state._sourceOptionsLoading,
    sourceOptions: (state: TAdvisorState) => state._sourceOptions,
    columnOptionsLoading: (state: TAdvisorState) => state._columnOptionsLoading,
    columnOptions:
      (state: TAdvisorState) =>
      (grouping: TAnalyticsReportsGrouping): TAnalyticsReportsColumnOption[] | undefined => {
        return state._columnOptions[grouping] ?? [];
      },
    tempReportFilters: (state: TAdvisorState) => state._tempReportFilters,
    tempReportColumns: (state: TAdvisorState) => state._tempReportColumns,
    saveReportInProgress: (state: TAdvisorState) => state._saveReportInProgress,
    deleteTempReportInProgress: (state: TAdvisorState) => state._deleteTempReportInProgress,
    clients: (state: TAdvisorState) => {
      const clients = <TAdvisorClientExtended[]>[];
      state._clients.forEach((client) => {
        const holdingReportsSummary = <TAdvisorClientHoldingReportDetails[]>[];
        if (client && client.holding_reports_summary) {
          for (const reportStatus in client.holding_reports_summary) {
            const holdingReportsStatus = Object.values(HOLDING_REPORT_STATUS_OPTIONS).find(
              (status) => status.value === reportStatus,
            );
            holdingReportsSummary.push({
              label: holdingReportsStatus?.text ?? "",
              value: client.holding_reports_summary[reportStatus],
              color: holdingReportsStatus?.color ?? "",
            });
          }
        }

        let advisor: TAdvisorClientAdvisor | undefined = undefined;
        if (client.advisor) {
          advisor = {
            ...client.advisor,
            name: `${client.advisor.first_name ?? ""} ${client.advisor.last_name ?? ""}`.trim(),
          };
        }

        clients.push({
          id: client.id,
          email: client.email,
          name: `${client.first_name ?? ""} ${client.last_name ?? ""}`.trim(),
          firstName: client.first_name ?? "",
          lastName: client.last_name ?? "",
          personalNumber: client.personal_number,
          lastHoldingCollectionDate: client.last_holdings_collection_at,
          holdingReportsSummary,
          advisor,
          fullName: useClientName(client),
        });
      });
      return clients;
    },
    clientsMap: (state: TAdvisorState) => state._clientsMap,
    clientsLoading: (state: TAdvisorState) => state._clientsLoading,
    reports: (state: TAdvisorState) => state._reports,
    clientSummary:
      (state: TAdvisorState) =>
      ({ clientId, startDate, endDate, accountIds }: TClientSummaryMapKeyParams) => {
        const { $activeLocale } = useNuxtApp();
        const locale = $activeLocale.value;
        const key = getKeyByStrings(clientId, startDate, endDate, locale, accountIds ?? "");
        return state._clientSummaryMap[key];
      },
    clientSummaryLoading:
      (state: TAdvisorState) =>
      ({ clientId, startDate, endDate, accountIds }: TClientSummaryMapKeyParams) => {
        const { $activeLocale } = useNuxtApp();
        const locale = $activeLocale.value;
        const key = getKeyByStrings(clientId, startDate, endDate, locale, accountIds ?? "");
        return state._clientSummaryMapLoading[key];
      },
    benchmarks: (state: TAdvisorState) => state._allBenchmarksCalculations,
  },
  actions: {
    // Mutations
    setOrganizations(orgs: TOrganization[]) {
      this._organizations = orgs;
    },
    setOrganizationsLoading(data: boolean) {
      this._organizationsLoading = data;
    },
    setSelectedOrganization(org: TOrganization | null) {
      this._organization = org;
    },
    handleClearState() {
      useUserStore().setUsers(null);
      this.setClients([]);
      this.setOrganizations([]);
      this.setAnalyticsReports([]);
      this.setGroupingOptions([]);
      this.setColumnOptions({});
      this.setSourceOptions({});
      this.setSelectedOrganization(null);
      this.setReports([]);

      this._clientSummaryMap = {};
      this._clientSummaryMapLoading = {};
      this._allBenchmarksCalculations = null;

      useCalcsStore().$reset();
      usePortfolioAssetsStore().$reset();
      usePortfolioTemplatesStore().$reset();
      useAssetClassesStore().$reset();
      useAssetListRulesStore().$reset();
    },
    setAnalyticsReportsLoading(payload: boolean) {
      this._analyticsReportsLoading = payload;
    },
    setAnalyticsReports(analyticsReports: TAnalyticsReportRow[]) {
      this._analyticsReports = analyticsReports;
    },
    setGroupingOptions(options: TAnalyticsReportsGroupingOption[]) {
      this._groupingOptions = options;
    },
    setGroupingOptionsLoading(loadingPromise: CancelablePromise<marshmallow_schema_EnumSchemaItems> | null) {
      this._groupingOptionsLoading = loadingPromise;
    },
    setColumnOptions(options: TAnalyticsReportsColumnOptions) {
      this._columnOptions = options;
    },
    setColumnOptionsLoading(loadingPromise: CancelablePromise<TAnalyticsReportsColumnOptions> | null) {
      this._columnOptionsLoading = loadingPromise;
    },
    setSourceOptions(options: TAnalyticsReportsSourceOptions) {
      this._sourceOptions = options;
    },
    setSourceOptionsLoading(loadingPromise: CancelablePromise<TAnalyticsReportsSourceOptions> | null) {
      this._sourceOptionsLoading = loadingPromise;
    },
    setSaveReportInProgress(inProgress: boolean) {
      this._saveReportInProgress = inProgress;
    },
    setDeleteTempReportInProgress(inProgress: boolean) {
      this._deleteTempReportInProgress = inProgress;
    },
    setTempReportFilters(filters: TAnalyticsReportsFilter[]) {
      this._tempReportFilters = [...filters];
    },
    setTempReportColumns(columns: TAnalyticsReportsColumnOption[]) {
      this._tempReportColumns = [...columns];
    },
    setClients(clients: TAdvisorClient[]) {
      this._clients = clients;
      const clientsMap = {} as TAdvisorClientsMap;
      clients.forEach((client) => {
        if (client.id) {
          clientsMap[client.id] = client;
        }
      });
      this._clientsMap = clientsMap;
    },
    setClientsLoading(isLoading: boolean) {
      this._clientsLoading = isLoading;
    },
    setReports(reports: HoldingsReport[]) {
      this._reports = reports;
    },
    setClient(client: TAdvisorHoldingReportClient | null) {
      this._client = client;
    },
    setClientReport(data: njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema) {
      this.setClient(data?.user ?? null);
      this._clientReport = data?.report_data ?? null;
      if (this._clientReport && Object.keys(this._clientReport).length > 0) {
        this._clientReport.created_at = data?.data?.created_at;
      }
    },

    // API actions
    fetchOrganizations() {
      this.setOrganizationsLoading(true);
      return DefaultService.getB2BV1AdvisorOrganizations().then(
        (resp: marshmallow_schema_OrganizationSchemaItems) => {
          this.setOrganizations(resp.items ?? []);
          if (this.organization) {
            const updatedOrganization = resp.items?.find(({ public_id }) => this.organization?.public_id === public_id);
            if (updatedOrganization) {
              this.setSelectedOrganization(updatedOrganization);
            }
          }
          this.setOrganizationsLoading(false);
          return resp?.items;
        },
        (error) => {
          this.setOrganizationsLoading(false);
          useNuxtApp().$notifyError({
            text: `Get Organizations list. ${error}`,
          });
        },
      );
    },
    fetchAnalyticsReports() {
      this.setAnalyticsReportsLoading(true);

      if (!this.organization?.public_id) {
        this.setAnalyticsReportsLoading(false);
        return;
      }

      return DefaultService.getB2BV1AnalyticsReports(this.organization.public_id).then(
        (resp: marshmallow_schema_ListReportSchemaItems) => {
          const res = resp?.items || [];
          this.setAnalyticsReports(res);
          this.setAnalyticsReportsLoading(false);
          return res;
        },
        (error) => {
          if (error.request && error.request.status === 404) {
            this.setAnalyticsReports([]);
          } else {
            useNuxtApp().$notifyError({
              text: `Get reports. ${error.errorMsg}`,
            });
          }
          this.setAnalyticsReportsLoading(false);
        },
      );
    },
    fetchAnalyticsGroupingOptions() {
      if (this.groupingOptionsLoading) {
        return this.groupingOptionsLoading;
      }
      // Caching the results, but this could possibly be not correct.
      if (this.groupingOptions && this.groupingOptions.length > 0) {
        return Promise.resolve(this.groupingOptions);
      }
      const promise = DefaultService.getB2BV1AnalyticsGroupingChoices();
      this.setGroupingOptionsLoading((promise as CancelablePromise<marshmallow_schema_EnumSchemaItems>) ?? null);
      promise.then(
        (resp: marshmallow_schema_EnumSchemaItems) => {
          const groupingItems = resp?.items;
          this.setGroupingOptions(groupingItems ?? []);
          this.setGroupingOptionsLoading(null);
          return groupingItems;
        },
        (error) => {
          if (error.request && error.request.status === 404) {
            this.setGroupingOptions([]);
          } else {
            useNuxtApp().$notifyError({
              text: `Get report grouping options. ${error}`,
            });
          }
          this.setGroupingOptionsLoading(null);
        },
      );
      return promise;
    },
    fetchAnalyticsColumnOptions() {
      if (this.columnOptionsLoading) {
        return this.columnOptionsLoading;
      }
      // Caching the results, but this could possibly be not correct.
      if (this.columnOptions && Object.keys(this.columnOptions).length > 0) {
        return Promise.resolve(this.columnOptions);
      }
      const promise = DefaultService.getB2BV1AnalyticsColumnChoices().then(
        (resp: marshmallow_schema_ColumnChoicesSchema) => {
          const columns = resp ?? {};
          this.setColumnOptions(columns);
          this.setColumnOptionsLoading(null);
          return columns;
        },
        (error) => {
          if (error.request && error.request.status === 404) {
            this.setColumnOptions({});
          } else {
            useNuxtApp().$notifyError({
              text: `Get report column options. ${error.errorMsg ?? error}`,
            });
          }
          this.setColumnOptionsLoading(null);
        },
      );
      this.setColumnOptionsLoading(promise as CancelablePromise<TAnalyticsReportsColumnOptions>);
      return promise;
    },
    deleteReport(reportId: string) {
      return DefaultService.deleteB2BV1AnalyticsReports(reportId).then(
        async (resp) => {
          await this.fetchAnalyticsReports();
          return resp;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Delete Report Error: ${error}`,
          });
        },
      );
    },
    getExportedReports({ reportIds, format }: { reportIds: string[]; format: TAnalyticsReportsExportFormat }) {
      return Promise.all(
        reportIds.map((reportId) => {
          return DefaultService.postB2BV1AnalyticsReportsExport(reportId, format).catch((e) => {
            useNuxtApp().$notifyError({
              text: `Export Reports Error: ${e}`,
            });
            return Promise.reject(e);
          });
        }),
      );
    },
    sendCalcTempReport({
      tempReportId,
      grouping,
      reportId,
      source,
      recalc,
    }: {
      tempReportId?: string;
      grouping: TAnalyticsReportsGrouping;
      reportId?: string;
      source: string;
      recalc: boolean;
    }) {
      const columnsParam = this.tempReportColumns.map(({ id }) => id).filter((id) => !!id) as string[];
      if (tempReportId && this.organization?.public_id && columnsParam.length > 0) {
        return DefaultService.patchB2BV1AnalyticsReportsUnsaved(tempReportId, this.organization.public_id, {
          columns: columnsParam,
          filters: this.tempReportFilters,
          grouping,
          source,
          recalculation_needed: recalc,
        }).then(
          () => {
            return tempReportId;
          },
          (error) => {
            useNuxtApp().$notifyError({
              text: `PATCH Calc Report. ${error}`,
            });
          },
        );
      } else if (this.organization?.public_id) {
        return DefaultService.postB2BV1AnalyticsReportsNewFromDefault(this.organization.public_id, {
          columns: columnsParam,
          filters: this.tempReportFilters,
          grouping,
          original_report_id: reportId,
          source,
          recalculation_needed: recalc,
        }).then(
          (resp: TAnalyticsReportsUnsavedReport) => {
            return resp?.temp_report_id;
          },
          (error) => {
            useNuxtApp().$notifyError({
              text: `POST Calc Report. ${error}`,
            });
          },
        );
      }
    },
    fetchTempReportById(tempReportId: string) {
      if (!tempReportId) {
        return Promise.reject(new Error("No temp report id found"));
      }
      return DefaultService.getB2BV1AnalyticsReportsUnsaved(tempReportId).then(
        (resp) => resp,
        (error) => {
          useNuxtApp().$notifyError({
            text: `GET TEMP Report. ${error}`,
          });
        },
      );
    },
    fetchAnalyticsSourceOptions() {
      if (this.sourceOptionsLoading) {
        return this.sourceOptionsLoading;
      }
      if (this.sourceOptions && Object.keys(this.sourceOptions).length > 0) {
        return Promise.resolve(this.sourceOptions);
      }
      const promise = DefaultService.getB2BV1AnalyticsSourceChoices().then(
        (resp: TAnalyticsReportsSourceOptions) => {
          const sources = resp;
          this.setSourceOptions(sources);
          this.setSourceOptionsLoading(null);
          return sources;
        },
        (error) => {
          if (error.request && error.request.status === 404) {
            this.setSourceOptions({});
          } else {
            useNuxtApp().$notifyError({
              text: `Get report source options. ${error.errorMsg ?? error}`,
            });
          }
          this.setSourceOptionsLoading(null);
        },
      );
      this.setSourceOptionsLoading(promise as CancelablePromise<TAnalyticsReportsSourceOptions>);
      return promise;
    },
    saveReport(payload: Omit<njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema, "organization">) {
      if (!this.organization?.public_id) {
        return Promise.reject("No organization set");
      }
      if (!payload.temp_report_id) {
        return Promise.reject("No organization set");
      }
      const _payload: njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema = {
        ...payload,
        organization: this.organization.public_id,
      };
      this.setSaveReportInProgress(true);

      return DefaultService.postB2BV1AnalyticsReports(_payload).then(
        (resp) => {
          this.setSaveReportInProgress(false);
          return resp;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Create New Report Error: ${error}`,
          });
          this.setSaveReportInProgress(false);
        },
      );
    },

    saveTempReportCharts({
      tempReportId,
      charts,
    }: {
      tempReportId: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      charts: Array<Record<string, any>>;
    }) {
      if (!this.organization?.public_id) {
        return Promise.reject("No organization set");
      }
      return DefaultService.patchB2BV1AnalyticsReportsUnsavedCharts(tempReportId, this.organization.public_id, {
        charts,
      });
    },
    async deleteTempReport(tempReportId: string) {
      if (!this.organization?.public_id) {
        return Promise.reject("No organization set");
      }
      this.setDeleteTempReportInProgress(true);

      await DefaultService.deleteB2BV1AnalyticsReportsUnsavedTask(tempReportId, this.organization.public_id);

      return DefaultService.deleteB2BV1AnalyticsReportsUnsaved(tempReportId).then(
        (resp) => {
          this.setDeleteTempReportInProgress(false);
          return resp;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Delete Unsaved Report Error: ${error}`,
          });
          this.setDeleteTempReportInProgress(false);
        },
      );
    },
    cancelTempReportCalculations(tempReportId: string) {
      if (!this.organization?.public_id) {
        return Promise.reject("No organization set");
      }
      if (!tempReportId) {
        return Promise.reject(new Error("No temp report id found"));
      }

      return DefaultService.deleteB2BV1AnalyticsReportsUnsavedTask(tempReportId, this.organization.public_id);
    },
    fetchAnalyticsReportRow({ reportId, rowId }: { reportId: string; rowId: string }) {
      if (!this.organization?.public_id) {
        return {
          request: Promise.reject("Organization Id is not set"),
        };
      }
      return DefaultService.getB2BV1AnalyticsReportsRows(reportId, rowId, this.organization.public_id);
    },
    getClients() {
      if (!this.organization?.public_id) {
        return {
          request: Promise.reject("Organization Id is not set"),
        };
      }

      this.setClientsLoading(true);
      return DefaultService.getB2BV1AdvisorClients(this.organization.public_id).then(
        (resp: marshmallow_schema_ClientResultSchemaItems) => {
          this.setClients(resp?.items ?? []);
          this.setClientsLoading(false);
          return resp?.items ?? [];
        },
        (error) => {
          this.setClients([]);
          useNuxtApp().$notifyError({
            text: `Get clients. ${error}`,
          });
          this.setClientsLoading(false);
        },
      );
    },
    deleteClients(selectedClients: TAdvisorClient[]) {
      if (!this.organization?.public_id) {
        return {
          request: Promise.reject("Organization Id is not set"),
        };
      }
      return DefaultService.deleteB2BV1AdvisorClients(
        selectedClients
          .map(({ id }) => {
            if (id) {
              return id;
            }
            return null;
          })
          .filter((id) => !!id)
          .join(","),
        this.organization.public_id,
      )
        .then(() => {
          this.getClients();
          return true;
        })
        .catch((error) => {
          useNuxtApp().$notifyError({
            text: `Error deleting multiple clients. ${error}`,
          });
        });
    },
    getExportedReportRows({
      reportId,
      format,
      rowIds,
    }: {
      reportId: string;
      format: TAnalyticsReportsExportFormat;
      rowIds: string[];
    }) {
      return DefaultService.postB2BV1AnalyticsReportsRowsExport(reportId, format, rowIds.join(",")).catch((error) => {
        useNuxtApp().$notifyError({
          text: `Export Reports Error: ${error}`,
        });
        return Promise.reject(error);
      });
    },
    fetchAnalyticsReport(reportId: string) {
      return DefaultService.getB2BV1AnalyticsReports1(reportId).catch((err) => {
        useNuxtApp().$notifyError({
          text: `Get report. ${err.errorMsg ?? err.message ?? err}`,
        });
        return Promise.reject(err);
      });
    },
    updateReport({
      reportId,
      data,
    }: {
      reportId: string;
      data: njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema;
    }) {
      if (!this.organization?.public_id) {
        return Promise.reject("Organization Id is not set");
      }
      return DefaultService.patchB2BV1AnalyticsReports(reportId, this.organization.public_id, {
        ...data,
      }).then(() => true);
    },
    getSharePortfolioToken(clientId: string) {
      return DefaultService.postB2BV1AdvisorClientsToken(clientId);
    },
    clearClientsConnectionCredentials(clientId: string) {
      return DefaultService.postB2BV1AdvisorSettingsAdminDeleteFlanksCredentials(clientId);
    },
    addClient(payload: Omit<njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema, "organization">) {
      if (!this.organization?.public_id) {
        return Promise.reject("Organization Id is not set");
      }
      return DefaultService.postB2BV1AdvisorClients({
        ...payload,
        organization: this.organization.public_id,
      }).then(
        async (res) => {
          this.getClients();
          return res;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Add client error. ${error}`,
          });
        },
      );
    },
    updateClient(payload: { id: string; data: njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema }) {
      return DefaultService.patchB2BV1AdvisorClients(payload.id, payload.data).then(
        async (res) => {
          this.getClients();
          return res;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Update client. ${error}`,
          });
        },
      );
    },
    getClient(clientId: string) {
      return DefaultService.getB2BV1AdvisorClients1(clientId);
    },
    async fetchClientSummary({ clientId, startDate, endDate, accountIds }: TClientSummaryMapKeyParams) {
      const { $activeLocale } = useNuxtApp();
      const locale = $activeLocale.value;
      const key = getKeyByStrings(clientId, startDate, endDate, locale, accountIds ?? "");
      this._clientSummaryMapLoading[key] = true;
      const summary = await DefaultService.getB2BV1AdvisorClientsSummary(
        clientId,
        endDate,
        startDate,
        locale,
        accountIds,
      ).catch(() => null);
      this._clientSummaryMap[key] = summary;
      this._clientSummaryMapLoading[key] = false;
    },
    async fetchAllBenchmarksCalculations() {
      if (!this.organization?.public_id) {
        return {
          request: Promise.reject("Organization Id is not set"),
        };
      }
      const { fetchBenchmarks } = usePortfolioBenchmarksStore();
      this._allBenchmarksCalculations = [];
      const benchmarks = await fetchBenchmarks().catch(() => [] as PortfolioBenchmark[]);
      const benchmarkIds = benchmarks
        ?.map((benchmark: PortfolioBenchmark) => benchmark.uuid)
        .filter((id) => !!id)
        .join(",");
      if (benchmarkIds) {
        const res = await DefaultService.getB2BV1PortfolioBuilderCalculateBenchmark(
          this.organization.public_id,
          benchmarkIds,
        ).catch(() => null);
        if (res) {
          this._allBenchmarksCalculations = res.portfolios ?? [];
        }
      }
    },
    getReports(userId: string) {
      this.setReports([]);
      return DefaultService.getB2BV1AdvisorHoldingReports(userId).then(
        (resp: marshmallow_schema_HoldingsReportSchemaItems) => {
          const reports = resp?.items ?? [];
          this.setReports(reports);
          return reports;
        },
        (error) => {
          this.setReports([]);
          useNuxtApp().$notifyError({
            text: `Get reports. ${error}`,
          });
          return null;
        },
      );
    },
    getClientAccounts(clientId: string) {
      return DefaultService.getB2BV1AdvisorAccounts(clientId).then(
        (res: marshmallow_schema_AccountDetailsSchemaItems) => res?.items ?? [],
      );
    },
    getClientPositions(clientId: string) {
      return DefaultService.getB2BV1AdvisorPositions(clientId);
    },
    getClientPositionsByAccountId({ clientId, accountId }: { clientId: string; accountId: string }) {
      return DefaultService.getB2BV1AdvisorPositions(clientId, accountId).then(
        (res: njorda_libs_schemas_positions_report_PositionsSchema) => res?.data,
      );
    },
    getClientAccountByClientIdAndAccountId({ clientId, accountId }: { clientId: string; accountId: string }) {
      return DefaultService.getB2BV1AdvisorAccounts1(accountId, clientId);
    },
    getClientReport({ userId, reportId }: { userId: string; reportId: string }) {
      // getReport
      this.setClient(this.clients.find((client) => client.id === userId) ?? null);
      const { $activeLocale, $notifyError } = useNuxtApp();
      const language = $activeLocale.value;
      return DefaultService.getB2BV1AdvisorHoldingReports1(userId, reportId, language).then(
        (report) => {
          this.setClientReport(report);
          return report;
        },
        (error) => {
          $notifyError({ text: `Get client report. ${error.errorMsg ?? error.message ?? error}` });
        },
      );
    },
  },
});
