export const njordaCustomTheme = {
  dark: false,
  colors: {
    "background": "#FCF8F3",
    "divider": "#F9F1E8",
    "border": "#F7EDE2",
    "loader": "#FC4832",
    "surface": "#FFF",
    "surface-loader": "#FC4832",
    "text": "#303030",
    "text-base": "#303030",
    "anchor": "#03A48D",
    "primary": "#FC4832",
    "primary-base": "#FC4832",
    "primary-lighten-1": "#FF4F37",
    "primary-lighten-2": "#F5E2DC",
    "primary-darken-1": "#E3412D",
    "secondary": "#03A48D",
    "secondary-base": "#03A48D",
    "secondary-lighten-1": "#D8F2E5",
    "secondary-lighten-2": "#E9F1ED",
    "secondary-lighten-3": "#F4F8F6",
    "secondary-lighten-4": "#C7DDD2",
    "secondary-darken-1": "#03947F",
    "secondary-darken-2": "#058976",
    "secondary-text": "#303030",
    "error": "#FC4832",
    "error-base": "#FC4832",
    "error-lighten-1": "#FF4F37",
    "error-darken-1": "#E3412D",
    "warning-base": "#FFB93F",
    "warning-lighten-1": "#FFDD54",
    "warning-darken-1": "#F8AC39",
    "success-base": "#03A48D",

    "info": "#FC4832",
    "info-base": "#FC4832",
    "info-surface": "#F9F1E8",
    "info-surface-text": "#303030",
    "info-surface-lighten-1": "#FCF8F3",
    "info-surface-lighten-1-text": "#303030",

    "info-surface2": "#E9F1ED",
    "info-surface2-base": "#E9F1ED",
    "info-surface2-text": "#303030",
    "info-surface2-lighten-1": "#F4F8F6",
    "info-surface2-lighten-1-text": "#303030",

    "positive": "#03A48D",
    "positive-base": "#03A48D",
    "positive-surface": "#E9F1ED",
    "positive-surface-text": "#303030",
    "positive-surface-lighten-1": "#F4F8F6",
    "positive-surface-lighten-1-text": "#303030",

    "negative": "#FC4832",
    "negative-base": "#FC4832",
    "negative-surface": "#FFF5F5",
    "negative-surface-text": "#303030",

    "gray": "#9E9E9E",
    "gray-base": "#9E9E9E",
    "gray-lighten-1": "#E4E5E5",
    "gray-lighten-2": "#EAEAEA",
    "gray-lighten-3": "#B7B7B7",
    "gray-lighten-4": "#F9F9F9",
    "gray-lighten-5": "#D0D0D0",
    "gray-darken-1": "#757575",

    "chart": "#03A48D",
    "chart-base": "#03A48D",
    "chart-accent-1": "#55bf8f",
    "chart-accent-2": "#8cd891",
    "chart-accent-3": "#c4f093",
    "chart-accent-4": "#FFC500",
    "chart-accent-5": "#FFB400",
    "chart-accent-6": "#F8A000",
    "chart-accent-7": "#F03529",
    "chart-accent-8": "#D81A1A",
    "chart-accent-9": "#BB000C",
  },
};

export const themes = {
  AVENIR_INVEST: {
    background: "#253745",
    primary: "#E0D9CF",
    secondary: "#C27A62",
  },
  MAX_MATTHIESSEN: {
    "background": "#133A74",
    "anchor": "#133A74",
    "primary": "#30497B",
    "secondary": "#133A74",
    "info-surface": "#D7D7D7",
    "info-surface-lighten-1": "#EAEAEA",
    "info-surface2": "#D7D7D7",
    "info-surface2-lighten-1": "#EAEAEA",
  },
  KNOX_CAPITAL: {
    background: "#132038",
    primary: "#132038",
    secondary: "#748EBE",
  },
  SVP: {
    background: "#FFFFFF",
    primary: "#918271",
    secondary: "#726659",
  },
  NJORDA_MOCK_WHITE: {
    background: "#A8A8A8",
    anchor: "#fff",
    primary: "#EAEAEA",
    secondary: "#A8A8A8",
  },
  MYBENEFIT: {
    background: "#fff",
    primary: "#303030",
    secondary: "#303030",
  },
  SEB_POC: {
    "background": "#eee",
    "primary": "#333",
    "secondary": "#868686",
    "success": "#308800",
    "anchor": "#308800",
    "breadcrumb": "#0062BC",
    "chart": "#308800",
    "chart-base": "#308800",
    "chart-accent-1": "#45B400",
    "chart-accent-2": "#60CD18",
  },
};
