import { LOADER } from "./store/app";

export const DEFAULT_LOGO_IMAGE = "/njorda_logo.svg";

export const themes = {
  AVENIR_INVEST: {
    name: "Avenir Invest",
    locale: { name: "English", code: "en", language: "en-US", file: "en.json" },
    background_image: "",
    favicon: "/avenirinvest/favicon.ico",
    show_language_switcher: true,
    logo_image: "/avenirinvest/logo.svg",
  },
  MAX_MATTHIESSEN: {
    name: "Max Matthiessen",
    background_image: "",
    favicon: "/mm/mm-favicon.ico",
    show_language_switcher: false,
    logo_image: "/mm/mm-logo.svg",
    logo_image_variant1: "/mm/mm-blue-logo.png",
  },
  KNOX_CAPITAL: {
    name: "Knox Capital",
    locale: { name: "English", code: "en", language: "en-US", file: "en.json" },
    background_image: "",
    favicon: "/knoxcapital/knox-favicon.ico",
    show_language_switcher: true,
    logo_image: "/knoxcapital/knox-capital-logo.png",
  },
  SVP: {
    name: "SVP",
    background_image: "",
    favicon: "/svp/favicon-svp.ico",
    show_language_switcher: false,
    logo_image: "/svp/svp-logo.svg",
  },
  NJORDA_MOCK_WHITE: {
    name: "Njorda White",
    background_image: "",
    show_language_switcher: false,
    logo_image: "/white-logo.svg",
  },
};

export const productDemoThemes = {
  MYBENEFIT: {
    name: "My Benefit",
    locale: { name: "Swedish", code: "sv", language: "sv", file: "sv.json" },
    background_image: "",
    favicon: "/my-benefit/favicon.ico",
    show_language_switcher: false,
    logo_image: "",
  },
};

export const PoCThemes = {
  SEB_POC: {
    name: "SEB PoC",
    locale: { name: "English", code: "en", language: "en-US", file: "en.json" },
    background_image: "",
    favicon: "/seb-poc/favicon.ico",
    show_language_switcher: false,
    logo_image: "",
    spinner: LOADER.CIRCLE,
    show_period_picker: true,
  },
};

export type TThemeKey = keyof typeof themes;

export const ORG_PUBLIC_ID_TO_THEME_KEY_MAP: Record<string, TThemeKey> = {
  "avenirinvest": "AVENIR_INVEST",
  "njorda-white": "NJORDA_MOCK_WHITE",
  "knoxcapital": "KNOX_CAPITAL",
  "svp": "SVP",
  "mm-test": "MAX_MATTHIESSEN",
  "mm": "MAX_MATTHIESSEN",
};

export type TOrgIdToThemeKey = keyof typeof ORG_PUBLIC_ID_TO_THEME_KEY_MAP;
