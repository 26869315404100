<template>
  <v-app>
    <v-main :class="$route.params.org_id">
      <v-container fill-height fluid class="portfolio-sharing align-start">
        <v-row class="portfolio-sharing__content">
          <v-col cols="12" md="12">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { useAppStore } from "~/store/app";

const { layoutSettings } = useAppStore();
const favicon = layoutSettings?.favicon;
const title = layoutSettings?.name;

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: favicon,
    },
  ],
  titleTemplate: title,
});
</script>

<style lang="scss" scoped>
@use "sass:map" as map;
@use "@/assets/style/variables" as vars;

.portfolio-sharing {
  &__content {
    z-index: 2;
  }
  &__background-illustration {
    position: absolute;
    width: calc(100% - 67vw);
    bottom: 0;
    left: 0;
    z-index: -1 !important;

    &__fade {
      opacity: 0.7;
    }
  }
}

@media #{map.get(vars.$display-breakpoints, 'xs')} {
  .portfolio-sharing {
    &__background-illustration {
      width: calc(100% - 25vw);
    }
  }
}
</style>
