import { useAppStore } from "~/store/app";

export default defineNuxtRouteMiddleware((to, from) => {
  const { setBreadcrumbsConfig } = useAppStore();
  const { $i18n } = useNuxtApp();

  if (to?.name !== from?.name) {
    setBreadcrumbsConfig({
      items: [
        {
          title: `${$i18n.t("common.loading")}...`,
          uppercase: true,
        },
      ],
    });
  }
});
