import accounting from "~/utils/accounting";
import { DEFAULT_CURRENCY } from "@lib/constants/connect";

export type TMonetaryAmount = {
  precision?: number;
  amount: number;
  currency: string;
};

export type TMonetaryAmountFormat = {
  symbol: string;
  precision: number;
};

function setDefaultNumberFormat() {
  // Settings object that controls default parameters for library methods:
  accounting.settings = {
    currency: {
      symbol: "", // default currency symbol is '$'
      decimal: ".", // decimal point separator
      thousand: "\xA0", // thousands separator
      precision: 2, // decimal places
      format: {
        pos: "%v %s",
        neg: "-%v %s",
        zero: "%v %s",
      },
    },
    number: {
      precision: 2,
      thousand: "\xA0",
      decimal: ".",
    },
  };
}

export function formatCurrency(param: number | null, format?: TMonetaryAmountFormat) {
  let number;
  if (typeof param === "number") {
    setDefaultNumberFormat();
    number = param;
  } else {
    return param;
  }

  if (typeof format === "object") {
    number = accounting.formatMoney(number, format);
  } else {
    number = accounting.formatMoney(number);
  }

  return number;
}

export function formatNumber(param: number, format?: number) {
  let number: number;
  if (typeof param === "number") {
    setDefaultNumberFormat();
    number = param;
  } else {
    return param;
  }

  return accounting.formatNumber(number, format);
}

export function formatNumberWithOptionalPrecision(value: number, precision = 2) {
  const _precise_val = parseFloat(value.toPrecision(precision + 1));

  // If value is 0 - it is 0;
  if (value === 0) {
    return value;
  }

  if (value - _precise_val !== 0) {
    return _precise_val;
  }

  return value;
}

export const convertFromSnakecaseToReadableString = (inputString: string) => {
  return inputString.replace(/_/g, " ").replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getNumberPrecision = (a: number) => {
  if (!a || !isFinite(a) || Number.isNaN(a)) return 0;
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
};

export const monetaryAmount = (
  value: TMonetaryAmount = {
    amount: 0,
    currency: DEFAULT_CURRENCY,
  },
) => {
  const amount = value?.precision ? value?.amount : Math.round(value?.amount ?? 0);
  return formatCurrency(amount, {
    symbol: value?.currency,
    precision: value?.precision || 0,
  });
};

export const getMonetaryAmount = (amount: number, locale = "sv-SE"): string => {
  return Math.round(amount).toLocaleString(locale);
};

export const formatSwedishCurrency = (amount: number) => {
  // Create a new instance of Intl.NumberFormat with Swedish locale and no decimal places
  const formatter = new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  // Format the amount
  return formatter.format(amount);
};

export const getKeyByStrings = (...strings: string[]) => {
  return strings.join("_");
};

export const convertAbsoulteNumberIntoPercentage = (num?: number, fallback = 0) => {
  if (typeof num !== "number" || Number.isNaN(num)) {
    return fallback;
  }
  return (num * 1000) / 10;
};

export const formatAbsoluteNumberInPercentage = (num?: number, precision = 2, fallback: number | string = "-") => {
  if (typeof num !== "number" || Number.isNaN(num)) {
    return fallback;
  }
  return `${convertAbsoulteNumberIntoPercentage(num).toFixed(precision)}%`;
};
