<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6 3H20.4V21H4V12.6H8.8V7.8H13.6V3ZM13.6 9.8H10.8V19H13.6V9.8ZM15.6 19H18.4V5H15.6V19ZM8.8 19V14.6H6V19H8.8Z"
      fill="currentColor"
    />
  </svg>
</template>
