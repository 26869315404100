import { transform, isEqual, isObject, snakeCase, isArray } from "~/utils/lodash";
/*
    This function gets the elements with different values in comparison with base object elements and returns those elements with key's in snake case.
*/
export const getPatchReqPayload = (object, base) => {
  return transform(object, (result, value, key) => {
    const transformed_key = snakeCase(key);
    if (!isEqual(value, base[transformed_key])) {
      result[transformed_key] = isObject(value) && isObject(base[key]) ? getPatchReqPayload(value, base[key]) : value;
    }
  });
};

export const getPostReqPayload = (data) => {
  return transform(data, (acc, value, key, target) => {
    const snakeCaseKey = isArray(target) ? key : snakeCase(key);
    acc[snakeCaseKey] = isObject(value) ? getPostReqPayload(value) : value;
  });
};

export const BLOB_ENDPOINTS = [
  ["POST", "/b2b/v1/advisor/holding-reports/{client_id}/{report_id}/pdf/result"],
  ["POST", "/b2b/v1/analytics/reports/{report_id}/export"],
  ["POST", "/b2b/v1/analytics/reports/{report_id}/rows/export"],
  ["POST", "/b2b/v1/calc-params/export"],
  ["POST", "/b2b/v1/client/holdings-report/{report_id}/pdf/result"],
  ["POST", "/b2b/v1/portfolio-builder/calculate/proposals/export"],
  ["POST", "/b2b/v1/portfolio-builder/data/asset-lists/export"],
  ["POST", "/b2b/v1/portfolio-builder/data/proposals/export"],
];

export function serializeUrlParams(obj) {
  var str = [];
  for (var p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] !== null && obj[p] !== "" && obj[p] !== undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
