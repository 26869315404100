import type {
  CancelablePromise,
  DataProviderSchemaItems,
  DataSessionConnection,
  marshmallow_schema_AccountSchemaItems,
  marshmallow_schema_PositionDetailsSchemaItems,
} from "@/api/njorda";
import axios from "axios";
import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import { handleAccountApiError } from "@/utils/error-handler";
import {
  type TUserPortfolioAccount,
  type TUserPortfolioAccountWithPositions,
  type TUserPortfolioPosition,
  type TUserPortfolioAccountInstitution,
  LOADING_CONNECTION_STATUSES,
  FAILED_CONNECTION_STATUES,
  SUCCESS_CONNECTION_STATUSES,
} from "@lib/interface/portfolio-report";
import {
  type TPortfolioSharingProvider,
  type TSelectedProviders,
  type TPortfolioReportDetails,
  type TPortfolioReportOrganization,
  type TPortfolioCollectionUrl,
} from "~/interfaces/portfolio-report";

export type TProviderSelectionState = { providerId: string; selected: boolean };

type TPortfolioSharingStoreState = {
  _reportId: string | null;
  _reportData: TPortfolioReportDetails | null; // ToDo: Type
  _requestInfoLoaded: boolean;
  _orgPublicId: string | null;
  _userId: string | null;
  _holdingReportsFetchInProgress: boolean;
  _openReportDirectly: boolean;
  _reportFetchFailed: boolean;
  _selectedProviders: TSelectedProviders;
  _showTinkIframe: boolean;
  _iframeUrl: string | null;
  _providers: TPortfolioSharingProvider[];
  _cancelConnectStartRequest: CancelablePromise<TPortfolioCollectionUrl> | null;
  _accounts: TUserPortfolioAccount[];
  _positions: TUserPortfolioPosition[];
  _isHoldingReportsFetchInProgress: boolean;
  _connections: DataSessionConnection[] | null;
};

export const usePortfolioSharingStore = defineStore("portfolioSharingStore", {
  state: () =>
    ({
      _reportId: null,
      _reportData: null,
      _requestInfoLoaded: false,
      _orgPublicId: null,
      _userId: null,
      _holdingReportsFetchInProgress: false,
      _openReportDirectly: false,
      _reportFetchFailed: false,
      _selectedProviders: {} as TSelectedProviders,
      _showTinkIframe: false,
      _iframeUrl: null,
      _providers: [],
      _cancelConnectStartRequest: null,
      _accounts: [],
      _positions: [],
      _isHoldingReportsFetchInProgress: false,
      _connections: null,
    }) as TPortfolioSharingStoreState,
  getters: {
    accounts: (state) => state._accounts,
    positions: (state) => state._positions,
    providers: (state) => state._providers,
    reportId: (state) => state._reportId,
    reportData: (state) => state._reportData,
    requestInfoLoaded: (state) => state._requestInfoLoaded,
    reportFetchFailed: (state) => state._reportFetchFailed,
    orgPublicId: (state) => state._orgPublicId,
    userId: (state) => state._userId,
    getSelectedProviders: (state) => state._selectedProviders,
    getProviders: (state) => state._providers,
    iframeUrl: (state) => state._iframeUrl,
    showTinkIframe: (state) => state._showTinkIframe,
    openReportDirectly: (state) => state._openReportDirectly,
    isHoldingReportsFetchInProgress: (state) => state._isHoldingReportsFetchInProgress,
    getGroupedAccounts(state) {
      const { $logError } = useNuxtApp();
      const hasManualInsitution = (this.getManualAccounts ?? []).length > 0;

      const institutions = (state._connections ?? []).map((connection) => {
        const institution: TUserPortfolioAccountInstitution = {
          ...(connection.data_provider?.institution ?? {}),
          accounts: [],
        };
        const currentProvider = state._providers.find(
          (provider: TPortfolioSharingProvider) =>
            provider?.institution?.id === connection?.data_provider?.institution?.id,
        );
        institution.identifier = currentProvider && currentProvider.identifier;
        institution.id = currentProvider?.institution?.id;
        const isLoading = LOADING_CONNECTION_STATUSES.includes(connection.state ?? "unknown");
        const isFailed = FAILED_CONNECTION_STATUES.includes(connection.state ?? "unknown");
        const isDone = SUCCESS_CONNECTION_STATUSES.includes(connection.state ?? "unknown");
        const isInUnknownStatus = !isLoading && !isFailed && !isDone;
        institution.connection = {
          ...connection,
          isLoading,
          isFailed,
          isDone,
          isInUnknownStatus,
        };
        return institution;
      });
      if (hasManualInsitution) {
        institutions.push({
          connection: {
            isLoading: false,
            isFailed: false,
            isDone: true,
            isInUnknownStatus: false,
          },
          accounts: [],
          isManualAccount: true,
        });
      }
      state._accounts.forEach((account: TUserPortfolioAccount) => {
        // Add positions for each account
        const positions: TUserPortfolioPosition[] = [];
        const _account: TUserPortfolioAccountWithPositions = {
          ...account,
        };
        state._positions.forEach((position: TUserPortfolioPosition) => {
          if (position.account_id === account.id) {
            positions.push(position);
          }
        });

        _account.positions = positions;
        // Group accounts by financial institution
        if (_account?.institution) {
          const institution = institutions.find((institution) => institution.id === _account?.institution?.id);

          if (!institution) {
            $logError(
              `Could not find institution ${_account?.institution?.id ?? "<id not found>"} from account ${_account.id} in connections list for report ${state._reportId}`,
            );
            return;
          }

          institution.accounts.push(_account);
        } else if (_account.is_manual_account) {
          const institution = institutions.find((institution) => institution.isManualAccount);

          if (!institution) {
            $logError(
              `Could not find manual institution for ${_account?.institution?.id ?? "<id not found>"} from account ${_account.id} in connections list for report ${state._reportId}`,
            );
            return;
          }

          institution.accounts.push(_account);
        }
      });
      return institutions;
    },
    getManualAccounts(state) {
      return state._accounts.filter((account: TUserPortfolioAccount) => account.is_manual_account);
    },
    getConnections(state) {
      return state._connections;
    },
  },
  actions: {
    resetState() {
      this.$reset();
    },
    setReportId(reportId: string) {
      this._reportId = reportId;
    },
    setReportData(data: TPortfolioReportDetails) {
      this._reportData = data;
    },
    setUserId(id: string | null) {
      this._userId = id;
    },
    setOrgPublicId(id: string | null) {
      this._orgPublicId = id;
    },
    setRequestInfoLoaded(loaded: boolean) {
      this._requestInfoLoaded = loaded;
    },
    setActionOnFinish(organization?: TPortfolioReportOrganization) {
      if (organization) {
        this._openReportDirectly = organization?.public_settings?.redirect_on_connect === "report_view";
      }
    },
    setReportFailed() {
      this._reportFetchFailed = true;
    },
    setProviders(data: TPortfolioSharingProvider[]) {
      this._providers = data;
    },
    setAccounts(data: TUserPortfolioAccount[]) {
      this._accounts = data;
    },
    setPositions(data: TUserPortfolioPosition[]) {
      this._positions = data;
    },
    startGetHoldingReportsRequest() {
      this._isHoldingReportsFetchInProgress = true;
    },
    endGetHoldingReportsRequest() {
      this._isHoldingReportsFetchInProgress = false;
    },
    getHoldingsReport() {
      if (this._holdingReportsFetchInProgress) {
        return false;
      }
      if (!this._reportId) {
        return;
      }
      this.startGetHoldingReportsRequest();
      const language = useNuxtApp().$activeLocale.value;
      this.fetchConnections();
      return DefaultService.getB2BV1ClientHoldingsReport(this._reportId, language)
        .then((data: TPortfolioReportDetails) => {
          this.setReportData(data);
          this.setUserId(data.user?.id ?? null);
          this.setOrgPublicId(data.organization?.public_id ?? null);
          this.setActionOnFinish(data.organization);
          this.setRequestInfoLoaded(true);
          this.endGetHoldingReportsRequest();
        })
        .catch((error) => {
          const errorMsg = handleAccountApiError(error);
          const { t } = useI18n();
          useNuxtApp().$notifyError({
            text: `${t("portfolioReport.getHoldingReportsError")} ${errorMsg}`,
          });
          this.setReportFailed();
        });
    },
    getDataProviders(orgId?: string) {
      const _orgId = orgId || this._orgPublicId;
      if (!_orgId) {
        return Promise.reject("No org id provided");
      }
      return DefaultService.getB2BV1ClientConnectDataProviders(_orgId)
        .then((resp: DataProviderSchemaItems) => {
          this.setProviders(resp?.items ?? []);
        })
        .catch((error) => {
          const errorMsg = handleAccountApiError(error);
          const { t } = useI18n();
          useNuxtApp().$notifyError({
            text: `${t("portfolioReport.getHoldingReportsError")} ${errorMsg}`,
          });
        });
    },
    clearSelectedProviders() {
      this._selectedProviders = {};
    },
    setTinkIframeState(value: boolean) {
      this._showTinkIframe = value;
    },
    setIframeUrl(data: string | null) {
      this._iframeUrl = data;
    },
    closeTinkIframe() {
      this.setTinkIframeState(false);
      this.setIframeUrl(null);
    },
    setCancelConnectStartRequest(request: CancelablePromise<TPortfolioCollectionUrl> | null) {
      this._cancelConnectStartRequest = request;
    },
    setProviderSelectionState({ providerId, selected }: TProviderSelectionState) {
      this._selectedProviders = {
        ...this._selectedProviders,
        [providerId]: selected,
      };
    },
    tryCancelConnectRequest() {
      if (this._cancelConnectStartRequest) {
        this._cancelConnectStartRequest.cancel();
        this.setCancelConnectStartRequest(null);
      }
    },
    requestIframeUrl({ reportId, providerIds }: { reportId: string; providerIds: string[] }) {
      this.tryCancelConnectRequest();
      const request = DefaultService.postB2BV1ClientConnectStart({
        report_id: reportId,
        data_provider_ids: providerIds,
      });
      this.setCancelConnectStartRequest((request as CancelablePromise<TPortfolioCollectionUrl>) ?? null);
      request.then(
        (resp: TPortfolioCollectionUrl) => {
          this.setCancelConnectStartRequest(null);
          const { url } = resp ?? {};
          if (!url) {
            return Promise.reject("Url can't be undefined");
          }
          const language = useNuxtApp().$activeLocale.value;
          const iframeUrl = new URL(url);
          // For Local dev uncomment this:
          // iframeUrl.protocol = "http";
          // iframeUrl.hostname = "localhost";
          // iframeUrl.port = "2003";
          iframeUrl.searchParams.set("language", language);
          this.setIframeUrl(iframeUrl.href);
          this.setTinkIframeState(true);
        },
        (error) => {
          if (!axios.isCancel(error)) {
            useNuxtApp().$notifyError({
              text: `Get tink iframe. ${error}`,
            });
          }
        },
      );
      return request;
    },
    receiveMessageFromTink(message: MessageEvent) {
      const isFromConnectIframe = this._iframeUrl && new URL(message.origin).host === new URL(this._iframeUrl).host;
      if (isFromConnectIframe) {
        const data = JSON.parse(message.data);
        if (data.event === "done") {
          this.closeTinkIframe();
          useRouter().push({
            path: `/client/${this._orgPublicId}/portfolio-sharing/step-2/${this._reportId}`,
          });
          this.getHoldingsReport();
        } else if (data.event === "navigate_back") {
          this.closeTinkIframe();
        } else if (data.event === "close") {
          this.closeTinkIframe();
        } else {
          useNuxtApp().$logInfo("Unhandled message (from connect iframe)", message);
        }
      }
    },
    deleteConnection(institutionId: string) {
      if (!this._reportId) {
        return Promise.reject("report id is not set");
      }
      return DefaultService.deleteB2BV1ClientConnections(institutionId, this._reportId).then(
        async () => {
          await Promise.all([this.getAccounts(), this.getPositions()]);
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `delete Connection. ${error}`,
          });
        },
      );
    },
    getAccounts() {
      if (this._reportId) {
        return DefaultService.getB2BV1ClientAccounts(this._reportId)
          .then((resp: marshmallow_schema_AccountSchemaItems) => {
            this.setAccounts(resp?.items ?? []);
          })
          .catch((error) => {
            const errorMsg = handleAccountApiError(error);
            const { t } = useI18n();
            useNuxtApp().$notifyError({
              text: `${t("portfolioReport.getAccountsError")} ${errorMsg}`,
            });
          });
      }
    },
    getPositions() {
      if (this._reportId) {
        return DefaultService.getB2BV1ClientPositions(this._reportId)
          .then((resp: marshmallow_schema_PositionDetailsSchemaItems) => {
            this.setPositions(resp?.items ?? []);
          })
          .catch((error) => {
            const errorMsg = handleAccountApiError(error);
            const { t } = useI18n();
            useNuxtApp().$notifyError({
              text: `${t("portfolioReport.getPositionsError")} ${errorMsg}`,
            });
          });
      }
    },
    async deleteManualAccounts() {
      const requests = [] as CancelablePromise<void>[];
      this.getManualAccounts.forEach((account) => {
        if (!account.id || !this._reportId) {
          return;
        }
        requests.push(DefaultService.deleteB2BV1ClientAccounts(account.id, this._reportId));
      });
      await Promise.all(requests)
        .then(async () => {
          await Promise.all([this.getAccounts(), this.getPositions()]);
        })
        .catch((error) => {
          useNuxtApp().$notifyError({
            text: `Delete Account. ${error}`,
          });
        });
    },
    async fetchConnections() {
      if (this._reportId) {
        const connections = await DefaultService.getB2BV1ClientConnections(this._reportId).catch((err) => {
          console.error(">>>getB2BV1ClientConnections error", err);
          return null;
        });
        if (connections) {
          this._connections = connections.items ?? null;
        }
      }
    },
  },
});
