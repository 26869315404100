import type { TAdvisorClient, TAdvisorClientAdvisor, TAdvisorClientExtended } from "~/interfaces/advisor";

export default function useClientName(
  client?: TAdvisorClientAdvisor | TAdvisorClient | TAdvisorClientExtended,
  fallback = "",
) {
  if (!client) {
    return fallback;
  }
  return (
    (client as TAdvisorClientExtended).fullName ??
    [
      (client as TAdvisorClient).first_name ?? (client as TAdvisorClientExtended).firstName,
      (client as TAdvisorClient).last_name ?? (client as TAdvisorClientExtended).lastName,
    ]
      .filter((n) => !!n)
      .join(" ")
      .trim()
  );
}
