import { useAdvisorStore } from "@/store/advisor";
import { ASSET_LIST_TYPES, PORTFOLIO_PROPOSALS_ASSET_PRICE_HISTORY_RANGE } from "@/constants/advisor";
import { useAssetListsStore } from "./asset-lists";
import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import type {
  Asset,
  AssetAllocationResult,
  AssetListResult,
  njorda_bps_b2b_v1_schemas_asset_allocation_CreateAssetAllocationSchema,
  njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema,
  njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems,
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema,
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema,
} from "~/api/njorda";

type TPortfolioAssetsStore = {
  _assetLists: AssetListResult[] | null;
  _assetListInProgress: boolean;
  _assetsAllocations: AssetAllocationResult[];
  _assetsAllocationsInProgress: boolean;
};

export type TAssetFull = Asset & {
  name?: string;
  slug: string;
  asset_list_id: string;
  is_archived: boolean;
};
export type TAssetClassFull = AssetListResult & {
  assets: TAssetFull[];
};
export type TAssetClassesMap = Record<string, TAssetClassFull>;

export const usePortfolioAssetsStore = defineStore("portfolioAssetsStore", {
  state: () =>
    ({
      _assetLists: null,
      _assetListInProgress: false,
      _assetsAllocations: [],
      _assetsAllocationsInProgress: false,
    }) as TPortfolioAssetsStore,
  getters: {
    assetsListInProgress(state: TPortfolioAssetsStore): boolean {
      return state._assetListInProgress;
    },
    assetsLists(state: TPortfolioAssetsStore): AssetListResult[] | undefined {
      return state._assetLists?.filter((assetsList) => !assetsList.is_archived);
    },
    allAssets(): Asset[] | undefined {
      if (!this.assetsLists || this.assetsLists.length < 1) {
        return [];
      }
      const assets = this.assetsLists.reduce((assetsAcc, assetList) => {
        return [
          ...assetsAcc,
          ...(assetList.assets ?? []).map((asset) => ({
            ...asset,
            asset_list_id: assetList.id,
            asset_list_name: assetList.name,
          })),
        ];
      }, [] as Asset[]);
      return assets;
    },
    allAssetsMap(): Record<string, Asset> | null {
      if (!this.allAssets || this.allAssets.length < 1) {
        return null;
      }
      const map: Record<string, Asset> = {};
      this.allAssets.forEach((asset: Asset) => {
        map[asset.ticker] = asset;
      });
      return map;
    },
    assetClassesMap(): Record<string, TAssetClassFull> {
      const map: Record<string, TAssetClassFull> = {};
      (this.assetsLists ?? []).forEach((assetsClass: AssetListResult) => {
        if (!assetsClass.id) {
          return;
        }
        map[assetsClass.id] = {
          ...assetsClass,
          assets: (assetsClass.assets ?? [])
            .map((asset: Asset) => {
              const _asset = {
                ...asset,
                asset_list_id: assetsClass.id,
                is_archived: assetsClass.is_archived ?? false,
              } as TAssetFull;
              return _asset;
            })
            .sort(({ name: nameA, ticker: tickerA }, { name: nameB, ticker: tickerB }) =>
              compareStringsWithLocale(nameA ?? tickerA, nameB ?? tickerB),
            ),
        } as TAssetClassFull;
      });
      return map;
    },
    portfolioAssetsAllocations: (state: TPortfolioAssetsStore) => state._assetsAllocations,
    portfolioAssetsAllocationsInProgress: (state: TPortfolioAssetsStore) => state._assetsAllocationsInProgress,
  },
  actions: {
    // Mutators:
    setPortfolioAssets(assets: AssetListResult[]) {
      this._assetLists = assets;
    },
    setPortfolioAssetsAllocations(portfolioAssetsAllocations: AssetAllocationResult[]) {
      this._assetsAllocations = [...portfolioAssetsAllocations];
    },

    // API Actions
    async fetchAssets(withArchived = true, storeInCurrentStore = true) {
      this._assetListInProgress = true;
      const assets = await useAssetListsStore()
        .getAssets(ASSET_LIST_TYPES.PORTFOLIO_BUILDER, withArchived)
        .then((items) => {
          return items ?? null;
        })
        .catch(() => null);

      if (storeInCurrentStore) {
        this.setPortfolioAssets(
          (assets ?? []).map((assets) => ({
            ...assets,
            slug: `${assets?.name?.toLowerCase().split(" ").join("-")}-${assets.id}`,
          })),
        );
      }

      this._assetListInProgress = false;

      return assets;
    },
    createNewAssetsList(
      assetsList: Omit<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema, "organization">,
    ) {
      return useAssetListsStore().createNewAssetsList(assetsList);
    },
    updateAssetsList(payload: {
      assetsListId: string;
      assetsList: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema;
    }) {
      return useAssetListsStore().updateAssetsList(payload);
    },
    deleteAssetsList(assetsListId: string) {
      return useAssetListsStore().deleteAssetsList(assetsListId);
    },
    exportAssetsListsAsCSV() {
      return useAssetListsStore().exportAssetsListsAsCSV();
    },
    async importAssetsListsCSV(blob: Blob) {
      const res = await useAssetListsStore().importAssetsListsCSV(blob);
      await this.fetchAssets();
      return res;
    },
    fetchPortfolioAssetDetails(assetId: string) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const language = useNuxtApp().$activeLocale.value;
      return DefaultService.getB2BV1ClientInstrumentsInfo(
        assetId,
        organization.public_id,
        language,
        PORTFOLIO_PROPOSALS_ASSET_PRICE_HISTORY_RANGE,
      );
    },
    fetchAssetsAllocations() {
      this._assetsAllocationsInProgress = true;
      this.setPortfolioAssetsAllocations([]);

      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }

      return DefaultService.getB2BV1AssetAllocations(organization.public_id)
        .then((resp) => resp?.items)
        .then((allocations?: AssetAllocationResult[]) => {
          this.setPortfolioAssetsAllocations(allocations ?? []);
          return allocations;
        })
        .finally(() => {
          this._assetsAllocationsInProgress = false;
        });
    },
    fetchAssetsAllocationItems(assetsAllocationId: string) {
      return DefaultService.getB2BV1AssetAllocationsItems(assetsAllocationId);
    },
    createNewAssetsAllocation(
      assetsAllocation: Omit<njorda_bps_b2b_v1_schemas_asset_allocation_CreateAssetAllocationSchema, "organization">,
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const payload = {
        ...assetsAllocation,
        organization: organization.public_id,
      };

      return DefaultService.postB2BV1AssetAllocations(payload);
    },
    updateAssetsAllocation({
      assetsAllocationId,
      assetsAllocation,
    }: {
      assetsAllocationId: string;
      assetsAllocation: njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema;
    }) {
      return DefaultService.patchB2BV1AssetAllocations(assetsAllocationId, assetsAllocation);
    },
    upsertAssetsAllocationsItems({
      assetsAllocationId,
      assetsAllocationItems,
    }: {
      assetsAllocationId: string;
      assetsAllocationItems: njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems[];
    }) {
      return DefaultService.postB2BV1AssetAllocationsItems(assetsAllocationId, assetsAllocationItems);
    },
    deleteAssetsAllocation(assetsAllocationId: string) {
      return DefaultService.deleteB2BV1AssetAllocations(assetsAllocationId);
    },
    fetchAssetsAllocationCalculations(
      assetsAllocationItems: njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems[],
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const request = DefaultService.postB2BV1AssetAllocationsCalcs(organization.public_id, assetsAllocationItems);
      return {
        request,
      };
    },
  },
});
