<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.037 2.93945L21.0607 6.96309L9.13765 18.8861L3.37891 20.6212L5.11402 14.8625L17.037 2.93945ZM15.7272 6.37061L17.6295 8.27292L18.9393 6.96309L17.037 5.06077L15.7272 6.37061ZM16.5688 9.33358L14.6665 7.43127L6.44142 15.6564L5.62108 18.379L8.34373 17.5587L16.5688 9.33358Z"
      fill="currentColor"
    />
  </svg>
</template>
