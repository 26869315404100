<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 17.8754V6.12456H7.9091V3.5H16.0909V6.12456H21.9999L22 17.8754C22 19.3249 20.8061 20.5 19.3333 20.5H4.66667C3.19391 20.5 2 19.3249 2 17.8754ZM20.6665 7.43684L20.6666 12.0298H14.6665V10.7175H9.3332V12.0298H3.33334V7.43684H20.6665ZM14.6665 13.3421V14.6544H9.3332V13.3421H3.33334V17.8754C3.33334 18.6002 3.93029 19.1877 4.66667 19.1877H19.3333C20.0697 19.1877 20.6667 18.6002 20.6667 17.8754L20.6666 13.3421H14.6665ZM14.7576 4.81228V6.12456H9.24244V4.81228H14.7576ZM13.3332 13.3421H10.6665V12.0298H13.3332V13.3421Z"
      fill="currentColor"
    />
  </svg>
</template>
