import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_setup_vC9lhfwB3j from "/app/plugins/sentry-setup.ts";
import logger_jZS9jFTUsl from "/app/lib/plugins/logger.ts";
import console_logger_2HBMdLjGtF from "/app/lib/plugins/console-logger.ts";
import sentry_logger_D2GpQlTVaW from "/app/lib/plugins/sentry-logger.ts";
import loginRedirect_pF7hVopNHJ from "/app/plugins/loginRedirect.ts";
import toast_ysMjUcU7eJ from "/app/plugins/toast.ts";
import notify_oWSRP5vOkO from "/app/plugins/notify.ts";
import set_default_locale_KzA5NYGykv from "/app/plugins/set-default-locale.ts";
import appLocale_1nnYXFbMsi from "/app/plugins/appLocale.ts";
import njordaB2BAuth_vbF6MDh0TT from "/app/plugins/njordaB2BAuth.ts";
import njordaAdminApi_W8As9VRU6k from "/app/plugins/njordaAdminApi.ts";
import njordaEmbedB2BApi_ViqUWE3nQv from "/app/plugins/njordaEmbedB2BApi.ts";
import njordaB2BApi_xqdvqcp16O from "/app/plugins/njordaB2BApi.ts";
import organization_3aAgxG0XO7 from "/app/plugins/organization.ts";
import org_permissions_PK6vDy5u06 from "/app/plugins/org-permissions.ts";
import user_role_8FzO236D9w from "/app/plugins/user-role.ts";
import maska_UHaKf2z1iQ from "/app/plugins/maska.ts";
import iframe_YNJUJrnpDq from "/app/plugins/iframe.ts";
import events_T3yiFlA3dv from "/app/plugins/events.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  sentry_setup_vC9lhfwB3j,
  logger_jZS9jFTUsl,
  console_logger_2HBMdLjGtF,
  sentry_logger_D2GpQlTVaW,
  loginRedirect_pF7hVopNHJ,
  toast_ysMjUcU7eJ,
  notify_oWSRP5vOkO,
  set_default_locale_KzA5NYGykv,
  appLocale_1nnYXFbMsi,
  njordaB2BAuth_vbF6MDh0TT,
  njordaAdminApi_W8As9VRU6k,
  njordaEmbedB2BApi_ViqUWE3nQv,
  njordaB2BApi_xqdvqcp16O,
  organization_3aAgxG0XO7,
  org_permissions_PK6vDy5u06,
  user_role_8FzO236D9w,
  maska_UHaKf2z1iQ,
  iframe_YNJUJrnpDq,
  events_T3yiFlA3dv,
  vuetify_7h9QAQEssH
]