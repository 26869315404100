/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataProviderSchemaItems } from '../models/DataProviderSchemaItems';
import type { HoldingsReportSchemaItems } from '../models/HoldingsReportSchemaItems';
import type { marshmallow_schema_AccountDetailsSchemaItems } from '../models/marshmallow_schema_AccountDetailsSchemaItems';
import type { marshmallow_schema_AccountSchemaItems } from '../models/marshmallow_schema_AccountSchemaItems';
import type { marshmallow_schema_AssetAllocationResultSchemaItems } from '../models/marshmallow_schema_AssetAllocationResultSchemaItems';
import type { marshmallow_schema_AssetListMetaSchemaItems } from '../models/marshmallow_schema_AssetListMetaSchemaItems';
import type { marshmallow_schema_AssetListResultSchemaItems } from '../models/marshmallow_schema_AssetListResultSchemaItems';
import type { marshmallow_schema_AssetsListFilterSchemaItems } from '../models/marshmallow_schema_AssetsListFilterSchemaItems';
import type { marshmallow_schema_CalcParamSchemaItems } from '../models/marshmallow_schema_CalcParamSchemaItems';
import type { marshmallow_schema_ClientResultSchemaItems } from '../models/marshmallow_schema_ClientResultSchemaItems';
import type { marshmallow_schema_ColumnChoicesSchema } from '../models/marshmallow_schema_ColumnChoicesSchema';
import type { marshmallow_schema_DataProviderSchemaItems } from '../models/marshmallow_schema_DataProviderSchemaItems';
import type { marshmallow_schema_DataSessionConnectionSchemaItems } from '../models/marshmallow_schema_DataSessionConnectionSchemaItems';
import type { marshmallow_schema_EnumSchemaItems } from '../models/marshmallow_schema_EnumSchemaItems';
import type { marshmallow_schema_HoldingsReportSchemaItems } from '../models/marshmallow_schema_HoldingsReportSchemaItems';
import type { marshmallow_schema_InstrumentsSearchSchemaItems } from '../models/marshmallow_schema_InstrumentsSearchSchemaItems';
import type { marshmallow_schema_InstrumentTypesSchema } from '../models/marshmallow_schema_InstrumentTypesSchema';
import type { marshmallow_schema_ListReportSchemaItems } from '../models/marshmallow_schema_ListReportSchemaItems';
import type { marshmallow_schema_OrganizationSchemaItems } from '../models/marshmallow_schema_OrganizationSchemaItems';
import type { marshmallow_schema_PortfolioBenchmarkSchemaItems } from '../models/marshmallow_schema_PortfolioBenchmarkSchemaItems';
import type { marshmallow_schema_PortfolioProposalResultSchemaItems } from '../models/marshmallow_schema_PortfolioProposalResultSchemaItems';
import type { marshmallow_schema_PortfolioTemplateResultSchemaItems } from '../models/marshmallow_schema_PortfolioTemplateResultSchemaItems';
import type { marshmallow_schema_PositionDetailsSchemaItems } from '../models/marshmallow_schema_PositionDetailsSchemaItems';
import type { marshmallow_schema_SourceChoicesSchema } from '../models/marshmallow_schema_SourceChoicesSchema';
import type { marshmallow_schema_UserDetailsSchemaItems } from '../models/marshmallow_schema_UserDetailsSchemaItems';
import type { njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema } from '../models/njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema';
import type { njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema } from '../models/njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema';
import type { njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema } from '../models/njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema';
import type { njorda_bps_api_v2_schemas_calculate_portfolio_analytics_PortfolioAnalyticsResultSchema } from '../models/njorda_bps_api_v2_schemas_calculate_portfolio_analytics_PortfolioAnalyticsResultSchema';
import type { njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema } from '../models/njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema';
import type { njorda_bps_app_v1_schemas_settings_AppSettingsSchema } from '../models/njorda_bps_app_v1_schemas_settings_AppSettingsSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_client_ClientPasswordSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_client_ClientPasswordSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_client_ClientResultSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_client_ClientResultSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_client_ShareLinkTokenSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_client_ShareLinkTokenSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_integrations_OrgIntegrationsSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_integrations_OrgIntegrationsSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_settings_DeleteFlanksCredentialsResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_settings_DeleteFlanksCredentialsResponseSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema';
import type { njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema } from '../models/njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema';
import type { njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema } from '../models/njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema';
import type { njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema } from '../models/njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema';
import type { njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema } from '../models/njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema';
import type { njorda_bps_b2b_v1_schemas_analytics_UpdateCharts } from '../models/njorda_bps_b2b_v1_schemas_analytics_UpdateCharts';
import type { njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema } from '../models/njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema';
import type { njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema } from '../models/njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema';
import type { njorda_bps_b2b_v1_schemas_asset_allocation_CreateAssetAllocationSchema } from '../models/njorda_bps_b2b_v1_schemas_asset_allocation_CreateAssetAllocationSchema';
import type { njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema } from '../models/njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema';
import type { njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems } from '../models/njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems';
import type { njorda_bps_b2b_v1_schemas_auth_LoginSchema } from '../models/njorda_bps_b2b_v1_schemas_auth_LoginSchema';
import type { njorda_bps_b2b_v1_schemas_auth_UserSchema } from '../models/njorda_bps_b2b_v1_schemas_auth_UserSchema';
import type { njorda_bps_b2b_v1_schemas_calc_param_CreateCalcParamB2BSchema } from '../models/njorda_bps_b2b_v1_schemas_calc_param_CreateCalcParamB2BSchema';
import type { njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamResponseSchema';
import type { njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamSchema } from '../models/njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamSchema';
import type { njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema } from '../models/njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectCompleteSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectCompleteSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectDeleteSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectDeleteSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectInitResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectInitResponseSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectInitSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectInitSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectStartSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectStartSchema';
import type { njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema';
import type { njorda_bps_b2b_v1_schemas_connect_StatusMessageResultSchema } from '../models/njorda_bps_b2b_v1_schemas_connect_StatusMessageResultSchema';
import type { njorda_bps_b2b_v1_schemas_core_UrlResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_core_UrlResponseSchema';
import type { njorda_bps_b2b_v1_schemas_feedback_FeedbackSchema } from '../models/njorda_bps_b2b_v1_schemas_feedback_FeedbackSchema';
import type { njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema } from '../models/njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema';
import type { njorda_bps_b2b_v1_schemas_pensions_PensionMetaSchema } from '../models/njorda_bps_b2b_v1_schemas_pensions_PensionMetaSchema';
import type { njorda_bps_b2b_v1_schemas_pensions_PensionOutputSchema } from '../models/njorda_bps_b2b_v1_schemas_pensions_PensionOutputSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListFilterWarningSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListFilterWarningSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_AssetsListFilterSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_AssetsListFilterSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_ImportAssetListsFiltersSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_ImportAssetListsFiltersSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsResponseSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_BookPortfolioProposalResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_BookPortfolioProposalResponseSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_CreatePortfolioProposalSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_CreatePortfolioProposalSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsResponseSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsResponseSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalFullResultSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalFullResultSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_UpdatePortfolioProposalSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_UpdatePortfolioProposalSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema';
import type { njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema } from '../models/njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema';
import type { njorda_bps_b2b_v1_schemas_users_AccountSchema } from '../models/njorda_bps_b2b_v1_schemas_users_AccountSchema';
import type { njorda_bps_b2b_v1_schemas_users_AccountUpdateSchema } from '../models/njorda_bps_b2b_v1_schemas_users_AccountUpdateSchema';
import type { njorda_bps_b2b_v1_schemas_users_PositionSchema } from '../models/njorda_bps_b2b_v1_schemas_users_PositionSchema';
import type { njorda_bps_b2b_v1_schemas_users_PositionsCreateSchema } from '../models/njorda_bps_b2b_v1_schemas_users_PositionsCreateSchema';
import type { njorda_bps_b2b_v1_schemas_users_PositionUpdateSchema } from '../models/njorda_bps_b2b_v1_schemas_users_PositionUpdateSchema';
import type { njorda_libs_schemas_account_details_AccountDetailsSchema } from '../models/njorda_libs_schemas_account_details_AccountDetailsSchema';
import type { njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema } from '../models/njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema';
import type { njorda_libs_schemas_instrument_InstrumentInfoResponseSchema } from '../models/njorda_libs_schemas_instrument_InstrumentInfoResponseSchema';
import type { njorda_libs_schemas_instrument_InstrumentPriceSchema } from '../models/njorda_libs_schemas_instrument_InstrumentPriceSchema';
import type { njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema } from '../models/njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema';
import type { njorda_libs_schemas_portfolio_benchmark_PortfoliosBenchmarkResultSchema } from '../models/njorda_libs_schemas_portfolio_benchmark_PortfoliosBenchmarkResultSchema';
import type { njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema } from '../models/njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema';
import type { njorda_libs_schemas_positions_report_PositionsInputSchema } from '../models/njorda_libs_schemas_positions_report_PositionsInputSchema';
import type { njorda_libs_schemas_positions_report_PositionsSchema } from '../models/njorda_libs_schemas_positions_report_PositionsSchema';
import type { njorda_libs_schemas_recovery_ResetPasswordSchema } from '../models/njorda_libs_schemas_recovery_ResetPasswordSchema';
import type { njorda_libs_schemas_recovery_SendResetPasswordEmailSchema } from '../models/njorda_libs_schemas_recovery_SendResetPasswordEmailSchema';
import type { njorda_libs_schemas_risk_report_RiskReportSchema } from '../models/njorda_libs_schemas_risk_report_RiskReportSchema';
import type { njorda_libs_schemas_summary_report_AccountSummarySchema } from '../models/njorda_libs_schemas_summary_report_AccountSummarySchema';
import type { njorda_libs_schemas_summary_report_ClientSummarySchema } from '../models/njorda_libs_schemas_summary_report_ClientSummarySchema';
import type { njorda_libs_schemas_summary_report_PositionSummarySchema } from '../models/njorda_libs_schemas_summary_report_PositionSummarySchema';
import type { OrganizationSchemaItems } from '../models/OrganizationSchemaItems';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
    /**
     * /b2b/v1/advisor/accounts/<client_id>
     * @param clientId
     * @returns marshmallow_schema_AccountDetailsSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorAccounts(
        clientId: string,
    ): CancelablePromise<marshmallow_schema_AccountDetailsSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/accounts/{client_id}',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/accounts/<client_id>/<account_id>
     * @param accountId
     * @param clientId
     * @returns njorda_libs_schemas_account_details_AccountDetailsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorAccounts1(
        accountId: string,
        clientId: string,
    ): CancelablePromise<njorda_libs_schemas_account_details_AccountDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/accounts/{client_id}/{account_id}',
            path: {
                'account_id': accountId,
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/calculate/allocations/clients/<client_id>/accounts/<account_id>
     * @param accountId
     * @param clientId
     * @param acceptLanguage
     * @returns njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorCalculateAllocationsClientsAccounts(
        accountId: string,
        clientId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/calculate/allocations/clients/{client_id}/accounts/{account_id}',
            path: {
                'account_id': accountId,
                'client_id': clientId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/advisor/calculate/allocations/positions
     * @param acceptLanguage
     * @param requestBody
     * @returns njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorCalculateAllocationsPositions(
        acceptLanguage?: 'en' | 'sv',
        requestBody?: njorda_libs_schemas_positions_report_PositionsInputSchema,
    ): CancelablePromise<njorda_libs_schemas_allocation_report_WeightedAllocationReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/calculate/allocations/positions',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/clients
     * @param clientIds
     * @param organization
     * @throws ApiError
     */
    public static deleteB2BV1AdvisorClients(
        clientIds: string,
        organization: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/advisor/clients',
            query: {
                'client_ids': clientIds,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients
     * @param organization
     * @returns marshmallow_schema_ClientResultSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorClients(
        organization: string,
    ): CancelablePromise<marshmallow_schema_ClientResultSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ClientResultSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorClients(
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ClientResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/clients',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>
     * @param clientId
     * @throws ApiError
     */
    public static deleteB2BV1AdvisorClients1(
        clientId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/advisor/clients/{client_id}',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>
     * @param clientId
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorClients1(
        clientId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients/{client_id}',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>
     * @param clientId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema
     * @throws ApiError
     */
    public static patchB2BV1AdvisorClients(
        clientId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/advisor/clients/{client_id}',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>
     * @param clientId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema
     * @throws ApiError
     */
    public static putB2BV1AdvisorClients(
        clientId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ClientDetailsResultSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/advisor/clients/{client_id}',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/accounts/<account_id>/position/summary
     * @param accountId
     * @param clientId
     * @param endDate
     * @param startDate
     * @param ticker Ticker for the security. For cash positions use CASH.SEK.
     * @returns njorda_libs_schemas_summary_report_PositionSummarySchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorClientsAccountsPositionSummary(
        accountId: string,
        clientId: string,
        endDate: string,
        startDate: string,
        ticker: string,
    ): CancelablePromise<njorda_libs_schemas_summary_report_PositionSummarySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients/{client_id}/accounts/{account_id}/position/summary',
            path: {
                'account_id': accountId,
                'client_id': clientId,
            },
            query: {
                'end_date': endDate,
                'start_date': startDate,
                'ticker': ticker,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/accounts/<account_id>/summary
     * @param accountId
     * @param clientId
     * @param endDate
     * @param startDate
     * @param acceptLanguage
     * @returns njorda_libs_schemas_summary_report_AccountSummarySchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorClientsAccountsSummary(
        accountId: string,
        clientId: string,
        endDate: string,
        startDate: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_libs_schemas_summary_report_AccountSummarySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients/{client_id}/accounts/{account_id}/summary',
            path: {
                'account_id': accountId,
                'client_id': clientId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'end_date': endDate,
                'start_date': startDate,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/password
     * @param clientId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorClientsPassword(
        clientId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_client_ClientPasswordSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ClientSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/clients/{client_id}/password',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/risk
     * @param clientId
     * @param fixedCorrelation
     * @returns njorda_libs_schemas_risk_report_RiskReportSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorClientsRisk(
        clientId: string,
        fixedCorrelation?: number,
    ): CancelablePromise<njorda_libs_schemas_risk_report_RiskReportSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients/{client_id}/risk',
            path: {
                'client_id': clientId,
            },
            query: {
                'fixed_correlation': fixedCorrelation,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/summary
     * @param clientId
     * @param endDate
     * @param startDate
     * @param acceptLanguage
     * @param accountIds
     * @returns njorda_libs_schemas_summary_report_ClientSummarySchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorClientsSummary(
        clientId: string,
        endDate: string,
        startDate: string,
        acceptLanguage?: 'en' | 'sv',
        accountIds?: string,
    ): CancelablePromise<njorda_libs_schemas_summary_report_ClientSummarySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/clients/{client_id}/summary',
            path: {
                'client_id': clientId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'account_ids': accountIds,
                'end_date': endDate,
                'start_date': startDate,
            },
        });
    }
    /**
     * /b2b/v1/advisor/clients/<client_id>/token
     * @param clientId
     * @returns njorda_bps_b2b_v1_schemas_advisor_client_ShareLinkTokenSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorClientsToken(
        clientId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_client_ShareLinkTokenSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/clients/{client_id}/token',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/data-providers
     * @param all
     * @param organization
     * @returns marshmallow_schema_DataProviderSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorDataProviders(
        all?: boolean,
        organization?: string,
    ): CancelablePromise<marshmallow_schema_DataProviderSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/data-providers',
            query: {
                'all': all,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>
     * @param clientId
     * @returns marshmallow_schema_HoldingsReportSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorHoldingReports(
        clientId: string,
    ): CancelablePromise<marshmallow_schema_HoldingsReportSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/holding-reports/{client_id}',
            path: {
                'client_id': clientId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>
     * @param clientId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorHoldingReports(
        clientId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/holding-reports/{client_id}',
            path: {
                'client_id': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>/<report_id>
     * @param clientId
     * @param reportId
     * @throws ApiError
     */
    public static deleteB2BV1AdvisorHoldingReports(
        clientId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/advisor/holding-reports/{client_id}/{report_id}',
            path: {
                'client_id': clientId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>/<report_id>
     * @param clientId
     * @param reportId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorHoldingReports1(
        clientId: string,
        reportId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/holding-reports/{client_id}/{report_id}',
            path: {
                'client_id': clientId,
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>/<report_id>/pdf
     * @param clientId
     * @param reportId
     * @param taskId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorHoldingReportsPdf(
        clientId: string,
        reportId: string,
        taskId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/holding-reports/{client_id}/{report_id}/pdf',
            path: {
                'client_id': clientId,
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'task_id': taskId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>/<report_id>/pdf
     * @param clientId
     * @param reportId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorHoldingReportsPdf(
        clientId: string,
        reportId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/holding-reports/{client_id}/{report_id}/pdf',
            path: {
                'client_id': clientId,
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/advisor/holding-reports/<client_id>/<report_id>/pdf/result
     * @param clientId
     * @param reportId
     * @param taskId
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1AdvisorHoldingReportsPdfResult(
        clientId: string,
        reportId: string,
        taskId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/holding-reports/{client_id}/{report_id}/pdf/result',
            path: {
                'client_id': clientId,
                'report_id': reportId,
            },
            query: {
                'task_id': taskId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/integrations
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_integrations_OrgIntegrationsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorIntegrations(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_integrations_OrgIntegrationsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/integrations',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/organizations
     * @returns marshmallow_schema_OrganizationSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorOrganizations(): CancelablePromise<marshmallow_schema_OrganizationSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/organizations',
        });
    }
    /**
     * /b2b/v1/advisor/positions/<client_id>
     * @param clientId
     * @param accountId
     * @returns njorda_libs_schemas_positions_report_PositionsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorPositions(
        clientId: string,
        accountId?: string,
    ): CancelablePromise<njorda_libs_schemas_positions_report_PositionsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/positions/{client_id}',
            path: {
                'client_id': clientId,
            },
            query: {
                'account_id': accountId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorSettings(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/settings',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings
     * @param organization
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema
     * @throws ApiError
     */
    public static patchB2BV1AdvisorSettings(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/advisor/settings',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/settings
     * @param organization
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema
     * @throws ApiError
     */
    public static putB2BV1AdvisorSettings(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/advisor/settings',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorSettingsAdmin(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/settings/admin',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin
     * @param organization
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema
     * @throws ApiError
     */
    public static patchB2BV1AdvisorSettingsAdmin(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/advisor/settings/admin',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin
     * @param organization
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema
     * @throws ApiError
     */
    public static putB2BV1AdvisorSettingsAdmin(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_OrgAdminSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/advisor/settings/admin',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin/delete-flanks-credentials
     * @param client
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_DeleteFlanksCredentialsResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorSettingsAdminDeleteFlanksCredentials(
        client?: string,
        organization?: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_DeleteFlanksCredentialsResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/settings/admin/delete-flanks-credentials',
            query: {
                'client': client,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin/instruments
     * Ensure the Instruments used by Organization exists in the dataapp
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorSettingsAdminInstruments(
        organization?: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/settings/admin/instruments',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin/product-lists
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorSettingsAdminProductLists(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/settings/admin/product-lists',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/admin/product-lists/svp
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorSettingsAdminProductListsSvp(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_settings_JobStartedResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/settings/admin/product-lists/svp',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/app
     * @param organization
     * @returns njorda_bps_app_v1_schemas_settings_AppSettingsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorSettingsApp(
        organization: string,
    ): CancelablePromise<njorda_bps_app_v1_schemas_settings_AppSettingsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/settings/app',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/settings/app
     * @param organization
     * @param requestBody
     * @returns njorda_bps_app_v1_schemas_settings_AppSettingsSchema
     * @throws ApiError
     */
    public static patchB2BV1AdvisorSettingsApp(
        organization: string,
        requestBody?: njorda_bps_app_v1_schemas_settings_AppSettingsSchema,
    ): CancelablePromise<njorda_bps_app_v1_schemas_settings_AppSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/advisor/settings/app',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/settings/app
     * @param organization
     * @param requestBody
     * @returns njorda_bps_app_v1_schemas_settings_AppSettingsSchema
     * @throws ApiError
     */
    public static putB2BV1AdvisorSettingsApp(
        organization: string,
        requestBody?: njorda_bps_app_v1_schemas_settings_AppSettingsSchema,
    ): CancelablePromise<njorda_bps_app_v1_schemas_settings_AppSettingsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/advisor/settings/app',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/users
     * @param organization
     * @returns marshmallow_schema_UserDetailsSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AdvisorUsers(
        organization: string,
    ): CancelablePromise<marshmallow_schema_UserDetailsSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/users',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/advisor/users
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema
     * @throws ApiError
     */
    public static postB2BV1AdvisorUsers(
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_users_CreateUserSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/advisor/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/users/<user_id>
     * @param userId
     * @throws ApiError
     */
    public static deleteB2BV1AdvisorUsers(
        userId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/advisor/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/users/<user_id>
     * @param userId
     * @returns njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema
     * @throws ApiError
     */
    public static getB2BV1AdvisorUsers1(
        userId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/advisor/users/{user_id}',
            path: {
                'user_id': userId,
            },
        });
    }
    /**
     * /b2b/v1/advisor/users/<user_id>
     * @param userId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema
     * @throws ApiError
     */
    public static patchB2BV1AdvisorUsers(
        userId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/advisor/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/advisor/users/<user_id>
     * @param userId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema
     * @throws ApiError
     */
    public static putB2BV1AdvisorUsers(
        userId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_users_UserDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/advisor/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/column-choices
     * Column choices available for analytics reports.
     * @returns marshmallow_schema_ColumnChoicesSchema
     * @throws ApiError
     */
    public static getB2BV1AnalyticsColumnChoices(): CancelablePromise<marshmallow_schema_ColumnChoicesSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/column-choices',
        });
    }
    /**
     * /b2b/v1/analytics/grouping-choices
     * Grouping alternatives available for analytics reports.
     * @returns marshmallow_schema_EnumSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AnalyticsGroupingChoices(): CancelablePromise<marshmallow_schema_EnumSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/grouping-choices',
        });
    }
    /**
     * /b2b/v1/analytics/reports
     * List analytics reports.
     * @param organization
     * @returns marshmallow_schema_ListReportSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AnalyticsReports(
        organization: string,
    ): CancelablePromise<marshmallow_schema_ListReportSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/reports',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports
     * Save an analytics report.
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema
     * @throws ApiError
     */
    public static postB2BV1AnalyticsReports(
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_CreateReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/analytics/reports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/new-from-default
     * Get a default analytics report.
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static postB2BV1AnalyticsReportsNewFromDefault(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_CreateDefaultReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/analytics/reports/new-from-default',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>
     * Delete the unsaved analytics report.
     * @param tempReportId
     * @throws ApiError
     */
    public static deleteB2BV1AnalyticsReportsUnsaved(
        tempReportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}',
            path: {
                'temp_report_id': tempReportId,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>
     * Get the unsaved report.
     * @param tempReportId
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static getB2BV1AnalyticsReportsUnsaved(
        tempReportId: string,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}',
            path: {
                'temp_report_id': tempReportId,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>
     * @param tempReportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static patchB2BV1AnalyticsReportsUnsaved(
        tempReportId: string,
        organization: string,
        requestBody?: njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}',
            path: {
                'temp_report_id': tempReportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>
     * @param tempReportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static putB2BV1AnalyticsReportsUnsaved(
        tempReportId: string,
        organization: string,
        requestBody?: njorda_bps_api_v2_schemas_analytics_unsaved_reports_UpdateUnsavedReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}',
            path: {
                'temp_report_id': tempReportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>/charts
     * @param tempReportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static patchB2BV1AnalyticsReportsUnsavedCharts(
        tempReportId: string,
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_UpdateCharts,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}/charts',
            path: {
                'temp_report_id': tempReportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>/charts
     * @param tempReportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema
     * @throws ApiError
     */
    public static putB2BV1AnalyticsReportsUnsavedCharts(
        tempReportId: string,
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_UpdateCharts,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_unsaved_reports_UnsavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}/charts',
            path: {
                'temp_report_id': tempReportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/unsaved/<temp_report_id>/task
     * @param tempReportId
     * @param organization
     * @throws ApiError
     */
    public static deleteB2BV1AnalyticsReportsUnsavedTask(
        tempReportId: string,
        organization: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/analytics/reports/unsaved/{temp_report_id}/task',
            path: {
                'temp_report_id': tempReportId,
            },
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>
     * @param reportId
     * @throws ApiError
     */
    public static deleteB2BV1AnalyticsReports(
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/analytics/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>
     * Get an analytics report.
     * @param reportId
     * @returns njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema
     * @throws ApiError
     */
    public static getB2BV1AnalyticsReports1(
        reportId: string,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>
     * Update an analytics report.
     * @param reportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema
     * @throws ApiError
     */
    public static patchB2BV1AnalyticsReports(
        reportId: string,
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/analytics/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>
     * Update an analytics report.
     * @param reportId
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema
     * @throws ApiError
     */
    public static putB2BV1AnalyticsReports(
        reportId: string,
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_analytics_RenameReportSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_analytics_reports_SavedReportDetailsSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/analytics/reports/{report_id}',
            path: {
                'report_id': reportId,
            },
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>/export
     * @param reportId
     * @param fileType
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1AnalyticsReportsExport(
        reportId: string,
        fileType: 'csv' | 'xlsx',
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/analytics/reports/{report_id}/export',
            path: {
                'report_id': reportId,
            },
            query: {
                'file_type': fileType,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>/rows/export
     * @param reportId
     * @param fileType
     * @param rowIds
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1AnalyticsReportsRowsExport(
        reportId: string,
        fileType: 'csv' | 'xlsx',
        rowIds: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/analytics/reports/{report_id}/rows/export',
            path: {
                'report_id': reportId,
            },
            query: {
                'file_type': fileType,
                'row_ids': rowIds,
            },
        });
    }
    /**
     * /b2b/v1/analytics/reports/<report_id>/rows/<row_id>
     * Get a detailed view of report's row.
     * @param reportId
     * @param rowId
     * @param organization
     * @returns njorda_bps_api_v2_schemas_calculate_portfolio_analytics_PortfolioAnalyticsResultSchema
     * @throws ApiError
     */
    public static getB2BV1AnalyticsReportsRows(
        reportId: string,
        rowId: string,
        organization: string,
    ): CancelablePromise<njorda_bps_api_v2_schemas_calculate_portfolio_analytics_PortfolioAnalyticsResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/reports/{report_id}/rows/{row_id}',
            path: {
                'report_id': reportId,
                'row_id': rowId,
            },
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/analytics/source-choices
     * Source choices available for analytics reports.
     * @returns marshmallow_schema_SourceChoicesSchema
     * @throws ApiError
     */
    public static getB2BV1AnalyticsSourceChoices(): CancelablePromise<marshmallow_schema_SourceChoicesSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/analytics/source-choices',
        });
    }
    /**
     * /b2b/v1/asset-allocations
     * @param organization
     * @returns marshmallow_schema_AssetAllocationResultSchemaItems
     * @throws ApiError
     */
    public static getB2BV1AssetAllocations(
        organization: string,
    ): CancelablePromise<marshmallow_schema_AssetAllocationResultSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/asset-allocations',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/asset-allocations
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema
     * @throws ApiError
     */
    public static postB2BV1AssetAllocations(
        requestBody?: njorda_bps_b2b_v1_schemas_asset_allocation_CreateAssetAllocationSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/asset-allocations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/asset-allocations/calcs
     * @param organization
     * @param requestBody
     * @returns njorda_libs_schemas_risk_report_RiskReportSchema
     * @throws ApiError
     */
    public static postB2BV1AssetAllocationsCalcs(
        organization: string,
        requestBody?: Array<njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems>,
    ): CancelablePromise<njorda_libs_schemas_risk_report_RiskReportSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/asset-allocations/calcs',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>
     * @param assetAllocationId
     * @throws ApiError
     */
    public static deleteB2BV1AssetAllocations(
        assetAllocationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>
     * @param assetAllocationId
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema
     * @throws ApiError
     */
    public static getB2BV1AssetAllocations1(
        assetAllocationId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>
     * @param assetAllocationId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema
     * @throws ApiError
     */
    public static patchB2BV1AssetAllocations(
        assetAllocationId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>
     * @param assetAllocationId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema
     * @throws ApiError
     */
    public static putB2BV1AssetAllocations(
        assetAllocationId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_asset_allocation_UpdateAssetAllocationSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationResultSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>/items
     * @param assetAllocationId
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema
     * @throws ApiError
     */
    public static getB2BV1AssetAllocationsItems(
        assetAllocationId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}/items',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
        });
    }
    /**
     * /b2b/v1/asset-allocations/<asset_allocation_id>/items
     * @param assetAllocationId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema
     * @throws ApiError
     */
    public static postB2BV1AssetAllocationsItems(
        assetAllocationId: string,
        requestBody?: Array<njorda_bps_b2b_v1_schemas_asset_allocation_UpsertAssetAllocationItems>,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_asset_allocation_AssetAllocationFullResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/asset-allocations/{asset_allocation_id}/items',
            path: {
                'asset_allocation_id': assetAllocationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/auth/login
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_auth_LoginSchema
     * @throws ApiError
     */
    public static postB2BV1AuthLogin(
        requestBody?: njorda_bps_b2b_v1_schemas_auth_LoginSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_auth_LoginSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/auth/logout
     * @throws ApiError
     */
    public static postB2BV1AuthLogout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/auth/logout',
        });
    }
    /**
     * /b2b/v1/auth/user
     * @returns njorda_bps_b2b_v1_schemas_auth_UserSchema
     * @throws ApiError
     */
    public static getB2BV1AuthUser(): CancelablePromise<njorda_bps_b2b_v1_schemas_auth_UserSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/auth/user',
        });
    }
    /**
     * /b2b/v1/availability/market-regions
     * @throws ApiError
     */
    public static getB2BV1AvailabilityMarketRegions(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/availability/market-regions',
        });
    }
    /**
     * /b2b/v1/cache/clear
     * @throws ApiError
     */
    public static postB2BV1CacheClear(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/cache/clear',
        });
    }
    /**
     * /b2b/v1/cache/preload
     * @throws ApiError
     */
    public static postB2BV1CachePreload(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/cache/preload',
        });
    }
    /**
     * /b2b/v1/calc-params
     * @param organization
     * @returns marshmallow_schema_CalcParamSchemaItems
     * @throws ApiError
     */
    public static getB2BV1CalcParams(
        organization: string,
    ): CancelablePromise<marshmallow_schema_CalcParamSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/calc-params',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/calc-params
     * @param organization
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema
     * @throws ApiError
     */
    public static postB2BV1CalcParams(
        organization: string,
        requestBody?: njorda_bps_b2b_v1_schemas_calc_param_CreateCalcParamB2BSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/calc-params',
            query: {
                'organization': organization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/calc-params/export
     * @param fileType
     * @param organization
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1CalcParamsExport(
        fileType: 'xlsx' | 'csv',
        organization: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/calc-params/export',
            query: {
                'file_type': fileType,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/calc-params/import
     * Import the Calc Params
     * @param organization
     * @param formData
     * @returns njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamResponseSchema
     * @throws ApiError
     */
    public static postB2BV1CalcParamsImport(
        organization: string,
        formData?: njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_calc_param_ImportCalcParamResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/calc-params/import',
            query: {
                'organization': organization,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * /b2b/v1/calc-params/<calc_param_id>
     * @param calcParamId
     * @throws ApiError
     */
    public static deleteB2BV1CalcParams(
        calcParamId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/calc-params/{calc_param_id}',
            path: {
                'calc_param_id': calcParamId,
            },
        });
    }
    /**
     * /b2b/v1/calc-params/<calc_param_id>
     * @param calcParamId
     * @returns njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema
     * @throws ApiError
     */
    public static getB2BV1CalcParams1(
        calcParamId: string,
    ): CancelablePromise<njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/calc-params/{calc_param_id}',
            path: {
                'calc_param_id': calcParamId,
            },
        });
    }
    /**
     * /b2b/v1/calc-params/<calc_param_id>
     * @param calcParamId
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema
     * @throws ApiError
     */
    public static patchB2BV1CalcParams(
        calcParamId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/calc-params/{calc_param_id}',
            path: {
                'calc_param_id': calcParamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/calc-params/<calc_param_id>
     * @param calcParamId
     * @param requestBody
     * @returns njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema
     * @throws ApiError
     */
    public static putB2BV1CalcParams(
        calcParamId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_calc_param_UpdateCalcParamB2BSchema,
    ): CancelablePromise<njorda_bps_api_v2_schemas_data_calc_params_CalcParamSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/calc-params/{calc_param_id}',
            path: {
                'calc_param_id': calcParamId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/accounts/<report_id>
     * @param reportId
     * @returns marshmallow_schema_AccountSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientAccounts(
        reportId: string,
    ): CancelablePromise<marshmallow_schema_AccountSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/accounts/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/accounts/<report_id>
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_AccountSchema
     * @throws ApiError
     */
    public static postB2BV1ClientAccounts(
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_AccountSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_AccountSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/accounts/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/accounts/<report_id>/<account_id>
     * @param accountId
     * @param reportId
     * @throws ApiError
     */
    public static deleteB2BV1ClientAccounts(
        accountId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/client/accounts/{report_id}/{account_id}',
            path: {
                'account_id': accountId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/accounts/<report_id>/<account_id>
     * @param accountId
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_AccountSchema
     * @throws ApiError
     */
    public static patchB2BV1ClientAccounts(
        accountId: string,
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_AccountUpdateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_AccountSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/client/accounts/{report_id}/{account_id}',
            path: {
                'account_id': accountId,
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/accounts/<report_id>/<account_id>
     * @param accountId
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_AccountSchema
     * @throws ApiError
     */
    public static putB2BV1ClientAccounts(
        accountId: string,
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_AccountUpdateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_AccountSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/client/accounts/{report_id}/{account_id}',
            path: {
                'account_id': accountId,
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/complete
     * @param requestBody
     * @throws ApiError
     */
    public static postB2BV1ClientConnectComplete(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectCompleteSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/connect/complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/data-providers
     * @param organization
     * @param isTest
     * @returns DataProviderSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientConnectDataProviders(
        organization: string,
        isTest?: boolean,
    ): CancelablePromise<DataProviderSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/connect/data-providers',
            query: {
                'is_test': isTest,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/client/connect/delete
     * @param requestBody
     * @throws ApiError
     */
    public static postB2BV1ClientConnectDelete(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectDeleteSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/connect/delete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/init
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_connect_ConnectInitResponseSchema
     * @throws ApiError
     */
    public static postB2BV1ClientConnectInit(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectInitSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_connect_ConnectInitResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/connect/init',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/start
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_core_UrlResponseSchema
     * @throws ApiError
     */
    public static postB2BV1ClientConnectStart(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectStartSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_core_UrlResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/connect/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/status-message
     * @param organization
     * @returns njorda_bps_b2b_v1_schemas_connect_StatusMessageResultSchema
     * @throws ApiError
     */
    public static getB2BV1ClientConnectStatusMessage(
        organization: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_connect_StatusMessageResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/connect/status-message',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/client/connect/update
     * @param requestBody
     * @throws ApiError
     */
    public static patchB2BV1ClientConnectUpdate(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/client/connect/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connect/update
     * @param requestBody
     * @throws ApiError
     */
    public static putB2BV1ClientConnectUpdate(
        requestBody?: njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/client/connect/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/connections/<report_id>
     * @param reportId
     * @returns marshmallow_schema_DataSessionConnectionSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientConnections(
        reportId: string,
    ): CancelablePromise<marshmallow_schema_DataSessionConnectionSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/connections/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/connections/<report_id>/<institution_id>
     * @param institutionId
     * @param reportId
     * @throws ApiError
     */
    public static deleteB2BV1ClientConnections(
        institutionId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/client/connections/{report_id}/{institution_id}',
            path: {
                'institution_id': institutionId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>
     * @param reportId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema
     * @throws ApiError
     */
    public static getB2BV1ClientHoldingsReport(
        reportId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/holdings-report/{report_id}',
            path: {
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>/dismiss-insight/<insight_id>
     * @param insightId
     * @param reportId
     * @throws ApiError
     */
    public static patchB2BV1ClientHoldingsReportDismissInsight(
        insightId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/client/holdings-report/{report_id}/dismiss-insight/{insight_id}',
            path: {
                'insight_id': insightId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>/dismiss-insight/<insight_id>
     * @param insightId
     * @param reportId
     * @throws ApiError
     */
    public static putB2BV1ClientHoldingsReportDismissInsight(
        insightId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/client/holdings-report/{report_id}/dismiss-insight/{insight_id}',
            path: {
                'insight_id': insightId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>/pdf
     * @param reportId
     * @param taskId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema
     * @throws ApiError
     */
    public static getB2BV1ClientHoldingsReportPdf(
        reportId: string,
        taskId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/holdings-report/{report_id}/pdf',
            path: {
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'task_id': taskId,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>/pdf
     * @param reportId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema
     * @throws ApiError
     */
    public static postB2BV1ClientHoldingsReportPdf(
        reportId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_HoldingsReportExportResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/holdings-report/{report_id}/pdf',
            path: {
                'report_id': reportId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-report/<report_id>/pdf/result
     * @param reportId
     * @param taskId
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1ClientHoldingsReportPdfResult(
        reportId: string,
        taskId: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/holdings-report/{report_id}/pdf/result',
            path: {
                'report_id': reportId,
            },
            query: {
                'task_id': taskId,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-reports
     * @param clientToken
     * @returns HoldingsReportSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientHoldingsReports(
        clientToken: string,
    ): CancelablePromise<HoldingsReportSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/holdings-reports',
            query: {
                'client_token': clientToken,
            },
        });
    }
    /**
     * /b2b/v1/client/holdings-reports
     * @param clientToken
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema
     * @throws ApiError
     */
    public static postB2BV1ClientHoldingsReports(
        clientToken: string,
        requestBody?: njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_advisor_holding_reports_CreateHoldingsReportResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/holdings-reports',
            query: {
                'client_token': clientToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/instruments/search
     * @param q
     * @param types
     * @returns marshmallow_schema_InstrumentsSearchSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientInstrumentsSearch(
        q: string,
        types?: string,
    ): CancelablePromise<marshmallow_schema_InstrumentsSearchSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/instruments/search',
            query: {
                'q': q,
                'types': types,
            },
        });
    }
    /**
     * /b2b/v1/client/instruments/<ticker>/info
     * @param ticker Ticker for the security. For cash positions use CASH.SEK.
     * @param organization
     * @param acceptLanguage
     * @param daysBack
     * @returns njorda_libs_schemas_instrument_InstrumentInfoResponseSchema
     * @throws ApiError
     */
    public static getB2BV1ClientInstrumentsInfo(
        ticker: string,
        organization: string,
        acceptLanguage?: 'en' | 'sv',
        daysBack?: number,
    ): CancelablePromise<njorda_libs_schemas_instrument_InstrumentInfoResponseSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/instruments/{ticker}/info',
            path: {
                'ticker': ticker,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'days_back': daysBack,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/client/instruments/<ticker>/latest-price
     * @param ticker
     * @returns njorda_libs_schemas_instrument_InstrumentPriceSchema
     * @throws ApiError
     */
    public static getB2BV1ClientInstrumentsLatestPrice(
        ticker: string,
    ): CancelablePromise<njorda_libs_schemas_instrument_InstrumentPriceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/instruments/{ticker}/latest-price',
            path: {
                'ticker': ticker,
            },
        });
    }
    /**
     * /b2b/v1/client/positions/<report_id>
     * @param reportId
     * @returns marshmallow_schema_PositionDetailsSchemaItems
     * @throws ApiError
     */
    public static getB2BV1ClientPositions(
        reportId: string,
    ): CancelablePromise<marshmallow_schema_PositionDetailsSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/client/positions/{report_id}',
            path: {
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/positions/<report_id>
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_PositionSchema
     * @throws ApiError
     */
    public static postB2BV1ClientPositions(
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_PositionsCreateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_PositionSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/client/positions/{report_id}',
            path: {
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/positions/<report_id>/<position_id>
     * @param positionId
     * @param reportId
     * @throws ApiError
     */
    public static deleteB2BV1ClientPositions(
        positionId: string,
        reportId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/client/positions/{report_id}/{position_id}',
            path: {
                'position_id': positionId,
                'report_id': reportId,
            },
        });
    }
    /**
     * /b2b/v1/client/positions/<report_id>/<position_id>
     * @param positionId
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_PositionSchema
     * @throws ApiError
     */
    public static patchB2BV1ClientPositions(
        positionId: string,
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_PositionUpdateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_PositionSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/client/positions/{report_id}/{position_id}',
            path: {
                'position_id': positionId,
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/client/positions/<report_id>/<position_id>
     * @param positionId
     * @param reportId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_users_PositionSchema
     * @throws ApiError
     */
    public static putB2BV1ClientPositions(
        positionId: string,
        reportId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_users_PositionUpdateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_users_PositionSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/client/positions/{report_id}/{position_id}',
            path: {
                'position_id': positionId,
                'report_id': reportId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/demo/organizations
     * @returns OrganizationSchemaItems
     * @throws ApiError
     */
    public static getB2BV1DemoOrganizations(): CancelablePromise<OrganizationSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/demo/organizations',
        });
    }
    /**
     * /b2b/v1/docs
     * @throws ApiError
     */
    public static getB2BV1Docs(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/docs',
        });
    }
    /**
     * /b2b/v1/feedback
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_feedback_FeedbackSchema
     * @throws ApiError
     */
    public static postB2BV1Feedback(
        requestBody?: njorda_bps_b2b_v1_schemas_feedback_FeedbackSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_feedback_FeedbackSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/instrument-types
     * @returns marshmallow_schema_InstrumentTypesSchema
     * @throws ApiError
     */
    public static getB2BV1InstrumentTypes(): CancelablePromise<marshmallow_schema_InstrumentTypesSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/instrument-types',
        });
    }
    /**
     * /b2b/v1/pensions/calculate
     *
     * Returns a list of allowed values per attribute for calc input.
     *
     * @returns njorda_bps_b2b_v1_schemas_pensions_PensionMetaSchema
     * @throws ApiError
     */
    public static getB2BV1PensionsCalculate(): CancelablePromise<Array<njorda_bps_b2b_v1_schemas_pensions_PensionMetaSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/pensions/calculate',
        });
    }
    /**
     * /b2b/v1/pensions/calculate
     *
     * Performs calculations.
     *
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_pensions_PensionOutputSchema
     * @throws ApiError
     */
    public static postB2BV1PensionsCalculate(
        requestBody?: njorda_bps_b2b_v1_schemas_pensions_PensionInputSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_pensions_PensionOutputSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/pensions/calculate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios
     * @param organization
     * @returns marshmallow_schema_PortfolioBenchmarkSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderBenchmarkPortfolios(
        organization: string,
    ): CancelablePromise<marshmallow_schema_PortfolioBenchmarkSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios
     * @param requestBody
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderBenchmarkPortfolios(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_benchmark_CreatePortfolioBenchmarkSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios/<portfolio_benchmark_id>
     * @param portfolioBenchmarkId
     * @throws ApiError
     */
    public static deleteB2BV1PortfolioBuilderBenchmarkPortfolios(
        portfolioBenchmarkId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios/{portfolio_benchmark_id}',
            path: {
                'portfolio_benchmark_id': portfolioBenchmarkId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios/<portfolio_benchmark_id>
     * @param portfolioBenchmarkId
     * @returns njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderBenchmarkPortfolios1(
        portfolioBenchmarkId: string,
    ): CancelablePromise<njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios/{portfolio_benchmark_id}',
            path: {
                'portfolio_benchmark_id': portfolioBenchmarkId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios/<portfolio_benchmark_id>
     * @param portfolioBenchmarkId
     * @param requestBody
     * @returns njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema
     * @throws ApiError
     */
    public static patchB2BV1PortfolioBuilderBenchmarkPortfolios(
        portfolioBenchmarkId: string,
        requestBody?: njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema,
    ): CancelablePromise<njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios/{portfolio_benchmark_id}',
            path: {
                'portfolio_benchmark_id': portfolioBenchmarkId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/benchmark-portfolios/<portfolio_benchmark_id>
     * @param portfolioBenchmarkId
     * @param requestBody
     * @returns njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema
     * @throws ApiError
     */
    public static putB2BV1PortfolioBuilderBenchmarkPortfolios(
        portfolioBenchmarkId: string,
        requestBody?: njorda_libs_schemas_portfolio_benchmark_UpdatePortfolioBenchmarkSchema,
    ): CancelablePromise<njorda_libs_schemas_portfolio_benchmark_PortfolioBenchmarkSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/portfolio-builder/benchmark-portfolios/{portfolio_benchmark_id}',
            path: {
                'portfolio_benchmark_id': portfolioBenchmarkId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/book/proposals/<proposal_id>
     * @param proposalId
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_BookPortfolioProposalResponseSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderBookProposals(
        proposalId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_BookPortfolioProposalResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/book/proposals/{proposal_id}',
            path: {
                'proposal_id': proposalId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/calculate/benchmark
     * @param organization
     * @param portfolioBenchmarkIds
     * @returns njorda_libs_schemas_portfolio_benchmark_PortfoliosBenchmarkResultSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderCalculateBenchmark(
        organization: string,
        portfolioBenchmarkIds: string,
    ): CancelablePromise<njorda_libs_schemas_portfolio_benchmark_PortfoliosBenchmarkResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/calculate/benchmark',
            query: {
                'organization': organization,
                'portfolio_benchmark_ids': portfolioBenchmarkIds,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/calculate/mean-optimal-proposal
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderCalculateMeanOptimalProposal(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/calculate/mean-optimal-proposal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/calculate/optimal-proposal
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderCalculateOptimalProposal(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_OptimalPortfolioProposalCalcsResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/calculate/optimal-proposal',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/calculate/proposals
     * @param acceptLanguage
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsResponseSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderCalculateProposals(
        acceptLanguage?: 'en' | 'sv',
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalCalcsResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/calculate/proposals',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters
     * @param organization
     * @param assetListId
     * @returns marshmallow_schema_AssetsListFilterSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetListFilters(
        organization: string,
        assetListId?: string,
    ): CancelablePromise<marshmallow_schema_AssetsListFilterSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters',
            query: {
                'asset_list_id': assetListId,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/batch
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema
     * @throws ApiError
     */
    public static patchB2BV1PortfolioBuilderDataAssetListFiltersBatch(
        requestBody?: Array<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema>,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/batch
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema
     * @throws ApiError
     */
    public static putB2BV1PortfolioBuilderDataAssetListFiltersBatch(
        requestBody?: Array<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema>,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_BatchOutputAssetListFilterSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/export
     * @param fileType
     * @param organization
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataAssetListFiltersExport(
        fileType: 'xlsx' | 'csv',
        organization: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/export',
            query: {
                'file_type': fileType,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/import
     * Import the Asset Lists
     * @param organization
     * @param formData
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataAssetListFiltersImport(
        organization: string,
        formData?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_ImportAssetListsFiltersSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/import',
            query: {
                'organization': organization,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/schema
     * @returns marshmallow_schema_AssetListMetaSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetListFiltersSchema(): CancelablePromise<marshmallow_schema_AssetListMetaSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/schema',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/<asset_list_filter_id>
     * @param assetListFilterId
     * @throws ApiError
     */
    public static deleteB2BV1PortfolioBuilderDataAssetListFilters(
        assetListFilterId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/{asset_list_filter_id}',
            path: {
                'asset_list_filter_id': assetListFilterId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-list-filters/<asset_list_filter_id>
     * @param assetListFilterId
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_AssetsListFilterSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetListFilters1(
        assetListFilterId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_AssetsListFilterSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-list-filters/{asset_list_filter_id}',
            path: {
                'asset_list_filter_id': assetListFilterId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists
     * @param organization
     * @param includeArchived
     * @param type
     * @returns marshmallow_schema_AssetListResultSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetLists(
        organization: string,
        includeArchived: boolean = false,
        type?: 'portfolio_builder' | 'asset_class',
    ): CancelablePromise<marshmallow_schema_AssetListResultSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-lists',
            query: {
                'include_archived': includeArchived,
                'organization': organization,
                'type': type,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataAssetLists(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/asset-lists',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists/<asset_list_id>
     * @param assetListId
     * @throws ApiError
     */
    public static deleteB2BV1PortfolioBuilderDataAssetLists(
        assetListId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/portfolio-builder/data/asset-lists/{asset_list_id}',
            path: {
                'asset_list_id': assetListId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists/<asset_list_id>
     * @param assetListId
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetLists1(
        assetListId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-lists/{asset_list_id}',
            path: {
                'asset_list_id': assetListId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists/<asset_list_id>
     * @param assetListId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema
     * @throws ApiError
     */
    public static patchB2BV1PortfolioBuilderDataAssetLists(
        assetListId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/portfolio-builder/data/asset-lists/{asset_list_id}',
            path: {
                'asset_list_id': assetListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists/<asset_list_id>
     * @param assetListId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema
     * @throws ApiError
     */
    public static putB2BV1PortfolioBuilderDataAssetLists(
        assetListId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListResultSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/portfolio-builder/data/asset-lists/{asset_list_id}',
            path: {
                'asset_list_id': assetListId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/asset-lists/<asset_list_id>/validate-asset-list-filters
     * @param assetListId
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListFilterWarningSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataAssetListsValidateAssetListFilters(
        assetListId: string,
    ): CancelablePromise<Array<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_AssetListFilterWarningSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/asset-lists/{asset_list_id}/validate-asset-list-filters',
            path: {
                'asset_list_id': assetListId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/product-lists/export
     * @param fileType
     * @param organization
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataProductListsExport(
        fileType: 'xlsx' | 'csv',
        organization: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/product-lists/export',
            query: {
                'file_type': fileType,
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/product-lists/import
     * Import the Product Lists
     * @param organization
     * @param formData
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsResponseSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataProductListsImport(
        organization: string,
        formData?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_ImportProductListsResponseSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/product-lists/import',
            query: {
                'organization': organization,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals
     * @param organization
     * @returns marshmallow_schema_PortfolioProposalResultSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataProposals(
        organization: string,
    ): CancelablePromise<marshmallow_schema_PortfolioProposalResultSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/proposals',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataProposals(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_CreatePortfolioProposalSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/proposals',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals/export
     * Returns the XLSX or PDF file in the response body
     * @param fileType
     * @param proposalIds
     * @param acceptLanguage
     * @returns binary
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataProposalsExport(
        fileType: 'pdf' | 'xlsx',
        proposalIds: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/proposals/export',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'file_type': fileType,
                'proposal_ids': proposalIds,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals/<proposal_id>
     * @param proposalId
     * @throws ApiError
     */
    public static deleteB2BV1PortfolioBuilderDataProposals(
        proposalId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/portfolio-builder/data/proposals/{proposal_id}',
            path: {
                'proposal_id': proposalId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals/<proposal_id>
     * @param proposalId
     * @param acceptLanguage
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalFullResultSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataProposals1(
        proposalId: string,
        acceptLanguage?: 'en' | 'sv',
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalFullResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/proposals/{proposal_id}',
            path: {
                'proposal_id': proposalId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals/<proposal_id>
     * @param proposalId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema
     * @throws ApiError
     */
    public static patchB2BV1PortfolioBuilderDataProposals(
        proposalId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_UpdatePortfolioProposalSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/portfolio-builder/data/proposals/{proposal_id}',
            path: {
                'proposal_id': proposalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/proposals/<proposal_id>
     * @param proposalId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema
     * @throws ApiError
     */
    public static putB2BV1PortfolioBuilderDataProposals(
        proposalId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_UpdatePortfolioProposalSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_proposal_PortfolioProposalResultSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/portfolio-builder/data/proposals/{proposal_id}',
            path: {
                'proposal_id': proposalId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates
     * @param organization
     * @returns marshmallow_schema_PortfolioTemplateResultSchemaItems
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataTemplates(
        organization: string,
    ): CancelablePromise<marshmallow_schema_PortfolioTemplateResultSchemaItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/templates',
            query: {
                'organization': organization,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema
     * @throws ApiError
     */
    public static postB2BV1PortfolioBuilderDataTemplates(
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_CreatePortfolioTemplateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/portfolio-builder/data/templates',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates/<template_id>
     * @param templateId
     * @throws ApiError
     */
    public static deleteB2BV1PortfolioBuilderDataTemplates(
        templateId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/b2b/v1/portfolio-builder/data/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates/<template_id>
     * @param templateId
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema
     * @throws ApiError
     */
    public static getB2BV1PortfolioBuilderDataTemplates1(
        templateId: string,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/portfolio-builder/data/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates/<template_id>
     * @param templateId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema
     * @throws ApiError
     */
    public static patchB2BV1PortfolioBuilderDataTemplates(
        templateId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/b2b/v1/portfolio-builder/data/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/portfolio-builder/data/templates/<template_id>
     * @param templateId
     * @param requestBody
     * @returns njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema
     * @throws ApiError
     */
    public static putB2BV1PortfolioBuilderDataTemplates(
        templateId: string,
        requestBody?: njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_UpdatePortfolioTemplateSchema,
    ): CancelablePromise<njorda_bps_b2b_v1_schemas_portfolio_builder_portfolio_template_PortfolioTemplateResultSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/b2b/v1/portfolio-builder/data/templates/{template_id}',
            path: {
                'template_id': templateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/recovery/reset-password/<code>
     * @param code
     * @returns njorda_libs_schemas_recovery_ResetPasswordSchema
     * @throws ApiError
     */
    public static getB2BV1RecoveryResetPassword(
        code: string,
    ): CancelablePromise<njorda_libs_schemas_recovery_ResetPasswordSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/b2b/v1/recovery/reset-password/{code}',
            path: {
                'code': code,
            },
        });
    }
    /**
     * /b2b/v1/recovery/reset-password/<code>
     * @param code
     * @param requestBody
     * @throws ApiError
     */
    public static postB2BV1RecoveryResetPassword(
        code: string,
        requestBody?: njorda_libs_schemas_recovery_ResetPasswordSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/recovery/reset-password/{code}',
            path: {
                'code': code,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * /b2b/v1/recovery/send-reset-password-email
     * @param requestBody
     * @throws ApiError
     */
    public static postB2BV1RecoverySendResetPasswordEmail(
        requestBody?: njorda_libs_schemas_recovery_SendResetPasswordEmailSchema,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/b2b/v1/recovery/send-reset-password-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
