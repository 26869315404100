import type { Account2, Institution, PositionDetails, DataSessionConnection } from "@/api/njorda";

export type TUserPortfolioAccount = Account2 & {
  is_manual_account?: boolean;
};
export type TUserPortfolioPosition = PositionDetails;

export type TUserPortfolioAccountWithPositions = TUserPortfolioAccount & {
  positions?: TUserPortfolioPosition[];
};

export type TDataSessionConnectionWithStatuses = DataSessionConnection & {
  isLoading: boolean;
  isDone: boolean;
  isFailed: boolean;
  isInUnknownStatus: boolean;
};

export type TUserPortfolioAccountInstitution = Institution & {
  accounts: TUserPortfolioAccountWithPositions[];
  identifier?: string;
  id?: string;
  isManualAccount?: boolean;
  connection?: TDataSessionConnectionWithStatuses;
};

export const DATA_SESSION_CONNECTION_STATUS = {
  AUTH_WAITING: "auth_waiting",
  AUTH_STARTED: "auth_started",
  AUTH_ERROR: "auth_error",
  AUTH_SUCCESS: "auth_success",
  DATA_COLLECTING: "data_collecting",
  DATA_COLLECTING_ERROR: "data_collecting_error",
  DATA_COLLECTING_SUCCESS: "data_collecting_success",
  DATA_PROCESSING: "data_processing",
  DATA_PROCESSING_ERROR: "data_processing_error",
  DATA_PROCESSING_SUCCESS: "data_processing_success",
};

export const SUCCESS_CONNECTION_STATUSES = [DATA_SESSION_CONNECTION_STATUS.DATA_PROCESSING_SUCCESS];
export const FAILED_CONNECTION_STATUES = [
  DATA_SESSION_CONNECTION_STATUS.AUTH_ERROR,
  DATA_SESSION_CONNECTION_STATUS.DATA_COLLECTING_ERROR,
  DATA_SESSION_CONNECTION_STATUS.DATA_PROCESSING_ERROR,
];
export const LOADING_CONNECTION_STATUSES = [
  DATA_SESSION_CONNECTION_STATUS.AUTH_WAITING,
  DATA_SESSION_CONNECTION_STATUS.AUTH_STARTED,
  DATA_SESSION_CONNECTION_STATUS.AUTH_SUCCESS,
  DATA_SESSION_CONNECTION_STATUS.DATA_COLLECTING,
  DATA_SESSION_CONNECTION_STATUS.DATA_COLLECTING_SUCCESS,
  DATA_SESSION_CONNECTION_STATUS.DATA_PROCESSING,
];
