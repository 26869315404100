import { ASSET_LIST_TYPES } from "@/constants/advisor";
import { defineStore } from "pinia";
import type {
  AssetListResult,
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema,
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema,
} from "~/api/njorda";
import { useAssetListsStore } from "./asset-lists";

type TPortfolioAssetsStore = {
  _assetClasses: AssetListResult[] | null;
  _assetClassesInProgress: boolean;
};

export type TAssetClassToUpdate = njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema;
export type TAssetClassToCreate = Omit<
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema,
  "organization"
>;

export const useAssetClassesStore = defineStore("asset-classes-store", {
  state: () =>
    ({
      _assetClasses: null,
      _assetClassesInProgress: false,
    }) as TPortfolioAssetsStore,
  getters: {
    assetsClassInProgress(state: TPortfolioAssetsStore): boolean {
      return state._assetClassesInProgress;
    },
    assetsClasses(state: TPortfolioAssetsStore): AssetListResult[] | null {
      return state._assetClasses;
    },
    assetClassesMap(): Record<string, AssetListResult> {
      const map: Record<string, AssetListResult> = {};
      (this.assetsClasses ?? []).forEach((assetsClass: AssetListResult) => {
        if (!assetsClass.id) {
          return;
        }
        map[assetsClass.id] = assetsClass;
      });
      return map;
    },
  },
  actions: {
    // Mutators:
    setAssetClasses(assets: AssetListResult[]) {
      this._assetClasses = assets;
    },

    // API Actions
    async fetchAssetClasses(withArchived = false, storeInCurrentStore = true) {
      this._assetClassesInProgress = true;
      const assetClasses = await useAssetListsStore()
        .getAssets(ASSET_LIST_TYPES.ASSET_CLASS, withArchived)
        .then((items) => {
          return items ?? null;
        })
        .catch(() => null);

      if (storeInCurrentStore) {
        this.setAssetClasses(
          (assetClasses ?? []).map((assetClass) => ({
            ...assetClass,
            slug: `${assetClass?.name?.toLowerCase().split(" ").join("-")}-${assetClass.id}`,
          })),
        );
      }

      this._assetClassesInProgress = false;

      return assetClasses;
    },
    createNewAssetsList(
      assetsList: Omit<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema, "organization">,
    ) {
      return useAssetListsStore().createNewAssetsList(assetsList);
    },
    updateAssetsList(payload: {
      assetsListId: string;
      assetsList: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema;
    }) {
      return useAssetListsStore().updateAssetsList(payload);
    },
    deleteAssetsList(assetsListId: string) {
      return useAssetListsStore().deleteAssetsList(assetsListId);
    },
  },
});
