<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C16.4184 4 20 7.5816 20 12C20.0028 13.8463 19.3643 15.6363 18.1936 17.064L18.2096 17.0816L18.104 17.1712C17.3538 18.0586 16.4189 18.7715 15.3646 19.2601C14.3103 19.7487 13.162 20.0012 12 20C9.64001 20 7.52001 18.9784 6.05601 17.3544L5.89601 17.1704L5.79041 17.0824L5.80641 17.0632C4.63581 15.6357 3.99731 13.8461 4.00001 12C4.00001 7.5816 7.58161 4 12 4ZM12 16C10.512 16 9.16721 16.4736 8.16561 17.1248C9.27154 17.9546 10.6174 18.4022 12 18.4C13.3826 18.4022 14.7285 17.9546 15.8344 17.1248C14.6899 16.3914 13.3593 16.0011 12 16ZM12 5.6C10.7956 5.59997 9.61568 5.93977 8.59578 6.58036C7.57589 7.22095 6.75745 8.13632 6.23454 9.22126C5.71162 10.3062 5.50545 11.5167 5.63973 12.7135C5.77401 13.9104 6.24328 15.0451 6.99361 15.9872C8.29041 15.0568 10.06 14.4 12 14.4C13.94 14.4 15.7096 15.0568 17.0064 15.9872C17.7567 15.0451 18.226 13.9104 18.3603 12.7135C18.4946 11.5167 18.2884 10.3062 17.7655 9.22126C17.2426 8.13632 16.4241 7.22095 15.4042 6.58036C14.3843 5.93977 13.2044 5.59997 12 5.6ZM12 7.2C12.8487 7.2 13.6626 7.53714 14.2627 8.13726C14.8629 8.73737 15.2 9.55131 15.2 10.4C15.2 11.2487 14.8629 12.0626 14.2627 12.6627C13.6626 13.2629 12.8487 13.6 12 13.6C11.1513 13.6 10.3374 13.2629 9.73727 12.6627C9.13715 12.0626 8.80001 11.2487 8.80001 10.4C8.80001 9.55131 9.13715 8.73737 9.73727 8.13726C10.3374 7.53714 11.1513 7.2 12 7.2ZM12 8.8C11.5757 8.8 11.1687 8.96857 10.8686 9.26863C10.5686 9.56869 10.4 9.97565 10.4 10.4C10.4 10.8243 10.5686 11.2313 10.8686 11.5314C11.1687 11.8314 11.5757 12 12 12C12.4244 12 12.8313 11.8314 13.1314 11.5314C13.4314 11.2313 13.6 10.8243 13.6 10.4C13.6 9.97565 13.4314 9.56869 13.1314 9.26863C12.8313 8.96857 12.4244 8.8 12 8.8Z"
      fill="currentColor"
    />
  </svg>
</template>
