/**
 * No Vuetify dependencies.
 */
import AccountIcon from "@lib/components/Icons/Account";
import AlertOutline from "@lib/components/Icons/AlertOutline";
import AnalyticsCharts from "@lib/components/Icons/AnalyticsCharts";
import ArrowLeft from "@lib/components/Icons/ArrowLeft";
import Bank from "@lib/components/Icons/Bank";
import Calendar from "@lib/components/Icons/Calendar";
import ChartBar from "@lib/components/Icons/ChartBar";
import CheckboxOutline from "@lib/components/Icons/CheckboxOutline";
import CheckCircleOutline from "@lib/components/Icons/CheckCircleOutline";
import Checkmark from "@lib/components/Icons/Checkmark";
import ChevronDown from "@lib/components/Icons/ChevronDown";
import ChevronLeft from "@lib/components/Icons/ChevronLeft";
import ChevronRight from "@lib/components/Icons/ChevronRight";
import ChevronUp from "@lib/components/Icons/ChevronUp";
import Clients from "@lib/components/Icons/Clients";
import Close from "@lib/components/Icons/Close";
import CodeBraces from "@lib/components/Icons/CodeBraces";
import CogOutline from "@lib/components/Icons/CogOutline";
import CommentAlert from "@lib/components/Icons/CommentAlert";
import Copy from "@lib/components/Icons/Copy";
import CopyLink from "@lib/components/Icons/CopyLink";
import Csv from "@lib/components/Icons/Csv";
import Dashboard from "@lib/components/Icons/Dashboard";
import DashboardInstruments from "@lib/components/Icons/DashboardInstruments";
import Delete from "@lib/components/Icons/Delete";
import DotsVertical from "@lib/components/Icons/DotsVertical";
import Eye from "@lib/components/Icons/Eye";
import Export from "@lib/components/Icons/Export";
import EyeOff from "@lib/components/Icons/EyeOff";
import Filter from "@lib/components/Icons/Filter";
import File from "@lib/components/Icons/File";
import Import from "@lib/components/Icons/Import";
import Info from "@lib/components/Icons/Info";
import Kr from "@lib/components/Icons/Kr";
import Logout from "@lib/components/Icons/Logout";
import Magnify from "@lib/components/Icons/Magnify";
import Menu from "@lib/components/Icons/Menu";
import MenuOpen from "@lib/components/Icons/MenuOpen";
import Minus from "@lib/components/Icons/Minus";
import OpenLink from "@lib/components/Icons/OpenLink";
import PortfolioOutline from "@lib/components/Icons/PortfolioOutline";
import Paperclip from "@lib/components/Icons/Paperclip";
import Pdf from "@lib/components/Icons/Pdf";
import Pencil from "@lib/components/Icons/Pencil";
import Percent from "@lib/components/Icons/Percent.vue";
import Play from "@lib/components/Icons/Play";
import Plus from "@lib/components/Icons/Plus";
import Refresh from "@lib/components/Icons/Refresh";
import Save from "@lib/components/Icons/Save";
import Share from "@lib/components/Icons/Share";
import Sofa from "@lib/components/Icons/Sofa";
import SpinnerAnimation from "@lib/components/Icons/SpinnerAnimation";
import Tune from "@lib/components/Icons/Tune";
import TuneVertical from "@lib/components/Icons/TuneVertical";
import User from "@lib/components/Icons/User";
import Unlink from "@lib/components/Icons/Unlink";
import Web from "@lib/components/Icons/Web";
import Xlsx from "@lib/components/Icons/Xlsx";
import Password from "@lib/components/Icons/Password";

const customSet: Record<string, Component> = {
  "account": AccountIcon,
  "alert-outline": AlertOutline,
  "analytics-charts": AnalyticsCharts,
  "arrow-left": ArrowLeft,
  "chart-bar": ChartBar,
  "bank": Bank,
  "calendar": Calendar,
  "check-circle-outline": CheckCircleOutline,
  "checkbox-outline": CheckboxOutline,
  "checkmark": Checkmark,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "clients": Clients,
  "close": Close,
  "code-braces": CodeBraces,
  "cog-outline": CogOutline,
  "comment-alert": CommentAlert,
  "copy": Copy,
  "copy-link": CopyLink,
  "csv": Csv,
  "dashboard": Dashboard,
  "dashboard-instruments": DashboardInstruments,
  "delete": Delete,
  "dots-vertical": DotsVertical,
  "eye": Eye,
  "export": Export,
  "eye-off": EyeOff,
  "filter": Filter,
  "file": File,
  "import": Import,
  "info": Info,
  "kr": Kr,
  "logout": Logout,
  "magnify": Magnify,
  "menu": Menu,
  "menu-open": MenuOpen,
  "minus": Minus,
  "open-link": OpenLink,
  "portfolio-outline": PortfolioOutline,
  "paperclip": Paperclip,
  "pdf": Pdf,
  "pencil": Pencil,
  "percent": Percent,
  "play": Play,
  "plus": Plus,
  "refresh": Refresh,
  "share": Share,
  "sofa": Sofa,
  "spinner-animation": SpinnerAnimation,
  "save": Save,
  "tune": Tune,
  "tune-vertical": TuneVertical,
  "user": User,
  "unlink": Unlink,
  "web": Web,
  "xlsx": Xlsx,
  "password": Password,
};

export const custom: { component: (props: { icon: string }) => Component } = {
  component: (props: { icon: string }) => {
    return h(customSet[props.icon]);
  },
};
