<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.74536 4C11.0603 4 12.937 5.87665 12.937 8.19161C12.937 10.5066 11.0603 12.3832 8.74536 12.3832C6.4304 12.3832 4.55375 10.5066 4.55375 8.19161C4.55375 5.87665 6.4304 4 8.74536 4ZM11.437 8.19161C11.437 6.70507 10.2319 5.5 8.74536 5.5C7.25883 5.5 6.05375 6.70507 6.05375 8.19161C6.05375 9.67814 7.25883 10.8832 8.74536 10.8832C10.2319 10.8832 11.437 9.67814 11.437 8.19161Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.6742 17.9665H22V17.2165C22 16.286 21.8339 15.505 21.5021 14.8635C21.1665 14.2146 20.6822 13.7495 20.1159 13.4265C19.0238 12.8036 17.6369 12.719 16.4318 12.719C15.2266 12.719 13.8397 12.8036 12.7476 13.4265C12.7132 13.4461 12.6792 13.4662 12.6454 13.4869C11.4334 13.0245 10.0273 12.9483 8.74549 12.9483C7.19616 12.9483 5.4651 13.0597 4.11492 13.8298C3.4195 14.2264 2.82775 14.7952 2.41681 15.5897C2.0097 16.3768 1.8006 17.3451 1.8006 18.5165V19.2665H15.6904V18.5165C15.6904 18.328 15.685 18.1446 15.6742 17.9665ZM14.209 14.4407C14.8314 14.2696 15.5868 14.219 16.4318 14.219C17.6357 14.219 18.6579 14.3217 19.3727 14.7294C19.7098 14.9217 19.9784 15.1827 20.1698 15.5526C20.2928 15.7904 20.3919 16.089 20.4484 16.4665H15.4234C15.3307 16.1522 15.2141 15.8602 15.0742 15.5897C14.8398 15.1366 14.5466 14.7569 14.209 14.4407ZM3.74913 16.2788C3.5519 16.6601 3.4038 17.1452 3.33806 17.7665H14.1529C14.0872 17.1452 13.9391 16.6601 13.7418 16.2788C13.4752 15.7633 13.099 15.3986 12.6329 15.1327C11.66 14.5778 10.2936 14.4483 8.74549 14.4483C7.19737 14.4483 5.83099 14.5778 4.85809 15.1327C4.39196 15.3986 4.01576 15.7633 3.74913 16.2788Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8585 9.18599C19.8585 7.29342 18.3242 5.75919 16.4317 5.75919C14.5391 5.75919 13.0049 7.29342 13.0049 9.18599C13.0049 11.0786 14.5391 12.6128 16.4317 12.6128C18.3242 12.6128 19.8585 11.0786 19.8585 9.18599ZM16.4317 7.25919C17.4958 7.25919 18.3585 8.12185 18.3585 9.18599C18.3585 10.2501 17.4958 11.1128 16.4317 11.1128C15.3675 11.1128 14.5049 10.2501 14.5049 9.18599C14.5049 8.12185 15.3675 7.25919 16.4317 7.25919Z"
      fill="currentColor"
    />
  </svg>
</template>
