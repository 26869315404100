<template>
  <v-dialog :model-value="true" max-width="730" @click:outside="$emit('close')">
    <v-card class="pa-6 ma-0">
      <v-card-title class="pa-0 ma-0">
        <v-row justify="space-between" class="pa-0 ma-0">
          <v-col cols="10" align="start" class="shrink pa-0 ma-0 n-lib-t30m font-ballinger text-text-base">
            {{ $t("feedback-dialog.title") }}
          </v-col>
          <v-col align="end" class="shrink pa-0 ma-0 d-flex justify-end">
            <v-btn variant="text" color="gray lighten-3 d-flex align-start justify-end pa-0" @click="$emit('close')">
              <v-icon>n:close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0 ma-0 mt-6">
        <v-container fluid class="pa-0 ma-0">
          <v-row no-gutters>
            <v-col class="n-lib-t16r">
              <div>
                {{ $t("feedback-dialog.description.line-1") }}
              </div>
              <div>
                {{ $t("feedback-dialog.description.line-2") }}
              </div>
            </v-col>
          </v-row>
          <v-row v-if="issueTypeEnabled" class="ma-0 mt-6 pa-0">
            <v-col class="ma-0 pa-0">
              <v-select
                v-model="issueType"
                :label="$t('feedback-dialog.issue-type-lbl')"
                hide-details
                variant="outlined"
                :items="issueTypeOptions"
                :menu-props="{
                  contentClass: 'manual-account-select-account-type-menu',
                  nudgeLeft: '1',
                  nudgeWidth: '1',
                  nudgeTop: '1',
                }"
                item-title="label"
                item-value="value"
                required
                density="compact"
                color="secondary"
              />
            </v-col>
          </v-row>
          <v-row class="ma-0 mt-6 pa-0">
            <v-col class="ma-0 pa-0">
              <v-textarea
                v-model="feedbackText"
                class="n-lib-t14r"
                :label="$t('feedback-dialog.description-lbl')"
                hide-details
                variant="outlined"
                required
                density="compact"
                color="secondary"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pa-0 ma-0 mt-6">
        <v-row class="pa-0 ma-0">
          <v-col class="flex justify-content-end pa-0 ma-0" align="end">
            <v-btn
              rounded="5"
              color="secondary"
              variant="flat"
              size="large"
              class="text-uppercase px-8"
              :loading="inProgress"
              :disabled="isSendDisabled"
              @click="handleSend"
            >
              {{ $t("common.send") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
const $props = defineProps({
  inProgress: {
    type: Boolean,
    default: () => false,
  },
  issueTypeEnabled: {
    type: Boolean,
    default: () => false,
  },
});

const $emit = defineEmits(["close", "send"]);
const { t: $t } = useI18n();

const issueType = ref<string | null>(null);
const feedbackText = ref("");

const isIssueTypeCorrect = computed(() => {
  return !$props.issueTypeEnabled || issueType.value;
});
const isSendDisabled = computed(() => !isIssueTypeCorrect.value || feedbackText.value.length < 1);

const issueTypeOptions = [
  {
    value: "general",
    label: $t("feedback-dialog.issue-type.general"),
  },
  {
    value: "missing-accounts",
    label: $t("feedback-dialog.issue-type.missing-accounts"),
  },
  {
    value: "missing-bank",
    label: $t("feedback-dialog.issue-type.missing-bank"),
  },
  {
    value: "missing-data",
    label: $t("feedback-dialog.issue-type.missing-data"),
  },
  {
    value: "other",
    label: $t("feedback-dialog.issue-type.other"),
  },
];

async function handleSend() {
  if (isSendDisabled.value) {
    return;
  }
  $emit("send", {
    feedbackText: feedbackText.value,
    issueType: issueType.value ?? "",
  });
}
</script>
