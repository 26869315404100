<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.2586 7.7389C14.2586 6.08818 12.9204 4.75 11.2697 4.75C9.61893 4.75 8.28075 6.08818 8.28075 7.7389C8.28075 9.38962 9.61893 10.7278 11.2697 10.7278C12.9204 10.7278 14.2586 9.38962 14.2586 7.7389ZM11.2697 3.25C13.7488 3.25 15.7586 5.25975 15.7586 7.7389C15.7586 10.2181 13.7488 12.2278 11.2697 12.2278C8.7905 12.2278 6.78075 10.2181 6.78075 7.7389C6.78075 5.25975 8.7905 3.25 11.2697 3.25ZM11.27 14.0081C12.9531 14.0081 14.8179 14.1299 16.2685 14.9572C17.014 15.3825 17.6476 15.9916 18.0878 16.8427C18.5242 17.6863 18.75 18.7275 18.75 19.9926V20.7426H18H4.53996H3.78996V19.9926C3.78996 18.7275 4.01578 17.6863 4.45216 16.8427C4.89236 15.9916 5.52593 15.3825 6.27151 14.9572C7.72202 14.1299 9.58687 14.0081 11.27 14.0081ZM5.78448 17.5318C5.55832 17.9691 5.39189 18.5267 5.32383 19.2426H17.2161C17.1481 18.5267 16.9816 17.9691 16.7555 17.5318C16.4596 16.9597 16.0416 16.5547 15.5253 16.2602C14.452 15.648 12.9519 15.5081 11.27 15.5081C9.58808 15.5081 8.08792 15.648 7.01468 16.2602C6.49838 16.5547 6.08037 16.9597 5.78448 17.5318Z"
      fill="currentColor"
    />
  </svg>
</template>
