<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.4454 11.1726H8.62727V18.4454H10.4454V11.1726Z" fill="currentColor" />
    <path d="M11.3545 13.8999H13.1727V18.4454H11.3545V13.8999Z" fill="currentColor" />
    <path d="M15.9 15.7181H14.0818V18.4454H15.9V15.7181Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.14999 3.1499V21.0135H19.3773V9.04379L13.4834 3.1499H5.14999ZM6.64999 19.5135V4.6499H12.4227V10.1044H17.8773V19.5135H6.64999ZM16.8166 8.60445L13.9227 5.71056V8.60445H16.8166Z"
      fill="currentColor"
    />
  </svg>
</template>
