<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.74956 15.1499C3.17737 15.1499 3.50265 14.86 3.50265 14.4145C3.50265 13.969 3.17737 13.6649 2.74956 13.6649C2.32528 13.6649 2 13.969 2 14.4145C2 14.86 2.32528 15.1499 2.74956 15.1499Z"
      fill="currentColor"
    />
    <path
      d="M9.74683 15.0368L8.0674 12.1057L9.71855 9.26304H8.59067L7.39209 11.4198L6.24654 9.26304H5.11866L6.71324 12.1057L5.08331 15.0368H6.17229L7.39209 12.7846L8.66492 15.0368H9.74683Z"
      fill="currentColor"
    />
    <path d="M10.4825 15.0368H14.1242V14.1635H11.5149V9.26304H10.4825V15.0368Z" fill="currentColor" />
    <path
      d="M16.7974 15.1499C18.0631 15.1499 18.7915 14.4781 18.7915 13.4457C18.7915 12.5406 18.3672 12.1092 17.2535 11.7557L16.7974 11.6107C16.1857 11.4092 15.9842 11.1652 15.9842 10.7374C15.9842 10.2601 16.2529 9.98785 16.8751 9.98785C17.4338 9.98785 17.8475 10.253 18.0525 10.8718L18.8198 10.4051C18.4909 9.50347 17.8722 9.1499 16.914 9.1499C15.6377 9.1499 14.9341 9.82875 14.9341 10.8011C14.9341 11.7486 15.468 12.2295 16.4473 12.5441L16.914 12.6962C17.5328 12.8906 17.7626 13.0709 17.7626 13.5447C17.7626 13.9973 17.455 14.3155 16.8256 14.3155C16.1786 14.3155 15.7331 13.9973 15.4609 13.3609L14.7149 13.8594C15.1002 14.7681 15.7968 15.1499 16.7974 15.1499Z"
      fill="currentColor"
    />
    <path
      d="M23.7381 15.0368L22.0586 12.1057L23.7098 9.26304H22.5819L21.3833 11.4198L20.2378 9.26304H19.1099L20.7045 12.1057L19.0745 15.0368H20.1635L21.3833 12.7846L22.6562 15.0368H23.7381Z"
      fill="currentColor"
    />
  </svg>
</template>
