<template>
  <v-app-bar v-if="isAppLayout" flat app color="surface" :clipped-left="true" class="app-bar elevation-custom">
    <v-row no-gutters>
      <v-col no-gutters align="start" class="app-bar__col app-bar__left-side d-flex align-center">
        <v-app-bar-nav-icon @click="toggleSidebar">
          <!--ToDo: update icon-->
          <v-icon v-if="isMiniVariant">n:menu</v-icon>
          <v-icon v-else>n:menu-open</v-icon>
        </v-app-bar-nav-icon>
        <v-avatar size="100" tile class="ml-5">
          <n-img contain lazy :src="layoutSettings?.logoImage" alt="logo" class="w-100" />
        </v-avatar>
      </v-col>
      <v-col class="col-vertical-divider" align="start">
        <v-divider vertical />
      </v-col>
      <v-col class="app-bar__col m-0 py-0 px-6 d-flex align-center justify-start organization-select-wrapper">
        <v-select
          v-if="organizations.length > 1"
          v-model="selectedOrganization"
          class="organization-select app-bar__menu"
          color="secondary"
          :items="organizations"
          variant="outlined"
          :label="selectOrganizationLabel"
          item-title="name"
          item-value="public_id"
          density="compact"
          hide-details
          @update:model-value="handleChangeOrganization($event)"
        />
        <div v-else-if="organizations.length === 1">
          <div>{{ $t("appBar.organization-select-label") }}:</div>
          <div>{{ organizations[0].name }}</div>
        </div>
        <div v-else>
          {{ $t("appBar.no-organizations") }}
        </div>
      </v-col>
      <v-col no-gutters class="app-bar__col d-flex align-center justify-end">
        <v-menu bottom class="app-bar__menu elevation-custom" offset-y>
          <template #activator="{ props, isActive }">
            <v-btn variant="text" v-bind="props" :ripple="false" max-width="300">
              <v-icon class="mr-auto">n:user</v-icon>
              <span class="app-bar__menu__text">{{ `${getUserName()}` }}</span>
              <v-icon v-if="isActive" class="ml-auto" color="secondary">n:chevron-down</v-icon>
              <v-icon v-else class="ml-auto">n:chevron-up</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0 my-0">
            <v-menu
              :disabled="$availableLocales.length < 2"
              class="app-bar__locale-menu elevation-custom"
              offset-x
              location="start"
            >
              <template #activator="{ props: insideProps }">
                <v-list-item role="menuitem" v-bind="insideProps" class="px-3">
                  <template #prepend>
                    <v-icon color="secondary">n:web</v-icon>
                  </template>
                  {{ $t("appBar.language") }}
                </v-list-item>
              </template>

              <v-list class="pa-0 my-0 border-thin-solid-secondary rounded-lg">
                <v-list-item
                  v-for="locale in $availableLocales"
                  :key="locale.code"
                  role="menuitem"
                  :class="{
                    'v-list-item--active': locale.code === appLocale.code,
                  }"
                  @click="$setAppLocale(locale)"
                >
                  {{ locale.name }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-list-item role="menuitem" class="px-3" @click="handleLogout">
              <template #prepend>
                <v-icon color="secondary">n:logout</v-icon>
              </template>
              {{ $t("appBar.logout") }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
  <v-row
    v-else
    class="portfolio-fetcher-bar px-0"
    :class="{
      'px-4': sm,
      'px-2': !smAndDown,
    }"
    :justify="showLogo ? 'space-between' : 'end'"
    align="center"
    no-gutters
  >
    <v-col v-if="showLogo && layoutSettings?.logoImage" cols="4" md="6" lg="6" class="ma-0 pa-0">
      <n-img contain class="portfolio-fetcher-bar__logo" lazy :src="layoutSettings?.logoImage" alt="Njorda Logo" />
    </v-col>
    <v-col v-if="showLanguageSwitcher" id="locale-switcher" cols="3" md="2" lg="2" sm="3" class="shrink pa-0 mr-16">
      <v-menu :disabled="$availableLocales.length < 2" attach="#locale-switcher" location="bottom" offset="-50">
        <template #activator="{ props }">
          <v-btn
            class="px-2 portfolio-fetcher-bar__locale-switcher white border-thin-solid-gray rounded-lg d-flex unset-text-transform align-stretch"
            v-bind="props"
          >
            <v-icon align="start" class="mr-4">n:web</v-icon>
            <span class="ml-4">{{ appLocale.name }}</span>
            <v-icon class="ml-auto">n:chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list class="pa-0 border-thin-solid-secondary rounded-lg">
          <v-list-item class="pa-0">
            <v-list-item-title class="v-btn__content px-2" justify="space-between">
              <v-icon align="start" color="secondary" class="mr-4">n:web</v-icon>
              <span class="ml-4">{{ appLocale.name }}</span>
              <v-icon class="ml-auto" color="secondary">n:chevron-up</v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="locale in $availableLocales"
            :key="locale.code"
            :class="{
              selected: locale.code === appLocale.code,
            }"
            @click="$setAppLocale(locale)"
          >
            <v-list-item-title class="ml-12">
              {{ locale.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
import { useAdvisorStore } from "@/store/advisor";
import { useDisplay } from "vuetify";

const { t: $t } = useI18n();

defineProps({
  hideLocaleSelector: {
    type: Boolean,
    default: () => false,
  },
  white: {
    type: Boolean,
    default: () => false,
  },
  showLogo: {
    type: Boolean,
    default: () => true,
  },
  showLanguageSwitcher: {
    type: Boolean,
    default: () => true,
  },
  isAppLayout: {
    type: Boolean,
    default: () => false,
  },
});

const { layoutSettings, isMiniVariant } = storeToRefs(useAppStore());
const { setIsMiniVariant, logout } = useAppStore();
const { handleClearState } = useAdvisorStore();
const { organization, organizations } = storeToRefs(useAdvisorStore());
const { $availableLocales, $activeLocale, $getAppLocale, $setAppLocale, $auth } = useNuxtApp();

const selectedOrganization = ref(organization.value?.public_id);

const $route = useRoute();
const $router = useRouter();
const { sm, smAndDown } = useDisplay();

const appLocale = computed(() => {
  return $getAppLocale($activeLocale.value);
});
// For instant UI translation
const selectOrganizationLabel = computed(() => {
  return $t("appBar.organization-select-label");
});

async function handleLogout() {
  await logout();
}
function getUserName() {
  if ($auth.user) {
    const { first_name, last_name } = $auth.user;

    if ($auth.user.organization) {
      const { name } = $auth.user.organization;

      return `${first_name ?? ""} ${last_name ?? ""} | ${name ?? ""}`.trim();
    }

    return `${first_name ?? ""} ${last_name ?? ""}`.trim();
  }
  return "";
}
function toggleSidebar() {
  setIsMiniVariant(!isMiniVariant.value);
}
function handleChangeOrganization(orgId: string) {
  handleClearState();
  if ($route.params.client_id) {
    $router.push(`/advisor/${orgId}/clients`);
  } else if ($route.params.user_id) {
    $router.push(`/advisor/${orgId}/manage/users`);
  } else if ($route.params.proposal_id) {
    $router.push(`/advisor/${orgId}/portfolio-builder`);
  } else if ($route.params.report_id) {
    $router.push(`/advisor/${orgId}/analytics/reports`);
  } else {
    $router.replace({
      params: {
        org_id: orgId,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
@use "sass:map" as map;
@use "@/assets/style/variables" as vars;

.app-bar {
  &__logout-menu {
    &__text {
      white-space: nowrap;
      max-width: 20vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__left-side {
    flex: 0 0 calc(260px - 4px) !important;
  }

  &__col {
    height: 64px;
  }
}

:deep(*) {
  .min-height-36 {
    min-height: 36px !important;
  }
}

.app-bar :deep(*) {
  .v-toolbar__content {
    padding: 4px !important;
  }
}

#locale-switcher {
  .menuable__content__active {
    box-shadow: none !important;
  }

  .v-btn {
    box-shadow: none !important;
  }
}
:deep(.v-btn__content) {
  font-size: 16px;
  font-family: vars.$body-font-family;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 16px !important;
    letter-spacing: normal !important;
  }

  .v-icon {
    color: rgb(0 0 0 / 54%);
  }

  .web-icon {
    margin-right: 16px;
  }

  .chevron-icon {
    justify-self: end;
  }

  @media (width <= 600px) {
    .web-icon {
      margin-right: 0;
    }
  }
}

.portfolio-fetcher-bar {
  &__locale-switcher {
    min-width: 200px !important;
    border-radius: 4px;
    min-height: 48px;

    :deep(*) {
      .v-select__slot .v-select__selection--comma {
        overflow: unset;
      }
    }
  }

  &__logo {
    max-width: 50vw !important;
    height: 60px !important;
  }

  @media #{map.get(vars.$display-breakpoints, 'sm')} {
    &__locale-switcher {
      width: 150px !important;
    }
  }

  @media #{map.get(vars.$display-breakpoints, 'xs')} {
    &__locale-switcher {
      min-width: 150px !important;
    }
  }
}

.organization-select :deep(.primary--text) {
  color: rgb(var(--v-theme-gray-ligthen-3));
  caret-color: rgb(var(--v-theme-gray-ligthen-3));
}

.organization-select-wrapper {
  flex: 0 0 calc(256px - 4px) !important;
}

@media #{map.get(vars.$display-breakpoints, 'md-and-down')} {
  .organization-select-wrapper {
    flex: 0 0 calc(192px - 4px) !important;
  }
}

.unset-text-transform {
  text-transform: unset !important;
}
</style>
