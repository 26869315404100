import { custom } from "@lib/utils/icons";
import { mdi } from "vuetify/iconsets/mdi";
import color from "color";

// import this after install `@mdi/font` package
import "@mdi/font/css/materialdesignicons.css";

import "@/assets/style/main.scss";
import { createVuetify, type IconSet } from "vuetify";
import { njordaCustomTheme, themes } from "@lib/utils/njorda-themes";

const vuetifyDefaulColorStructure = ["primary", "secondary"];

type TThemeKey = keyof typeof themes;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const brandThemes = {} as Record<TThemeKey, any>;
Object.keys(themes).forEach((key) => {
  const theme = themes[key as TThemeKey];
  const outputTheme = {
    dark: false,
    colors: {} as Record<keyof typeof theme, string>,
  };

  Object.keys(theme).forEach((colorKey) => {
    const colors = {
      [colorKey]: theme[colorKey as keyof typeof theme],
    };
    if (vuetifyDefaulColorStructure.includes(colorKey)) {
      colors[`${colorKey}-base`] = theme[colorKey as keyof typeof theme];

      const baseColor = color(theme[colorKey as keyof typeof theme]);
      const variants: Record<string, string> = {
        "lighten-1": baseColor.lightness(76).hex(),
        "lighten-2": baseColor.lightness(86).hex(),
        "lighten-3": baseColor.lightness(96).hex(),
        "darken-1": baseColor.blacken(0.5).hex(),
        "darken-2": baseColor.blacken(0.6).hex(),
        "darken-3": baseColor.blacken(0.7).hex(),
      };
      Object.keys(variants).forEach((key) => {
        colors[`${colorKey}-${key}`] = variants[key];
      });
    }

    outputTheme.colors = {
      ...outputTheme.colors,
      ...colors,
    };

    outputTheme.colors["surface-loader" as keyof typeof theme] =
      outputTheme.colors["secondary-base" as keyof typeof theme];
    outputTheme.colors["info-surface" as keyof typeof theme] = outputTheme.colors["info-surface" as keyof typeof theme]
      ? outputTheme.colors["info-surface" as keyof typeof theme]
      : outputTheme.colors["secondary-lighten-2" as keyof typeof theme];
    outputTheme.colors["info-surface-lighten-1" as keyof typeof theme] = outputTheme.colors[
      "info-surface-lighten-1" as keyof typeof theme
    ]
      ? outputTheme.colors["info-surface-lighten-1" as keyof typeof theme]
      : outputTheme.colors["secondary-lighten-3" as keyof typeof theme];
    outputTheme.colors["info-surface2" as keyof typeof theme] = outputTheme.colors[
      "info-surface2" as keyof typeof theme
    ]
      ? outputTheme.colors["info-surface2" as keyof typeof theme]
      : outputTheme.colors["secondary-lighten-2" as keyof typeof theme];
    outputTheme.colors["info-surface2-lighten-1" as keyof typeof theme] = outputTheme.colors[
      "info-surface2-lighten-1" as keyof typeof theme
    ]
      ? outputTheme.colors["info-surface2-lighten-1" as keyof typeof theme]
      : outputTheme.colors["secondary-lighten-3" as keyof typeof theme];
    outputTheme.colors["divider" as keyof typeof theme] = outputTheme.colors["secondary-base" as keyof typeof theme];
    outputTheme.colors["border" as keyof typeof theme] = outputTheme.colors["secondary-base" as keyof typeof theme];

    if (outputTheme.colors.background === outputTheme.colors["secondary-base" as keyof typeof theme]) {
      // Loader color will take up the default surface/card colour on no-card surface when secondary and background are the same.
      outputTheme.colors["loader" as keyof typeof theme] = "#fff";
    } else {
      outputTheme.colors["loader" as keyof typeof theme] = outputTheme.colors["secondary-base" as keyof typeof theme];
    }
  });
  brandThemes[key as TThemeKey] = {
    ...outputTheme,
    colors: {
      ...njordaCustomTheme.colors,
      ...outputTheme.colors,
    },
  };
});

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    theme: {
      defaultTheme: "njordaCustomTheme",
      themes: {
        njordaCustomTheme,
        ...brandThemes,
      },
    },
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi,
        n: custom as IconSet,
      },
    },
    display: {
      mobileBreakpoint: "sm",
      thresholds: {
        xs: 0, // < 576px
        sm: 576, // 576px > x > 768px
        md: 768, // 768px > x > 992px
        lg: 992, // 992px > x > 1200px
        xl: 1200, // 1200px > x > 1400px
        xxl: 1400, // 1400px <
      },
    },
  });
  app.vueApp.use(vuetify);
});
