<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3636 12.6882V2.5H3V20.5H13.9996C13.6356 20.0153 13.3575 19.4623 13.1882 18.8636H12V10.6818H17.7273V12.5073C17.8176 12.5025 17.9085 12.5 18 12.5C18.4727 12.5 18.9302 12.5656 19.3636 12.6882ZM17.7273 9.04545V4.13636H12V9.04545H17.7273ZM10.3636 4.13636H4.63636V12.3182H10.3636V4.13636ZM10.3636 13.9545H4.63636V18.8636H10.3636V13.9545Z"
      fill="currentColor"
    />
    <path
      d="M19.1098 13.5004L19.2629 14.3402C19.2732 14.4332 19.305 14.5225 19.3559 14.6009C19.4067 14.6793 19.4751 14.7446 19.5556 14.7914C19.636 14.8383 19.7263 14.8655 19.8192 14.8709C19.912 14.8762 20.0048 14.8595 20.09 14.8222L20.8848 14.5357L21.999 16.4661L21.3516 17.0212C21.2767 17.0766 21.2157 17.149 21.1737 17.2325C21.1317 17.316 21.1098 17.4083 21.1099 17.5019C21.1099 17.5956 21.1318 17.6879 21.1739 17.7714C21.216 17.8548 21.277 17.9272 21.3521 17.9825L22 18.5373L20.8863 20.4681L20.0891 20.1811C20.0891 20.1811 19.9113 20.1265 19.8184 20.1316C19.7256 20.1368 19.6352 20.1639 19.5547 20.2107C19.4742 20.2576 19.4058 20.3229 19.355 20.4013C19.3043 20.4797 19.2726 20.5691 19.2625 20.6622L19.1113 21.5H16.8883L16.7366 20.663C16.7261 20.5704 16.6941 20.4815 16.6433 20.4035C16.5924 20.3255 16.5242 20.2606 16.4439 20.214C16.3637 20.1674 16.2737 20.1404 16.1812 20.1351C16.0887 20.1299 15.9962 20.1465 15.9113 20.1837L15.1137 20.4702L14 18.5377L14.6484 17.9825C14.7234 17.9271 14.7844 17.8548 14.8264 17.7713C14.8685 17.6878 14.8904 17.5955 14.8904 17.5018C14.8904 17.4082 14.8685 17.3159 14.8264 17.2324C14.7844 17.1489 14.7234 17.0765 14.6484 17.0212L14.0002 16.4669L15.1146 14.5363L15.9091 14.8226C15.9945 14.8599 16.0874 14.8764 16.1803 14.871C16.2732 14.8656 16.3635 14.8383 16.444 14.7913C16.5245 14.7444 16.593 14.6791 16.6439 14.6006C16.6948 14.5222 16.7267 14.4329 16.7371 14.3398L16.8903 13.5L19.1098 13.5004ZM17.9991 16.2063C17.6578 16.2063 17.3305 16.3428 17.0891 16.5857C16.8478 16.8287 16.7122 17.1582 16.7122 17.5018C16.7122 17.8454 16.8478 18.175 17.0891 18.4179C17.3305 18.6609 17.6578 18.7974 17.9991 18.7974C18.3405 18.7974 18.6678 18.6609 18.9092 18.4179C19.1505 18.175 19.2861 17.8454 19.2861 17.5018C19.2861 17.1582 19.1505 16.8287 18.9092 16.5857C18.6678 16.3428 18.3405 16.2063 17.9991 16.2063Z"
      fill="currentColor"
    />
  </svg>
</template>
