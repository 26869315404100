<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10 5.23333H4V4H10V5.23333Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 9C10.5 10.3807 9.38071 11.5 8 11.5C6.61929 11.5 5.5 10.3807 5.5 9C5.5 7.61929 6.61929 6.5 8 6.5C9.38071 6.5 10.5 7.61929 10.5 9ZM9.25 9C9.25 9.69036 8.69036 10.25 8 10.25C7.30964 10.25 6.75 9.69036 6.75 9C6.75 8.30964 7.30964 7.75 8 7.75C8.69036 7.75 9.25 8.30964 9.25 9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 2V14H14V4.66667L11.3333 2H2ZM12.6667 5.23333V12.6667H3.33333V3.33333H10.7667L12.6667 5.23333Z"
      fill="currentColor"
    />
  </svg>
</template>
