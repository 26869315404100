export default defineNuxtPlugin((_nuxtApp) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  let loggers = {} as Record<string, (...args: any[]) => void>;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const addLogger = (cb: (args: any) => void, key: string) => {
    if (typeof cb === "function") {
      loggers[key] = cb;
    }
  };

  const removeLogger = (key: string) => {
    const { [key]: toRemove, ...otherLoggers } = loggers;
    loggers = otherLoggers;
  };

  const log = (...args: unknown[]) => {
    Object.values(loggers).forEach((logger) => {
      logger("log", ...args);
    });
  };

  const logError = (...args: unknown[]) => {
    Object.values(loggers).forEach((logger) => {
      logger("error", ...args);
    });
  };

  const logInfo = (...args: unknown[]) => {
    Object.values(loggers).forEach((logger) => {
      logger("info", ...args);
    });
  };

  const logWarn = (...args: unknown[]) => {
    Object.values(loggers).forEach((logger) => {
      logger("warn", ...args);
    });
  };

  return {
    provide: {
      addLogger,
      removeLogger,
      log,
      logInfo,
      logWarn,
      logError,
    },
  };
});
