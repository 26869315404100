import { useAdvisorStore } from "@/store/advisor";
import { ASSET_LIST_TYPES, EXPORT_FORMATS } from "@/constants/advisor";
import { defineStore } from "pinia";
import { DefaultService } from "@/api/njorda/services/DefaultService";
import type {
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema,
  njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema,
} from "~/api/njorda";

export const useAssetListsStore = defineStore("asset-lists-store", {
  actions: {
    // API Actions
    async getAssets(
      type: (typeof ASSET_LIST_TYPES)[keyof typeof ASSET_LIST_TYPES] | null = ASSET_LIST_TYPES.PORTFOLIO_BUILDER,
      withArchived = true,
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const assets = await DefaultService.getB2BV1PortfolioBuilderDataAssetLists(
        organization.public_id,
        withArchived,
        type ?? undefined,
      )
        .then((resp) => {
          return resp?.items ?? null;
        })
        .catch(() => null);
      return assets;
    },
    createNewAssetsList(
      assetsList: Omit<njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_CreateAssetListSchema, "organization">,
    ) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const payload = {
        ...assetsList,
        organization: organization.public_id,
      };
      return DefaultService.postB2BV1PortfolioBuilderDataAssetLists(payload);
    },
    updateAssetsList({
      assetsListId,
      assetsList,
    }: {
      assetsListId: string;
      assetsList: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_UpdateAssetListSchema;
    }) {
      return DefaultService.patchB2BV1PortfolioBuilderDataAssetLists(assetsListId, assetsList);
    },
    deleteAssetsList(assetsListId: string) {
      return DefaultService.deleteB2BV1PortfolioBuilderDataAssetLists(assetsListId);
    },
    exportAssetsListsAsCSV() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1PortfolioBuilderDataProductListsExport(EXPORT_FORMATS.CSV, organization.public_id);
    },
    async importAssetsListsCSV(blob: Blob) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const res = await DefaultService.postB2BV1PortfolioBuilderDataProductListsImport(organization.public_id, {
        assets: blob,
      });
      return res;
    },
  },
});
