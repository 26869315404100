<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3636 3V21H3V3H19.3636ZM17.7273 4.63636V9.54545H12V4.63636H17.7273ZM4.63636 4.63636H10.3636V12.8182H4.63636V4.63636ZM4.63636 14.4545H10.3636V19.3636H4.63636V14.4545ZM12 19.3636V11.1818H17.7273V19.3636H12Z"
      fill="currentColor"
    />
  </svg>
</template>
