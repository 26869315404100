import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import breadcrumbs_45global from "/app/middleware/breadcrumbs.global.ts";
import cache_45handler_45global from "/app/middleware/cache-handler.global.ts";
import org_45id_45global from "/app/middleware/orgId.global.ts";
export const globalMiddleware = [
  validate,
  breadcrumbs_45global,
  cache_45handler_45global,
  org_45id_45global
]
export const namedMiddleware = {
  "advisor-access-required": () => import("/app/middleware/advisorAccessRequired.ts"),
  "login-required": () => import("/app/middleware/loginRequired.ts"),
  "my-benefit-demo-access-required": () => import("/app/middleware/myBenefitDemoAccessRequired.ts"),
  "seb-demo-access-required": () => import("/app/middleware/sebDemoAccessRequired.ts")
}