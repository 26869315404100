<template>
  <svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.98548 10.5L2.12495 7.88738L1.34131 8.75362V10.5H0V2.5H1.34131V7.35466L3.72106 4.71424H5.3316L3.09608 7.08599L5.55755 10.5H3.98548Z"
      fill="currentColor"
    />
    <path
      d="M10.1299 4.56601C11.3702 4.56601 12 5.27475 12 6.66908C12 6.98408 11.9615 7.30834 11.8846 7.66503L10.5241 7.79473C10.5818 7.44268 10.6154 7.10452 10.6154 6.75246C10.6154 6.13173 10.4472 5.68703 9.73563 5.68703C9.12988 5.68703 8.61066 6.03908 8.35105 7.10915V10.5H7.00493V4.71424H8.1876L8.25009 5.80747C8.5722 5.01534 9.15392 4.56601 10.1299 4.56601Z"
      fill="currentColor"
    />
  </svg>
</template>
