import { useAssetClassesStore } from "./asset-classes";
import { type AssetsListFilter, DefaultService, type AssetListResult, type AssetListMeta } from "~/api/njorda";
import { useAdvisorStore } from "./advisor";
import { EXPORT_FORMATS } from "~/constants/advisor";

type TAssetListRulesState = {
  _assetListRules: AssetsListFilter[];
  _assetListRulesInitial: AssetsListFilter[];
  _assetLists: AssetListResult[];
  _instrumentTypes: Record<string, string | undefined> | null;
  _assetListRulesSchema: AssetListMeta[];
};

const defaultStoreState: TAssetListRulesState = {
  _assetListRules: [],
  _assetListRulesInitial: [],
  _assetLists: [],
  _instrumentTypes: null,
  _assetListRulesSchema: [],
};

export const useAssetListRulesStore = defineStore("assetListRulesStore", {
  state: () => ({
    ...defaultStoreState,
  }),
  getters: {
    assetListRules: (state) => state._assetListRules,
    assetListRulesInitial: (state) => state._assetListRulesInitial,
    assetLists: (state) => state._assetLists,
    instrumentTypes: (state) => state._instrumentTypes,
    assetListRulesSchema: (state) => state._assetListRulesSchema,
  },
  actions: {
    // Mutators
    setAssetListFilters(filters: AssetsListFilter[]) {
      this._assetListRules = filters
        .map((rule, idx) => ({
          ...rule,
          priority: rule.priority ?? idx + 1,
        }))
        .sort((ruleA: AssetsListFilter, ruleB: AssetsListFilter) => {
          return (ruleA.priority ?? 0) - (ruleB.priority ?? 0);
        });
    },
    setAssetListFiltersInitial(filters: AssetsListFilter[]) {
      this._assetListRulesInitial = filters;
    },
    setAssetsLists(lists: AssetListResult[]) {
      this._assetLists = lists;
    },
    setInstrumentTypes(types: Record<string, string | undefined>) {
      this._instrumentTypes = types;
    },
    setRulesSchema(schema: AssetListMeta[]) {
      this._assetListRulesSchema = schema;
    },

    // API
    fetchAssetListFilters() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      const { public_id } = organization;
      return DefaultService.getB2BV1PortfolioBuilderDataAssetListFilters(public_id).then(
        async (resp) => {
          await this.setAssetListFilters(resp?.items ?? []);
          this.setAssetListFiltersInitial(this.assetListRules ?? []);
          return resp;
        },
        (error) => {
          useNuxtApp().$notifyError({
            text: `Get calc parameters. ${error}`,
          });
        },
      );
    },
    saveAssetListRulesInBatch(_rules?: AssetsListFilter[]) {
      const rules = _rules ?? this._assetListRules;
      return DefaultService.putB2BV1PortfolioBuilderDataAssetListFiltersBatch(
        rules.map((rule, idx) => {
          const { asset_list_id: asset_list, asset_list_name, ..._rule } = rule;
          return {
            ..._rule,
            priority: idx + 1,
            asset_list: asset_list ?? "unknown",
          };
        }),
      ).then(() => {
        return this.fetchAssetListFilters();
      });
    },
    validateAssetListFilter(filterId: string) {
      return DefaultService.getB2BV1PortfolioBuilderDataAssetListsValidateAssetListFilters(filterId);
    },
    deleteAssetListFilter(filterId: string) {
      return DefaultService.deleteB2BV1PortfolioBuilderDataAssetListFilters(filterId).then(() => {
        return this.fetchAssetListFilters();
      });
    },
    async fetchAssetListFiltersRulesSchema() {
      const rulesSchema = await DefaultService.getB2BV1PortfolioBuilderDataAssetListFiltersSchema();
      this.setRulesSchema(rulesSchema?.items ?? []);
    },
    async fetchAssetListFiltersAssetLists() {
      const { fetchAssetClasses } = useAssetClassesStore();
      const assetsLists = await fetchAssetClasses(false, false);
      if (assetsLists) {
        this.setAssetsLists(assetsLists);
      }
    },
    async fetchAssetListFiltersInstrumentTypes() {
      const instrumentTypes = await DefaultService.getB2BV1InstrumentTypes().then((resp) => resp || {});
      this.setInstrumentTypes(instrumentTypes);
    },
    importAssetListFilters(file: Blob) {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1PortfolioBuilderDataAssetListFiltersImport(organization.public_id, {
        asset_list_filters: file,
      });
    },
    exportAssetListFilters() {
      const { organization } = useAdvisorStore();
      if (!organization?.public_id) {
        return Promise.reject("Organization is not set");
      }
      return DefaultService.postB2BV1PortfolioBuilderDataAssetListFiltersExport(
        EXPORT_FORMATS.CSV,
        organization.public_id,
      );
    },
  },
});
