import { njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema } from "~/api/njorda";

export const HOLDING_REPORT_STATUS = njorda_bps_b2b_v1_schemas_connect_ConnectUpdateSchema.status;

export const HOLDING_REPORT_STATUS_OPTIONS = {
  [HOLDING_REPORT_STATUS.INITIALIZED]: {
    id: 1,
    value: HOLDING_REPORT_STATUS.INITIALIZED,
    text: "advisor.holdingReports.reportStatuses.initialized",
    color: "#FF557D",
  },
  [HOLDING_REPORT_STATUS.CONSENT_RECEIVED]: {
    id: 2,
    value: HOLDING_REPORT_STATUS.CONSENT_RECEIVED,
    text: "advisor.holdingReports.reportStatuses.consentReceived",
    color: "#FF557D",
  },
  [HOLDING_REPORT_STATUS.BANKS_SELECTED]: {
    id: 3,
    value: HOLDING_REPORT_STATUS.BANKS_SELECTED,
    text: "advisor.holdingReports.reportStatuses.banksSelected",
    color: "#058976",
  },
  [HOLDING_REPORT_STATUS.COMMITTED]: {
    id: 4,
    value: HOLDING_REPORT_STATUS.COMMITTED,
    text: "advisor.holdingReports.reportStatuses.committed",
    color: "#058976",
  },
  [HOLDING_REPORT_STATUS.PROCESSING]: {
    id: 5,
    value: HOLDING_REPORT_STATUS.PROCESSING,
    text: "advisor.holdingReports.reportStatuses.processing",
    color: "#058976",
  },
  [HOLDING_REPORT_STATUS.COMPLETED]: {
    id: 6,
    value: HOLDING_REPORT_STATUS.COMPLETED,
    text: "advisor.holdingReports.reportStatuses.completed",
    color: "#3C3CE5",
  },
  [HOLDING_REPORT_STATUS.ERROR]: {
    id: 7,
    value: HOLDING_REPORT_STATUS.ERROR,
    text: "advisor.holdingReports.reportStatuses.error",
    color: "#E53C3C",
  },
};

export const SUPER_ADMIN_ROLE = "admin";
export const ORG_ADMIN_ROLE = "org_admin";
export const ORG_ADVISOR_ROLE = "org_advisor";
export const ORG_CLIENT_ROLE = "org_client";

export const USER_ROLES = [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE, ORG_ADVISOR_ROLE, ORG_CLIENT_ROLE];

export const PERMISSIONS = {
  CLIENTS: {
    MANAGE: "PERMISSIONS.CLIENTS.MANAGE",
    CLEAR_CREDENTIALS: "PERMISSIONS.CLIENTS.CLEAR_CREDENTIALS",
    RISK_DETAILS: "PERMISSIONS.CLIENTS.RISK_DETAILS",
  },
  USERS: {
    MANAGE: "PERMISSIONS.USERS.MANAGE",
    VIEW: "PERMISSIONS.USERS.VIEW",
  },
  SETTINGS_NJORDA: {
    VIEW: "PERMISSIONS.SETTINGS_NJORDA.VIEW",
  },
  APP_SETTINGS: {
    VIEW: "PERMISSIONS.APP_SETTINGS.VIEW",
  },
  SETTINGS: {
    VIEW: "PERMISSIONS.SETTINGS.VIEW",
  },
  INTEGRATIONS: {
    VIEW: "PERMISSIONS.INTEGRATIONS.VIEW",
  },
  ASSET_LIST_RULES: {
    VIEW: "PERMISSIONS.ASSET_LIST_RULES.VIEW",
  },
  CALCULATION_PARAMETERS: {
    VIEW: "PERMISSIONS.CALCULATION_PARAMETERS.VIEW",
  },
  ASSETS: {
    VIEW: "PERMISSIONS.ASSETS.VIEW",
  },
  ASSETS_CLASSES: {
    VIEW: "PERMISSIONS.ASSETS_CLASSES.VIEW",
  },
  ASSETS_ALLOCATIONS: {
    VIEW: "PERMISSIONS.ASSETS_ALLOCATIONS.VIEW",
  },
  BENCHMARKS: {
    VIEW: "PERMISSIONS.BENCHMARKS.VIEW",
  },
  PORTFOLIO_TEMPLATES: {
    VIEW: "PERMISSIONS.PORTFOLIO_TEMPLATES.VIEW",
    MANAGE: "PERMISSIONS.PORTFOLIO_TEMPLATES.MANAGE",
  },
  ANALYTICS_REPORTS: {
    VIEW: "PERMISSIONS.ANALYTICS_REPORTS.VIEW",
  },
  PORTFOLIO_BUILDER: {
    VIEW: "PERMISSIONS.PORTFOLIO_BUILDER.VIEW",
  },
};

export const USER_PERMISSIONS = {
  [PERMISSIONS.CLIENTS.MANAGE]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE, ORG_ADVISOR_ROLE],
  [PERMISSIONS.CLIENTS.CLEAR_CREDENTIALS]: [SUPER_ADMIN_ROLE],
  [PERMISSIONS.CLIENTS.RISK_DETAILS]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.USERS.MANAGE]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.USERS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.SETTINGS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.INTEGRATIONS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.CALCULATION_PARAMETERS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.ASSET_LIST_RULES.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.ASSETS_CLASSES.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.SETTINGS_NJORDA.VIEW]: [SUPER_ADMIN_ROLE],
  [PERMISSIONS.APP_SETTINGS.VIEW]: [SUPER_ADMIN_ROLE],
  [PERMISSIONS.ASSETS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.ASSETS_ALLOCATIONS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.BENCHMARKS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.PORTFOLIO_TEMPLATES.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE, ORG_ADVISOR_ROLE],
  [PERMISSIONS.PORTFOLIO_TEMPLATES.MANAGE]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.ANALYTICS_REPORTS.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE],
  [PERMISSIONS.PORTFOLIO_BUILDER.VIEW]: [SUPER_ADMIN_ROLE, ORG_ADMIN_ROLE, ORG_ADVISOR_ROLE],
};

export enum EXPORT_FORMATS {
  CSV = "csv",
  XLSX = "xlsx",
}

export enum ACTION_TYPE {
  VIEW = "VIEW",
  DELETE = "DELETE",
  EXPORT = "EXPORT",
  EDIT = "EDIT",
  EXPORT_CSV = "EXPORT.CSV",
  EXPORT_XLSX = "EXPORT.XLSX",
  OPEN_LINK = "SHARE_OPEN_LINK",
  COPY_LINK = "SHARE_COPY_LINK",
  OPEN_IN_PB = "OPEN_IN_PB",
  CLEAR_CREDS = "CLEAR_CREDS",
  CREATE_PORTFOLIO = "CREATE_PORTFOLIO",
  SHARE = "SHARE",
  COMPARE = "COMPARE",
}

export enum ACTION_FORMAT {
  BUTTON = "BUTTON",
  BUTTON_MENU = "BUTTON_MENU",
}

export const COMMON_LIST_ACTIONS = {
  VIEW: "view",
  DELETE: "delete",
};

export const ADVISOR_ACTIONS = {
  ...COMMON_LIST_ACTIONS,
  EXPORT: {
    CSV: "export.csv",
    XLSX: "export.xlsx",
  },
};

export const ANALYTICS_REPORT_REQUEST_TIMEOUT = 1500;

export const ANALYTICS_REPORT_CALCULATE_STATUSES = {
  DONE: "Unsaved",
  PENDING: "Processing",
  ERROR: "Error",
};

export const ANALYTICS_REPORTS_COLUMNS_TYPES = {
  STRING: "String",
  PRICE: "Price",
  PERCENTAGE: "Percentage",
};

export const ANALYTICS_REPORTS_FILTERS_CHOISES = [
  {
    id: "eq",
    name: "equal",
    isValueNeeded: true,
  },
  {
    id: "lt",
    name: "less-than",
    isValueNeeded: true,
  },
  {
    id: "gt",
    name: "greater-than",
    isValueNeeded: true,
  },
  {
    id: "contain",
    name: "contain",
    isValueNeeded: true,
  },
  {
    id: "empty",
    name: "is-empty",
    isValueNeeded: false,
  },
  {
    id: "not-empty",
    name: "is-not-empty",
    isValueNeeded: false,
  },
  {
    id: "error",
    name: "is-error",
    isValueNeeded: false,
  },
  {
    id: "not-error",
    name: "is-not-error",
    isValueNeeded: false,
  },
];

export const ANALYTICS_REPORTS_GROUPINGS = {
  USER: "user",
};

export enum ANALYTICS_REPORTS_CHART_TYPES {
  BAR = "bar",
  SCATTER = "scatter",
}

export const SHARE_OPEN_LINK_ACTION = {
  label: "advisor.clients.info.kebabMenu.openLink",
  icon: "n:open-link",
  type: ACTION_TYPE.OPEN_LINK,
};

export const SHARE_COPY_LINK_ACTION = {
  label: "advisor.clients.info.kebabMenu.copyLink",
  icon: "n:copy-link",
  type: ACTION_TYPE.COPY_LINK,
};

export const CLEAR_CREDENTIALS_ACTION = {
  label: "advisor.clients.info.action.clear-connection-credentials",
  icon: "n:unlink",
  type: ACTION_TYPE.CLEAR_CREDS,
};

export const CREATE_PORTFOLIO_PROPOSAL_ACTION = {
  label: "advisor.clients.create-portfolio",
  icon: "n:portfolio-outline",
  type: ACTION_TYPE.CREATE_PORTFOLIO,
};

export const SHARE_LINK_ACTIONS = [SHARE_COPY_LINK_ACTION, SHARE_OPEN_LINK_ACTION];

export const TOOLTIP_TIMEOUT = 2000;

export const PORTFOLIO_BUILDER_MODE_FULL = "normal";
export const PORTFOLIO_BUILDER_MODE_SIMPLE = "simplified";

export type TAssetListType = "portfolio_builder" | "asset_class";

export const ASSET_LIST_TYPES: Record<string, TAssetListType> = {
  PORTFOLIO_BUILDER: "portfolio_builder",
  ASSET_CLASS: "asset_class",
};

export const REPORT_ENABLE_MANUAL_ACCOUNTS = [
  { id: "enable_manual_accounts", value: false },
  { id: "disable_manual_accounts", value: true },
];

export const CURRENCY_BRL = {
  name: "BRL",
  symbol: "R$",
};
export const CURRENCY_EUR = {
  name: "EUR",
  symbol: "€",
};
export const CURRENCY_USD = {
  name: "USD",
  symbol: "$",
};
export const CURRENCY_SEK = {
  name: "SEK",
  symbol: "kr",
};
export const CURRENCY_GBP = {
  name: "GBP",
  symbol: "£",
};
export const CURRENCY_GBX = {
  name: "GBX",
  symbol: "p",
};

export const BASE_CURRENCY = [CURRENCY_BRL, CURRENCY_SEK, CURRENCY_EUR, CURRENCY_USD, CURRENCY_GBP, CURRENCY_GBX];

export const BASE_CURRENCY_MAP = {
  BRL: CURRENCY_BRL,
  EUR: CURRENCY_EUR,
  USD: CURRENCY_USD,
  SEK: CURRENCY_SEK,
  GBP: CURRENCY_GBP,
  GBX: CURRENCY_GBX,
};

export type TBaseCurrencyMapKey = keyof typeof BASE_CURRENCY_MAP;

export const REPORT_VIEW = ["simple", "allocations_and_positions", "detailed_view"];

export const COLLECT_IDENTITY = true;
export const FORM_USER_ROLES = [ORG_ADMIN_ROLE, ORG_ADVISOR_ROLE];

export const MAPPING_TYPE_PRIORITY_RANK = {
  ticker: 4,
  asset_list: 3,
  instrument_type: 2,
  default: 1,
};

export const PORTFOLIO_PROPOSAL_MODE = {
  ASSETS_MODE: "ASSETS_MODE",
  ALLOCATION_MODE: "ALLOCATION_MODE",
};
export const UNKNOWN_ASSETS_LIST_ID = "UNKNOWN_ASSETS";
export const PORTFOLIO_PROPOSALS_ASSET_LIST_TYPES_WHITELIST: TAssetListType[] = [ASSET_LIST_TYPES.PORTFOLIO_BUILDER];

export const PORTFOLIO_TEMPLATE_SCOPES = {
  USER: "user",
  ORGANIZATION: "organization_app",
};

export const PORTFOLIO_PROPOSALS_ALLOCATION_ACTIONS = {
  EQUAL_WEIGHT: "equal_weight",
  FIX_TO_100_PERCENT: "fix_to_100",
  CLEAR_ALL: "clear_all",
};

export const PORTFOLIO_PROPOSAL_ACTIONS = {
  ...PORTFOLIO_PROPOSALS_ALLOCATION_ACTIONS,
  COPY_FROM_OTHER: "copy_from_other",
  SHOW_QUANTITIES: "show_quantities",
  SHOW_RISKS: "show_risks",
  OPTIMIZE: "optimize_proposal",
  DUPLICATE: "duplicate",
  DUPLICATE_AND_OPTIMIZE: "duplicate_and_optimize_proposal",
  OPTIMIZE_RETURNS: "optimize_returns",
};

export const DEFAULT_PORTFOLIO_PROPOSAL_VALUE = null;
export const DEFAULT_PORTFOLIO_PROPOSAL_INVESTMENT_PERIOD = 1;

export const PORTFOLIO_PROPOSALS_ASSET_PRICE_HISTORY_RANGE = 3560;

export const PORTFOLIO_PROPOSALS_ASSET_CHART_RANGES = {
  "1m": 30,
  "3m": 90,
  "1y": 365,
  "all-time": 3650,
};

export const PORTFOLIO_PROPOSALS_ASSET_CHART_RANGE_BUTTONS = Object.keys(PORTFOLIO_PROPOSALS_ASSET_CHART_RANGES).map(
  (key) => ({
    slug: key,
    label: `advisor.portfolioProposals.proposalForm.assetsPicker.chartRange.${key}`,
  }),
);

export const PORTFOLIO_BUILDER_EXPORT_FORMATS = {
  PDF: "pdf",
  XLSX: "xlsx",
};
