<template>
  <v-navigation-drawer
    v-model="appDrawer"
    v-model:rail="appDrawerIsMiniVariant"
    app
    color="surface"
    clipped
    height="100%"
    flat
    location="left"
    class="app-sidebar elevation-custom"
    :width="256"
    permanent
  >
    <v-list :key="forceRerender" v-model:selected="selected" nav density="compact" flat class="ma-0 pa-0">
      <!-- <v-list-item-group active-class="secondary--text active-option"> -->
      <!-- Regular menu items -->
      <v-list-item
        v-for="option in sidebarOptions"
        :key="option.id"
        :disabled="option.disabled"
        :to="`${baseUrl}${option.path}`"
        :prepend-icon="option.icon"
        :title="option.name"
        :class="{
          'v-list-item--active': isActiveOption(`${baseUrl}${option.path}`),
        }"
      />
      <!-- Admin menu item subitems -->
      <v-list-group
        v-if="hasAccessToAnyAdminOption"
        :key="renderKey"
        :value="isAdminRoute"
        no-action
        :prepend-icon="adminMenu.icon"
        :class="[
          {
            'active-option': adminOptionSelected,
          },
          'admin-list-group',
        ]"
      >
        <template #activator="{ props }">
          <v-list-item v-bind="props" :title="adminMenu.name" />
        </template>

        <template v-for="suboption in adminOptions" :key="suboption.id">
          <v-list-item v-if="suboption.type === 'divider'" class="divider" disabled>
            <v-list-item-title v-if="suboption.type === 'divider'">
              <v-divider class="ma-0 pa-0" />
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            :to="suboption.path ? `${adminBaseUrl}${suboption.path}` : undefined"
            :title="suboption.name"
            :class="{
              'v-list-item--active': suboption.isFullPathCheck
                ? isActiveOption(`${adminBaseUrl}${suboption.path}`)
                : isActiveAdminOption(`${adminBaseUrl}${suboption.path}`),
            }"
          />
        </template>
      </v-list-group>
      <!-- </v-list-item-group> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useDisplay } from "vuetify";
import { PERMISSIONS } from "~/constants/advisor";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app";
import { debounce } from "lodash";

const { t: $t } = useI18n();
const { drawer, isMiniVariant } = storeToRefs(useAppStore());
const { setDrawer, setIsMiniVariant } = useAppStore();
const { $userRole } = useNuxtApp();
const { lgAndDown } = useDisplay();
const $route = useRoute();
const renderKey = ref(0);

// For instant UI translation
const options = computed(() => [
  {
    id: 1,
    name: $t("advisor.sidebar.analyticsStudio"),
    icon: "n:chart-bar",
    disabled: true,
    hide: true,
    value: 1,
  },
  {
    id: 2,
    name: $t("advisor.sidebar.analyticsReports"),
    icon: "n:analytics-charts",
    path: "/analytics/reports",
    disabled: false,
    hide: !$userRole.hasPermission(PERMISSIONS.ANALYTICS_REPORTS.VIEW),
    value: 2,
  },
  {
    id: 3,
    name: $t("advisor.sidebar.portfolioCompare"),
    icon: "mdi-scale-balance",
    disabled: true,
    hide: true,
    value: 3,
  },
  {
    id: 4,
    name: $t("advisor.sidebar.portfolioBuilder"),
    icon: "n:portfolio-outline",
    path: "/portfolio-builder",
    disabled: false,
    hide: !$userRole.hasPermission(PERMISSIONS.PORTFOLIO_BUILDER.VIEW),
    value: 4,
  },
  {
    id: 5,
    name: $t("advisor.sidebar.clientDashboard"),
    icon: "n:dashboard",
    disabled: true,
    hide: true,
    value: 5,
  },
  {
    id: 6,
    name: $t("advisor.sidebar.instrumentDashboard"),
    icon: "n:dashboard-instruments",
    disabled: true,
    hide: true,
    value: 6,
  },
  {
    id: 7,
    name: $t("advisor.sidebar.clients"),
    icon: "n:clients",
    path: "/clients",
    disabled: false,
    value: 7,
  },
  {
    id: 8,
    name: $t("advisor.sidebar.marketParameters"),
    icon: "n:tune-vertical",
    disabled: true,
    hide: true,
    value: 8,
  },
  {
    id: 9,
    name: $t("advisor.sidebar.modelValidation"),
    icon: "n:checkbox-outline",
    disabled: true,
    hide: true,
    value: 9,
  },
  {
    id: 10,
    name: $t("advisor.sidebar.development"),
    icon: "n:code-braces",
    disabled: true,
    hide: true,
    value: 10,
  },
  {
    id: 11,
    name: $t("advisor.sidebar.admin"),
    icon: "n:cog-outline",
    disabled: true,
    hide: true,
    value: 11,
  },
]);
// For instant UI translation
const adminMenu = computed(() => ({
  name: $t("advisor.sidebar.admin"),
  icon: "n:cog-outline",
  options: [
    {
      id: 11.101,
      name: $t("advisor.sidebar.assets-classes"),
      hide: !$userRole.hasPermission(PERMISSIONS.ASSETS_CLASSES.VIEW),
      path: "/asset-classes",
      value: 110.5,
    },
    {
      id: 11.11,
      name: $t("advisor.sidebar.asset-list-rules"),
      hide: !$userRole.hasPermission(PERMISSIONS.ASSETS.VIEW),
      path: "/asset-class-rules",
      value: 111,
    },
    {
      id: 11.112,
      name: $t("advisor.sidebar.assets"),
      hide: !$userRole.hasPermission(PERMISSIONS.ASSETS.VIEW),
      path: "/product-lists",
      value: 111.5,
    },
    {
      id: 11.2,
      name: $t("advisor.sidebar.assets-allocations"),
      hide: !$userRole.hasPermission(PERMISSIONS.ASSETS_ALLOCATIONS.VIEW),
      path: "/product-list-allocations",
      value: 112,
    },
    {
      id: 11.3,
      name: $t("advisor.sidebar.benchmarks"),
      hide: !$userRole.hasPermission(PERMISSIONS.BENCHMARKS.VIEW),
      path: "/benchmarks",
      value: 113,
    },
    {
      id: 11.31,
      name: $t("advisor.sidebar.portfolio-templates"),
      hide: !$userRole.hasPermission(PERMISSIONS.PORTFOLIO_TEMPLATES.VIEW),
      path: "/portfolio-templates",
      value: 114,
    },
    {
      id: 11.4,
      name: $t("advisor.sidebar.calculationParameters"),
      hide: !$userRole.hasPermission(PERMISSIONS.CALCULATION_PARAMETERS.VIEW),
      path: "/calculations",
      value: 115,
    },
    {
      id: 11.5,
      type: "divider",
    },
    {
      id: 11.6,
      name: $t("advisor.sidebar.integrations"),
      hide: !$userRole.hasPermission(PERMISSIONS.INTEGRATIONS.VIEW),
      path: "/integrations",
      value: 116,
    },
    {
      id: 11.7,
      name: $t("advisor.sidebar.settingsNjordaAdmin"),
      hide: !$userRole.hasPermission(PERMISSIONS.SETTINGS_NJORDA.VIEW),
      path: "/njorda",
      value: 117,
    },
    {
      id: 11.8,
      name: $t("advisor.sidebar.settingsClientApp"),
      hide: !$userRole.hasPermission(PERMISSIONS.APP_SETTINGS.VIEW),
      path: "/njorda/app",
      value: 118,
    },
    {
      id: 11.9,
      name: $t("advisor.sidebar.settings"),
      hide: !$userRole.hasPermission(PERMISSIONS.SETTINGS.VIEW),
      path: "/settings",
      value: 119,
    },
    {
      id: 11.11,
      name: $t("advisor.sidebar.users"),
      hide: !$userRole.hasPermission(PERMISSIONS.USERS.VIEW),
      path: "/users",
      isFullPathCheck: true,
      value: 120,
    },
  ],
}));

const selected = ref([]);
const forceRerender = ref(0);

const orgId = computed(() => $route.params.org_id);
const baseUrl = computed(() => `/advisor/${orgId.value}`);
const adminBaseUrl = computed(() => `${baseUrl.value}/manage`);
const isAdminRoute = computed(() => {
  return (($route.name as string) ?? "")?.startsWith("advisor-org_id-manage");
});
const sidebarOptions = computed(() => options.value.filter((option) => !option.hide));
const adminOptions = computed(() => adminMenu.value.options.filter((option) => !option.hide));
const hasAccessToAnyAdminOption = computed(() => {
  return adminOptions.value.filter(({ type }) => type !== "divider").length > 0;
});
const adminOptionSelected = computed(() => {
  return adminOptions.value.some((option) =>
    option.isFullPathCheck
      ? isActiveOption(`${adminBaseUrl.value}${option.path}`)
      : isActiveAdminOption(`${adminBaseUrl.value}${option.path}`),
  );
});

const appDrawer = computed({
  get: () => {
    return drawer.value;
  },
  set: (val) => {
    setDrawer(val);
  },
});
const appDrawerIsMiniVariant = computed({
  get: () => isMiniVariant.value,
  set: (val) => {
    setIsMiniVariant(val);
  },
});

const handleResizeDebounced = debounce(handleResize, 500);

watch(
  lgAndDown,
  () => {
    handleResizeDebounced();
  },
  {
    immediate: true,
  },
);

watch(orgId, () => {
  forceRerender.value++;
});

watch($route, () => {
  renderKey.value = renderKey.value + 1;
  handleResizeDebounced();
});

function isActiveOption(path: string) {
  return $route.path.startsWith(path);
}
function isActiveAdminOption(path: string) {
  return $route.path === path;
}
function handleResize() {
  if (lgAndDown.value) {
    setIsMiniVariant(true);
  } else {
    setIsMiniVariant(false);
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-list-item--active) {
  border-left: 4px solid rgb(var(--v-theme-secondary-base)) !important;
  border-radius: 0;
  padding-left: 12px !important;
  background-color: rgb(var(--v-theme-secondary-lighten-3)) !important;
  color: rgb(var(--v-theme-secondary-base)) !important;
}

.app-sidebar :deep(*) {
  .v-list-item {
    padding-left: 16px;
    padding-right: 16px;

    &--active {
      padding-left: 12px;
    }

    .v-list-item__icon:first-child {
      margin-right: 24px !important;
    }

    &:hover {
      background: rgb(var(--v-theme-gray-lighten-4)) !important;
    }

    &:active {
      background: rgb(var(--v-theme-secondary-lighten-1)) !important;
      color: rgb(var(--v-theme-secondary-base));
    }
  }

  .v-list-group__header {
    &:hover {
      background: rgb(var(--v-theme-gray-lighten-4)) !important;
    }

    &:active {
      background: rgb(var(--v-theme-secondary-lighten-1)) !important;
      color: rgb(var(--v-theme-secondary-base));
    }
  }

  .v-list-item.divider {
    height: 1px !important;
    min-height: 1px !important;
    background-color: white !important;
  }
  .admin-list-group {
    .v-list-group__header {
      padding-left: 16px;
      padding-right: 16px;

      .v-list-item__icon:first-child {
        margin-right: 24px !important;
      }
    }
    &.v-list-group--active {
      .v-list-item__prepend {
        i::before,
        i::after {
          color: rgb(var(--v-theme-secondary-base));
        }
      }
      .v-list-group__header {
        border-left: 4px solid rgb(var(--v-theme-secondary-base));
        border-radius: 0;
        color: rgb(var(--v-theme-secondary-base));
        padding-left: 12px !important;
      }
    }

    :deep(.v-list-item--active) {
      border: 0;
    }

    .v-list-group__items {
      .v-list-item--link {
        padding-left: 72px !important;
      }

      :deep(.v-list-item--active) {
        .v-list-item-title {
          color: rgb(var(--v-theme-secondary-base));
        }

        .v-list-item__prepend {
          i::before,
          i::after {
            color: rgb(var(--v-theme-secondary-base));
          }
        }
      }
    }
  }
}

.organization-select :deep(.primary--text) {
  color: rgb(var(--v-theme-gray-ligthen-3));
  caret-color: rgb(var(--v-theme-gray-ligthen-3));
}

.organization-select-wrapper {
  min-height: 20px;
}
</style>
