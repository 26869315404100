/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema = {
    asset_category_groups?: Array<string> | null;
    asset_category_groups_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_category_groups_operator;
    asset_category_names?: Array<string> | null;
    asset_category_names_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_category_names_operator;
    asset_currencies?: Array<'AED' | 'ANG' | 'AUD' | 'BGN' | 'BMD' | 'BRL' | 'BWP' | 'CAD' | 'CHF' | 'CLP' | 'CNH' | 'CNY' | 'COP' | 'CZK' | 'DKK' | 'EEK' | 'EUR' | 'GBP' | 'GBX' | 'HKD' | 'HUF' | 'IDR' | 'ILS' | 'INR' | 'ISK' | 'JPY' | 'KRW' | 'KWD' | 'KYD' | 'KZT' | 'LSL' | 'LTL' | 'MOP' | 'MTL' | 'MXN' | 'MYR' | 'NAD' | 'NOK' | 'NZD' | 'OMR' | 'PKR' | 'PLN' | 'RMB' | 'RON' | 'RUB' | 'SEK' | 'SGD' | 'THB' | 'TRY' | 'TWD' | 'USD' | 'VND' | 'ZAR'> | null;
    asset_currencies_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_currencies_operator;
    asset_isin_countries?: Array<'GB' | 'DE' | 'CH' | 'AT' | 'PT' | 'ES' | 'FR' | 'IT' | 'NL' | 'BE' | 'AD' | 'CY' | 'DK' | 'FI' | 'GI' | 'GR' | 'HU' | 'IS' | 'IE' | 'LV' | 'LT' | 'LI' | 'NO' | 'PL' | 'RO' | 'BG' | 'SM' | 'SK' | 'SI' | 'HR' | 'CZ' | 'MC' | 'MT' | 'AR' | 'AZ' | 'BZ' | 'BM' | 'BO' | 'BA' | 'BR' | 'KH' | 'KY' | 'CL' | 'CN' | 'CO' | 'CR' | 'DO' | 'EC' | 'EG' | 'IN' | 'ID' | 'IL' | 'KE' | 'KR' | 'KW' | 'LB' | 'MY' | 'MU' | 'MX' | 'OM' | 'PA' | 'PY' | 'PE' | 'PH' | 'PR' | 'QA' | 'RU' | 'SA' | 'ZA' | 'TH' | 'TR' | 'UA' | 'AE' | 'VN' | 'VE' | 'SE' | 'US' | 'CA' | 'JP' | 'AU' | 'NZ' | 'SG' | 'HK' | 'TW' | 'EE' | 'LU' | 'MO' | 'MK'> | null;
    asset_isin_countries_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_isin_countries_operator;
    asset_list: string;
    asset_markets?: Array<'global' | 'north_america' | 'latin_america' | 'asia_pacific' | 'europe' | 'mid_east_africa' | 'russia' | 'sweden' | 'other' | 'no_service' | 'no_info' | 'unmapped' | 'unsupported_ins_type'> | null;
    asset_markets_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_markets_operator;
    asset_sectors?: Array<'basic_materials' | 'communication_services' | 'consumer_cyclical' | 'consumer_defensive' | 'energy' | 'financial_services' | 'fx' | 'healthcare' | 'industrials' | 'real_estate' | 'technology' | 'telecommunications' | 'utilities' | 'other' | 'unmapped' | 'no_info'> | null;
    asset_sectors_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_sectors_operator;
    asset_svp_types?: Array<string> | null;
    asset_svp_types_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_svp_types_operator;
    asset_types?: Array<'BOND' | 'CERT' | 'CDS' | 'CURRENCY' | 'ETF' | 'ETN' | 'FUTURE' | 'INDEX' | 'MFUND' | 'NOTE' | 'OPTION' | 'SERVICE' | 'STOCK' | 'SP' | 'WARRANT' | 'NONE'> | null;
    asset_types_operator?: njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema.asset_types_operator;
    id?: string;
    priority: number;
};
export namespace njorda_bps_b2b_v1_schemas_portfolio_builder_asset_list_filter_UpdateAssetListFilterSchema {
    export enum asset_category_groups_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_category_names_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_currencies_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_isin_countries_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_markets_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_sectors_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_svp_types_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
    export enum asset_types_operator {
        IN = 'IN',
        NOT_IN = 'NOT_IN',
    }
}

