<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="10" viewBox="0 0 19 10" fill="none">
    <path d="M6.56152 4.90088H11.5426" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0981 9.80182H13.2027C15.9094 9.80182 18.1036 7.60761 18.1036 4.90091C18.1036 2.19421 15.9094 0 13.2027 0H10.0981V1.5H13.2027C15.081 1.5 16.6036 3.02264 16.6036 4.90091C16.6036 6.77918 15.081 8.30182 13.2027 8.30182H10.0981V9.80182ZM8.59814 1.5H4.90091C3.02264 1.5 1.5 3.02264 1.5 4.90091C1.5 6.77918 3.02264 8.30182 4.90091 8.30182H8.59814V9.80182H4.90091C2.19421 9.80182 0 7.60761 0 4.90091C0 2.19421 2.19421 0 4.90091 0H8.59814V1.5Z"
      fill="currentColor"
    />
  </svg>
</template>
