
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexaGGIfcOD1sMeta } from "/app/pages/advisor/[org_id]/analytics/index.vue?macro=true";
import { default as _91report_id_93imKKw6MlO3Meta } from "/app/pages/advisor/[org_id]/analytics/reports/[report_id].vue?macro=true";
import { default as indexPepDxUUfQ8Meta } from "/app/pages/advisor/[org_id]/analytics/reports/index.vue?macro=true";
import { default as new2fT1k7vfrNMeta } from "/app/pages/advisor/[org_id]/analytics/reports/new.vue?macro=true";
import { default as indexIl8rbnLSOxMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/index.vue?macro=true";
import { default as _91position_id_93FOopIWTmNlMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/position/[position_id].vue?macro=true";
import { default as editwLr4RGb8rdMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/edit.vue?macro=true";
import { default as indexzfnJ968eYDMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/index.vue?macro=true";
import { default as _91report_id_93p21hMc7h3oMeta } from "/app/pages/advisor/[org_id]/clients/[client_id]/report/[report_id].vue?macro=true";
import { default as indexEDZliObGaUMeta } from "/app/pages/advisor/[org_id]/clients/index.vue?macro=true";
import { default as newtsViPetB1DMeta } from "/app/pages/advisor/[org_id]/clients/new.vue?macro=true";
import { default as index5NWNbzoyuFMeta } from "/app/pages/advisor/[org_id]/index.vue?macro=true";
import { default as login29UAlSbCUyMeta } from "/app/pages/advisor/[org_id]/login.vue?macro=true";
import { default as indexoXUsCyXGrGMeta } from "/app/pages/advisor/[org_id]/manage/asset-class-rules/index.vue?macro=true";
import { default as indexFRIOCBkiWAMeta } from "/app/pages/advisor/[org_id]/manage/asset-classes/index.vue?macro=true";
import { default as index72Kvog42IlMeta } from "/app/pages/advisor/[org_id]/manage/benchmarks/index.vue?macro=true";
import { default as index6P9E26KVxTMeta } from "/app/pages/advisor/[org_id]/manage/calculations/index.vue?macro=true";
import { default as indexX229izAP6wMeta } from "/app/pages/advisor/[org_id]/manage/integrations/index.vue?macro=true";
import { default as appWlgEhoEDB1Meta } from "/app/pages/advisor/[org_id]/manage/njorda/app.vue?macro=true";
import { default as indexysYJR5TwL9Meta } from "/app/pages/advisor/[org_id]/manage/njorda/index.vue?macro=true";
import { default as indexXrSSDvt949Meta } from "/app/pages/advisor/[org_id]/manage/portfolio-templates/index.vue?macro=true";
import { default as indexAPeQZ1GBLaMeta } from "/app/pages/advisor/[org_id]/manage/product-list-allocations/index.vue?macro=true";
import { default as index7ClPtIKkmeMeta } from "/app/pages/advisor/[org_id]/manage/product-lists/index.vue?macro=true";
import { default as indexEUv3fJDvOpMeta } from "/app/pages/advisor/[org_id]/manage/settings/index.vue?macro=true";
import { default as edita64SYnXpCdMeta } from "/app/pages/advisor/[org_id]/manage/users/[user_id]/edit.vue?macro=true";
import { default as indexpiMT9aotrdMeta } from "/app/pages/advisor/[org_id]/manage/users/[user_id]/index.vue?macro=true";
import { default as indexiQ6HpY2naZMeta } from "/app/pages/advisor/[org_id]/manage/users/index.vue?macro=true";
import { default as newARJ8aaPDQzMeta } from "/app/pages/advisor/[org_id]/manage/users/new.vue?macro=true";
import { default as _91proposal_id_934a0zVWMUa2Meta } from "/app/pages/advisor/[org_id]/portfolio-builder/[proposal_id].vue?macro=true";
import { default as indexrmiErTAMOlMeta } from "/app/pages/advisor/[org_id]/portfolio-builder/index.vue?macro=true";
import { default as new1lGHvpwiJiMeta } from "/app/pages/advisor/[org_id]/portfolio-builder/new.vue?macro=true";
import { default as indexc9j7BWdPWKMeta } from "/app/pages/advisor/index.vue?macro=true";
import { default as bad_45orgtmF1o4i2oLMeta } from "/app/pages/client/[org_id]/error/bad-org.vue?macro=true";
import { default as _91id_93dJKlFEED6CMeta } from "/app/pages/client/[org_id]/portfolio-report/[id].vue?macro=true";
import { default as _91id_939wWOKV0f5vMeta } from "/app/pages/client/[org_id]/portfolio-sharing/start/[id].vue?macro=true";
import { default as _91id_93RYZGFjwplXMeta } from "/app/pages/client/[org_id]/portfolio-sharing/step-1/[id].vue?macro=true";
import { default as _91id_930d1VMAJpriMeta } from "/app/pages/client/[org_id]/portfolio-sharing/step-2/[id].vue?macro=true";
import { default as report_45doneIQ86bmiIKsMeta } from "/app/pages/client/[org_id]/report-done.vue?macro=true";
import { default as start_45holdings_45collectionv7a5Xu7VefMeta } from "/app/pages/client/[org_id]/start-holdings-collection.vue?macro=true";
import { default as terms_45and_45conditionsT78E48Ow4mMeta } from "/app/pages/client/[org_id]/terms-and-conditions.vue?macro=true";
import { default as termsoJv3ve1oW0Meta } from "/app/pages/client/[org_id]/terms.vue?macro=true";
import { default as adminmBanvygEjqMeta } from "/app/pages/developer/api/admin.vue?macro=true";
import { default as appEFwHJ1Az1GMeta } from "/app/pages/developer/api/app.vue?macro=true";
import { default as b2b_45v2e21keQG87sMeta } from "/app/pages/developer/api/b2b-v2.vue?macro=true";
import { default as b2bQkhyRTypBDMeta } from "/app/pages/developer/api/b2b.vue?macro=true";
import { default as connectFL9mu0MsyJMeta } from "/app/pages/developer/api/connect.vue?macro=true";
import { default as client_45detailsZuFr1UMBGpMeta } from "/app/pages/developer/client-details.vue?macro=true";
import { default as component_45gallery0FoSrOoCW6Meta } from "/app/pages/developer/component-gallery.vue?macro=true";
import { default as embed1owv5znqh1Meta } from "/app/pages/developer/embed.vue?macro=true";
import { default as holdings_45reportrHmWRcTSPmMeta } from "/app/pages/developer/holdings-report.vue?macro=true";
import { default as indextZfnwGbNcyMeta } from "/app/pages/developer/index.vue?macro=true";
import { default as instrument_45details8CJsZ2ms31Meta } from "/app/pages/developer/instrument-details.vue?macro=true";
import { default as instrument_45issuesZXZKn5asHIMeta } from "/app/pages/developer/instrument-issues.vue?macro=true";
import { default as instrument_45listkQE7OsCZpjMeta } from "/app/pages/developer/instrument-list.vue?macro=true";
import { default as integration_45tests_45reportGQ9pkq2cc2Meta } from "/app/pages/developer/integration-tests-report.vue?macro=true";
import { default as integration_45testsASi8bSw4U6Meta } from "/app/pages/developer/integration-tests.vue?macro=true";
import { default as organizations2K1OpqDh7mMeta } from "/app/pages/developer/organizations.vue?macro=true";
import { default as portfolio_45datawyfXaksydrMeta } from "/app/pages/developer/portfolio-data.vue?macro=true";
import { default as trigger_45taskiTmFZeIys4Meta } from "/app/pages/developer/trigger-task.vue?macro=true";
import { default as user_45detailsP2UZC4k3qRMeta } from "/app/pages/developer/user-details.vue?macro=true";
import { default as user_45listYUTmANb148Meta } from "/app/pages/developer/user-list.vue?macro=true";
import { default as apiZUoiCDVGCNMeta } from "/app/pages/docs/api.vue?macro=true";
import { default as indexlQLb2XkosQMeta } from "/app/pages/embed/advisor/[org_id]/portfolio-builder/index.vue?macro=true";
import { default as pdfMvclV2CkmCMeta } from "/app/pages/embed/advisor/[org_id]/portfolio-builder/pdf.vue?macro=true";
import { default as forget_45passwordEVWK44q6hlMeta } from "/app/pages/forget-password.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as bad_45orgC77qNgWm0ZMeta } from "/app/pages/product-demo/[org_id]/error/bad-org.vue?macro=true";
import { default as _91id_93VRbgE2sfW9Meta } from "/app/pages/product-demo/[org_id]/mb/report/[id].vue?macro=true";
import { default as _91id_930IwM01MqTQMeta } from "/app/pages/product-demo/[org_id]/mb/review/[id].vue?macro=true";
import { default as accountQG4Z5HQuyZMeta } from "/app/pages/product-demo/[org_id]/sp/investment/account.vue?macro=true";
import { default as indexWpkPMeup3XMeta } from "/app/pages/product-demo/[org_id]/sp/investment/index.vue?macro=true";
import { default as overviewsZItjFsfkUMeta } from "/app/pages/product-demo/[org_id]/sp/investment/overview.vue?macro=true";
import { default as positionQWu7Vq3yvNMeta } from "/app/pages/product-demo/[org_id]/sp/investment/position.vue?macro=true";
import { default as _91uuid_93ixfYQmY4daMeta } from "/app/pages/reset-password/[uuid].vue?macro=true";
import { default as test_45linksoPVN4KYRyHMeta } from "/app/pages/test-links.vue?macro=true";
export default [
  {
    name: "advisor-org_id-analytics",
    path: "/advisor/:org_id()/analytics",
    meta: indexaGGIfcOD1sMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/index.vue")
  },
  {
    name: "advisor-org_id-analytics-reports-report_id",
    path: "/advisor/:org_id()/analytics/reports/:report_id()",
    meta: _91report_id_93imKKw6MlO3Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/[report_id].vue")
  },
  {
    name: "advisor-org_id-analytics-reports",
    path: "/advisor/:org_id()/analytics/reports",
    meta: indexPepDxUUfQ8Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/index.vue")
  },
  {
    name: "advisor-org_id-analytics-reports-new",
    path: "/advisor/:org_id()/analytics/reports/new",
    meta: new2fT1k7vfrNMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/analytics/reports/new.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-account-account_id",
    path: "/advisor/:org_id()/clients/:client_id()/account/:account_id()",
    meta: indexIl8rbnLSOxMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-account-account_id-position-position_id",
    path: "/advisor/:org_id()/clients/:client_id()/account/:account_id()/position/:position_id()",
    meta: _91position_id_93FOopIWTmNlMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/account/[account_id]/position/[position_id].vue")
  },
  {
    name: "advisor-org_id-clients-client_id-edit",
    path: "/advisor/:org_id()/clients/:client_id()/edit",
    meta: editwLr4RGb8rdMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/edit.vue")
  },
  {
    name: "advisor-org_id-clients-client_id",
    path: "/advisor/:org_id()/clients/:client_id()",
    meta: indexzfnJ968eYDMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/index.vue")
  },
  {
    name: "advisor-org_id-clients-client_id-report-report_id",
    path: "/advisor/:org_id()/clients/:client_id()/report/:report_id()",
    meta: _91report_id_93p21hMc7h3oMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/[client_id]/report/[report_id].vue")
  },
  {
    name: "advisor-org_id-clients",
    path: "/advisor/:org_id()/clients",
    meta: indexEDZliObGaUMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/index.vue")
  },
  {
    name: "advisor-org_id-clients-new",
    path: "/advisor/:org_id()/clients/new",
    meta: newtsViPetB1DMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/clients/new.vue")
  },
  {
    name: "advisor-org_id",
    path: "/advisor/:org_id()",
    meta: index5NWNbzoyuFMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/index.vue")
  },
  {
    name: "advisor-org_id-login",
    path: "/advisor/:org_id()/login",
    meta: login29UAlSbCUyMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/login.vue")
  },
  {
    name: "advisor-org_id-manage-asset-class-rules",
    path: "/advisor/:org_id()/manage/asset-class-rules",
    meta: indexoXUsCyXGrGMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/asset-class-rules/index.vue")
  },
  {
    name: "advisor-org_id-manage-asset-classes",
    path: "/advisor/:org_id()/manage/asset-classes",
    meta: indexFRIOCBkiWAMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/asset-classes/index.vue")
  },
  {
    name: "advisor-org_id-manage-benchmarks",
    path: "/advisor/:org_id()/manage/benchmarks",
    meta: index72Kvog42IlMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/benchmarks/index.vue")
  },
  {
    name: "advisor-org_id-manage-calculations",
    path: "/advisor/:org_id()/manage/calculations",
    meta: index6P9E26KVxTMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/calculations/index.vue")
  },
  {
    name: "advisor-org_id-manage-integrations",
    path: "/advisor/:org_id()/manage/integrations",
    meta: indexX229izAP6wMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/integrations/index.vue")
  },
  {
    name: "advisor-org_id-manage-njorda-app",
    path: "/advisor/:org_id()/manage/njorda/app",
    meta: appWlgEhoEDB1Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/njorda/app.vue")
  },
  {
    name: "advisor-org_id-manage-njorda",
    path: "/advisor/:org_id()/manage/njorda",
    meta: indexysYJR5TwL9Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/njorda/index.vue")
  },
  {
    name: "advisor-org_id-manage-portfolio-templates",
    path: "/advisor/:org_id()/manage/portfolio-templates",
    meta: indexXrSSDvt949Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/portfolio-templates/index.vue")
  },
  {
    name: "advisor-org_id-manage-product-list-allocations",
    path: "/advisor/:org_id()/manage/product-list-allocations",
    meta: indexAPeQZ1GBLaMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/product-list-allocations/index.vue")
  },
  {
    name: "advisor-org_id-manage-product-lists",
    path: "/advisor/:org_id()/manage/product-lists",
    meta: index7ClPtIKkmeMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/product-lists/index.vue")
  },
  {
    name: "advisor-org_id-manage-settings",
    path: "/advisor/:org_id()/manage/settings",
    meta: indexEUv3fJDvOpMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/settings/index.vue")
  },
  {
    name: "advisor-org_id-manage-users-user_id-edit",
    path: "/advisor/:org_id()/manage/users/:user_id()/edit",
    meta: edita64SYnXpCdMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/[user_id]/edit.vue")
  },
  {
    name: "advisor-org_id-manage-users-user_id",
    path: "/advisor/:org_id()/manage/users/:user_id()",
    meta: indexpiMT9aotrdMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/[user_id]/index.vue")
  },
  {
    name: "advisor-org_id-manage-users",
    path: "/advisor/:org_id()/manage/users",
    meta: indexiQ6HpY2naZMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/index.vue")
  },
  {
    name: "advisor-org_id-manage-users-new",
    path: "/advisor/:org_id()/manage/users/new",
    meta: newARJ8aaPDQzMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/manage/users/new.vue")
  },
  {
    name: "advisor-org_id-portfolio-builder-proposal_id",
    path: "/advisor/:org_id()/portfolio-builder/:proposal_id()",
    meta: _91proposal_id_934a0zVWMUa2Meta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/[proposal_id].vue")
  },
  {
    name: "advisor-org_id-portfolio-builder",
    path: "/advisor/:org_id()/portfolio-builder",
    meta: indexrmiErTAMOlMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/index.vue")
  },
  {
    name: "advisor-org_id-portfolio-builder-new",
    path: "/advisor/:org_id()/portfolio-builder/new",
    meta: new1lGHvpwiJiMeta || {},
    component: () => import("/app/pages/advisor/[org_id]/portfolio-builder/new.vue")
  },
  {
    name: "advisor",
    path: "/advisor",
    meta: indexc9j7BWdPWKMeta || {},
    component: () => import("/app/pages/advisor/index.vue")
  },
  {
    name: "client-org_id-error-bad-org",
    path: "/client/:org_id()/error/bad-org",
    meta: bad_45orgtmF1o4i2oLMeta || {},
    component: () => import("/app/pages/client/[org_id]/error/bad-org.vue")
  },
  {
    name: "client-org_id-portfolio-report-id",
    path: "/client/:org_id()/portfolio-report/:id()",
    meta: _91id_93dJKlFEED6CMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-report/[id].vue")
  },
  {
    name: "client-org_id-portfolio-sharing-start-id",
    path: "/client/:org_id()/portfolio-sharing/start/:id()",
    meta: _91id_939wWOKV0f5vMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/start/[id].vue")
  },
  {
    name: "client-org_id-portfolio-sharing-step-1-id",
    path: "/client/:org_id()/portfolio-sharing/step-1/:id()",
    meta: _91id_93RYZGFjwplXMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/step-1/[id].vue")
  },
  {
    name: "client-org_id-portfolio-sharing-step-2-id",
    path: "/client/:org_id()/portfolio-sharing/step-2/:id()",
    meta: _91id_930d1VMAJpriMeta || {},
    component: () => import("/app/pages/client/[org_id]/portfolio-sharing/step-2/[id].vue")
  },
  {
    name: "client-org_id-report-done",
    path: "/client/:org_id()/report-done",
    meta: report_45doneIQ86bmiIKsMeta || {},
    component: () => import("/app/pages/client/[org_id]/report-done.vue")
  },
  {
    name: "client-org_id-start-holdings-collection",
    path: "/client/:org_id()/start-holdings-collection",
    meta: start_45holdings_45collectionv7a5Xu7VefMeta || {},
    component: () => import("/app/pages/client/[org_id]/start-holdings-collection.vue")
  },
  {
    name: "client-org_id-terms-and-conditions",
    path: "/client/:org_id()/terms-and-conditions",
    meta: terms_45and_45conditionsT78E48Ow4mMeta || {},
    component: () => import("/app/pages/client/[org_id]/terms-and-conditions.vue")
  },
  {
    name: "client-org_id-terms",
    path: "/client/:org_id()/terms",
    meta: termsoJv3ve1oW0Meta || {},
    component: () => import("/app/pages/client/[org_id]/terms.vue")
  },
  {
    name: "developer-api-admin",
    path: "/developer/api/admin",
    meta: adminmBanvygEjqMeta || {},
    component: () => import("/app/pages/developer/api/admin.vue")
  },
  {
    name: "developer-api-app",
    path: "/developer/api/app",
    meta: appEFwHJ1Az1GMeta || {},
    component: () => import("/app/pages/developer/api/app.vue")
  },
  {
    name: "developer-api-b2b-v2",
    path: "/developer/api/b2b-v2",
    meta: b2b_45v2e21keQG87sMeta || {},
    component: () => import("/app/pages/developer/api/b2b-v2.vue")
  },
  {
    name: "developer-api-b2b",
    path: "/developer/api/b2b",
    meta: b2bQkhyRTypBDMeta || {},
    component: () => import("/app/pages/developer/api/b2b.vue")
  },
  {
    name: "developer-api-connect",
    path: "/developer/api/connect",
    meta: connectFL9mu0MsyJMeta || {},
    component: () => import("/app/pages/developer/api/connect.vue")
  },
  {
    name: "developer-client-details",
    path: "/developer/client-details",
    meta: client_45detailsZuFr1UMBGpMeta || {},
    component: () => import("/app/pages/developer/client-details.vue")
  },
  {
    name: "developer-component-gallery",
    path: "/developer/component-gallery",
    meta: component_45gallery0FoSrOoCW6Meta || {},
    component: () => import("/app/pages/developer/component-gallery.vue")
  },
  {
    name: "developer-embed",
    path: "/developer/embed",
    meta: embed1owv5znqh1Meta || {},
    component: () => import("/app/pages/developer/embed.vue")
  },
  {
    name: "developer-holdings-report",
    path: "/developer/holdings-report",
    meta: holdings_45reportrHmWRcTSPmMeta || {},
    component: () => import("/app/pages/developer/holdings-report.vue")
  },
  {
    name: "developer",
    path: "/developer",
    meta: indextZfnwGbNcyMeta || {},
    component: () => import("/app/pages/developer/index.vue")
  },
  {
    name: "developer-instrument-details",
    path: "/developer/instrument-details",
    meta: instrument_45details8CJsZ2ms31Meta || {},
    component: () => import("/app/pages/developer/instrument-details.vue")
  },
  {
    name: "developer-instrument-issues",
    path: "/developer/instrument-issues",
    meta: instrument_45issuesZXZKn5asHIMeta || {},
    component: () => import("/app/pages/developer/instrument-issues.vue")
  },
  {
    name: "developer-instrument-list",
    path: "/developer/instrument-list",
    meta: instrument_45listkQE7OsCZpjMeta || {},
    component: () => import("/app/pages/developer/instrument-list.vue")
  },
  {
    name: "developer-integration-tests-report",
    path: "/developer/integration-tests-report",
    meta: integration_45tests_45reportGQ9pkq2cc2Meta || {},
    component: () => import("/app/pages/developer/integration-tests-report.vue")
  },
  {
    name: "developer-integration-tests",
    path: "/developer/integration-tests",
    meta: integration_45testsASi8bSw4U6Meta || {},
    component: () => import("/app/pages/developer/integration-tests.vue")
  },
  {
    name: "developer-organizations",
    path: "/developer/organizations",
    meta: organizations2K1OpqDh7mMeta || {},
    component: () => import("/app/pages/developer/organizations.vue")
  },
  {
    name: "developer-portfolio-data",
    path: "/developer/portfolio-data",
    meta: portfolio_45datawyfXaksydrMeta || {},
    component: () => import("/app/pages/developer/portfolio-data.vue")
  },
  {
    name: "developer-trigger-task",
    path: "/developer/trigger-task",
    meta: trigger_45taskiTmFZeIys4Meta || {},
    component: () => import("/app/pages/developer/trigger-task.vue")
  },
  {
    name: "developer-user-details",
    path: "/developer/user-details",
    meta: user_45detailsP2UZC4k3qRMeta || {},
    component: () => import("/app/pages/developer/user-details.vue")
  },
  {
    name: "developer-user-list",
    path: "/developer/user-list",
    meta: user_45listYUTmANb148Meta || {},
    component: () => import("/app/pages/developer/user-list.vue")
  },
  {
    name: "docs-api",
    path: "/docs/api",
    meta: apiZUoiCDVGCNMeta || {},
    component: () => import("/app/pages/docs/api.vue")
  },
  {
    name: "embed-advisor-org_id-portfolio-builder",
    path: "/embed/advisor/:org_id()/portfolio-builder",
    meta: indexlQLb2XkosQMeta || {},
    component: () => import("/app/pages/embed/advisor/[org_id]/portfolio-builder/index.vue")
  },
  {
    name: "embed-advisor-org_id-portfolio-builder-pdf",
    path: "/embed/advisor/:org_id()/portfolio-builder/pdf",
    meta: pdfMvclV2CkmCMeta || {},
    component: () => import("/app/pages/embed/advisor/[org_id]/portfolio-builder/pdf.vue")
  },
  {
    name: "forget-password",
    path: "/forget-password",
    meta: forget_45passwordEVWK44q6hlMeta || {},
    component: () => import("/app/pages/forget-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "product-demo-org_id-error-bad-org",
    path: "/product-demo/:org_id()/error/bad-org",
    meta: bad_45orgC77qNgWm0ZMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/error/bad-org.vue")
  },
  {
    name: "product-demo-org_id-mb-report-id",
    path: "/product-demo/:org_id()/mb/report/:id()",
    meta: _91id_93VRbgE2sfW9Meta || {},
    component: () => import("/app/pages/product-demo/[org_id]/mb/report/[id].vue")
  },
  {
    name: "product-demo-org_id-mb-review-id",
    path: "/product-demo/:org_id()/mb/review/:id()",
    meta: _91id_930IwM01MqTQMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/mb/review/[id].vue")
  },
  {
    name: "product-demo-org_id-sp-investment-account",
    path: "/product-demo/:org_id()/sp/investment/account",
    meta: accountQG4Z5HQuyZMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/account.vue")
  },
  {
    name: "product-demo-org_id-sp-investment",
    path: "/product-demo/:org_id()/sp/investment",
    meta: indexWpkPMeup3XMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/index.vue")
  },
  {
    name: "product-demo-org_id-sp-investment-overview",
    path: "/product-demo/:org_id()/sp/investment/overview",
    meta: overviewsZItjFsfkUMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/overview.vue")
  },
  {
    name: "product-demo-org_id-sp-investment-position",
    path: "/product-demo/:org_id()/sp/investment/position",
    meta: positionQWu7Vq3yvNMeta || {},
    component: () => import("/app/pages/product-demo/[org_id]/sp/investment/position.vue")
  },
  {
    name: "reset-password-uuid",
    path: "/reset-password/:uuid()",
    meta: _91uuid_93ixfYQmY4daMeta || {},
    component: () => import("/app/pages/reset-password/[uuid].vue")
  },
  {
    name: "test-links",
    path: "/test-links",
    meta: test_45linksoPVN4KYRyHMeta || {},
    component: () => import("/app/pages/test-links.vue")
  }
]