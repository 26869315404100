<template>
  <v-app>
    <v-app-bar app fixed clipped-left density="compact" color="primary">
      <v-toolbar-title style="color: white">Njorda Developer Portal</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer v-model:mini-variant="drawerMini" permanent clipped app>
      <v-list density="compact">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.to" router exact>
          <template #prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>
          <v-list-item-title style="font-size: 14px">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <template #append>
        <v-btn icon class="my-1" style="margin-left: 10px" @click.stop.prevent="drawerMini = !drawerMini">
          <v-icon>{{ drawerMinifyIcon }}</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useAppStore } from "~/store/app";
import { storeToRefs } from "pinia";

const drawerMini = ref(false);
const items = [
  {
    icon: "mdi-apps",
    title: "Home",
    to: "/developer",
  },
  {
    icon: "mdi-finance",
    title: "Instrument Data",
    to: "/developer/instrument-list",
  },
  {
    icon: "mdi-account",
    title: "Clients Data Sessions",
    to: "/developer/client-details",
  },
  {
    icon: "mdi-account",
    title: "User Data",
    to: "/developer/user-list",
  },
  {
    icon: "mdi-briefcase",
    title: "Portfolio Calc",
    to: "/developer/portfolio-data",
  },
  {
    icon: "mdi-widgets",
    title: "UI Components",
    to: "/developer/component-gallery",
  },
  {
    icon: "mdi-office-building-cog",
    title: "OrgApp Export/Import",
    to: "/developer/organizations",
  },
  {
    icon: "mdi-queue-first-in-last-out",
    title: "Trigger Task",
    to: "/developer/trigger-task",
  },
  {
    icon: "mdi-ab-testing",
    title: "Integration Tests",
    to: "/developer/integration-tests",
  },
  {
    icon: "mdi-trophy-broken",
    title: "Instrument issues",
    to: "/developer/instrument-issues",
  },
  {
    icon: "mdi-inbox-arrow-down",
    title: "Embed Pages",
    to: "/developer/embed",
  },
  {
    icon: "mdi-api",
    title: "B2C APP API",
    to: "/developer/api/app",
  },
  {
    icon: "mdi-api",
    title: "B2B API V1",
    to: "/developer/api/b2b",
  },
  {
    icon: "mdi-api",
    title: "B2B API V2",
    to: "/developer/api/b2b-v2",
  },
  {
    icon: "mdi-api",
    title: "Admin API V1",
    to: "/developer/api/admin",
  },
  {
    icon: "mdi-api",
    title: "Connect API V1",
    to: "/developer/api/connect",
  },
];

const { layoutSettings } = storeToRefs(useAppStore());

const drawerMinifyIcon = computed(() => {
  if (drawerMini.value) {
    return "n:chevron-right";
  } else {
    return "n:chevron-left";
  }
});

const favicon = computed(() => {
  return layoutSettings.value?.favicon;
});

useHead({
  link: [
    {
      hid: "icon",
      rel: "icon",
      type: "image/x-icon",
      href: favicon,
    },
  ],
});
</script>

<style lang="scss" scoped>
.toolbar-white-box {
  color: white;
  background-color: rgb(255 255 255 / 20%);
  border-radius: 4px;
}

.only-bottom-border {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}
</style>
