<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
    <path d="M8 10.0869H14" stroke="currentColor" stroke-width="2" stroke-linecap="square" />
    <path d="M4.69995 18.0869L18.1985 1.99998" stroke="currentColor" stroke-width="2" stroke-linecap="square" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3572 16.0869H16C19.3137 16.0869 22 13.4006 22 10.0869C22 6.77321 19.3137 4.08691 16 4.08691H12.3572V6.08691H16C18.2091 6.08691 20 7.87778 20 10.0869C20 12.2961 18.2091 14.0869 16 14.0869H12.3572V16.0869ZM10.3572 6.08691H6C3.79086 6.08691 2 7.87778 2 10.0869C2 12.2961 3.79086 14.0869 6 14.0869H10.3572V16.0869H6C2.68629 16.0869 0 13.4006 0 10.0869C0 6.77321 2.68629 4.08691 6 4.08691H10.3572V6.08691Z"
      fill="currentColor"
    />
  </svg>
</template>
